import React from 'react';
import { useField } from 'formik';
import { FormGroup, Label, Input, FormFeedback } from 'reactstrap';

export interface EmailFieldProps {}

const EmailField: React.FC<EmailFieldProps> = () => {
    const [field, meta] = useField('email');

    return (
        <FormGroup>
            <Label for="email">Email</Label>
            <Input type="email" id="email" invalid={!!meta.error && meta.touched} {...field} />
            <FormFeedback>{meta.error}</FormFeedback>
        </FormGroup>
    );
};

export default EmailField;
