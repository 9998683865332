import React from 'react';
import { Icon } from 'components/atoms';
import { Button, ButtonProps } from 'reactstrap';

export interface CreateButtonProps extends Omit<ButtonProps, 'children'> {}

const CreateButton: React.FC<CreateButtonProps> = ({ ...props }) => (
    <Button {...props}>
        <Icon.Create />
    </Button>
);

export default CreateButton;
