import React from 'react';
import { Table } from 'reactstrap';
import { NutritionTableData } from './NutritionTable.types';

interface NutritionTableProps {
    data: NutritionTableData;
}

const NutritionTable: React.FC<NutritionTableProps> = ({ data }) => (
    <Table className="mb-0" borderless striped>
        <thead>
            <tr>
                <th scope="col">Date</th>
                <th scope="col">Protein</th>
                <th scope="col">Carbohydrates</th>
                <th scope="col">Fat</th>
                <th scope="col">Fiber</th>
                <th scope="col">Calories</th>
            </tr>
        </thead>
        <tbody>
            {data.map((item, index) => (
                <tr key={index}>
                    <th scope="row">{item.date}</th>
                    <td>{item.protein}g</td>
                    <td>{item.carbohydrates}g</td>
                    <td>{item.fat}g</td>
                    <td>{item.fiber}g</td>
                    <td>{item.calories} kcal</td>
                </tr>
            ))}
        </tbody>
    </Table>
);

export default NutritionTable;
