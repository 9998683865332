import React from 'react';
import { Loader } from 'components/atoms';
import BaseInfiniteScroll, { Props } from 'react-infinite-scroll-component';

export interface InfiniteScrollProps extends Omit<Props, 'loader' | 'style'> {}

const InfiniteScroll: React.FC<InfiniteScrollProps> = props => (
    <BaseInfiniteScroll loader={<Loader />} style={{ overflow: 'inherit' }} {...props} />
);

export default InfiniteScroll;
