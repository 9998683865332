import React from 'react';
import type { ExerciseFormSubmitFunction, ExerciseFormValues } from 'components/organisms';
import { CreateExercisePage } from 'components/pages';
import { useCreateExerciseMutation } from 'graphql/generated';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

export interface CreateExerciseProps {}

const CreateExercise: React.FC<CreateExerciseProps> = () => {
    const navigate = useNavigate();

    const [createExercise] = useCreateExerciseMutation();

    const initialExerciseFormValues: ExerciseFormValues = {
        name: '',
        description: ''
    };

    const onExerciseFormSubmit: ExerciseFormSubmitFunction = (values, helpers): void => {
        createExercise({
            variables: { name: values.name, description: values.description },
            onCompleted: data => {
                toast.success('Exercise created successfully!');
                navigate(`/exercises/${data.createExercise.id}`);
            },
            onError: () => {
                toast.error('Failed to create exercise!');
                helpers.setSubmitting(false);
            },
            update: (cache, result) => {
                cache.modify({
                    fields: {
                        exercises: (existingRefs = []) => [
                            ...existingRefs,
                            result.data!.createExercise
                        ]
                    }
                });
            }
        });
    };

    return <CreateExercisePage {...{ initialExerciseFormValues, onExerciseFormSubmit }} />;
};

export default CreateExercise;
