import React, { useMemo } from 'react';
import { UserGoalsPage } from 'components/pages';
import { useCreateGoalMutation, useGetUserQuery } from 'graphql/generated';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

export interface UserGoalsProps {}

const UserGoals: React.FC<UserGoalsProps> = () => {
    const { userId } = useParams() as { userId: string };

    const [createGoal] = useCreateGoalMutation();

    const { loading, error, data } = useGetUserQuery({ variables: { userId, includeGoals: true } });

    const goals = useMemo(() => {
        if (data?.user?.goals) {
            return data.user.goals.map(goal => ({
                id: goal.id,
                date: goal.date,
                protein: goal.protein,
                carbohydrates: goal.carbohydrates,
                fat: goal.fat,
                fiber: goal.fiber,
                calories: goal.calories,
                createdAt: goal.created_at,
                updatedAt: goal.updated_at || ''
            }));
        }
        return [];
    }, [data]);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error! {error.message}</div>;
    }

    if (!data) {
        return null;
    }

    const handleCreateGoal = (
        values: {
            calories: number;
            carbohydrates: number;
            date: string;
            fat: number;
            fiber: number;
            protein: number;
        },
        onSuccess: () => void
    ): void => {
        createGoal({
            variables: { userId, ...values },
            onCompleted: () => {
                toast.success('Goal created successfully!');
                onSuccess();
            },
            onError: () => {
                toast.error('Failed to create goal!');
            },
            update: (cache, result) => {
                cache.modify({
                    id: cache.identify(data!.user),
                    fields: {
                        goals: (existingRefs = []) => [...existingRefs, result.data!.createGoal]
                    }
                });
            }
        });
    };

    return (
        <UserGoalsPage
            {...{
                goals,
                handleCreateGoal
            }}
        />
    );
};

export default UserGoals;
