import React, { useMemo } from 'react';
import { useGetCurrentUserQuery, useLoginMutation } from 'graphql/generated';

export type CurrentUser = {
    id: string;
    firstName: string;
    lastName: string;
};

interface AuthContextProps {
    user: CurrentUser | undefined;
    login: (user: any, callback: VoidFunction) => void;
    logout: (callback: VoidFunction) => void;
}

export const AuthContext = React.createContext<AuthContextProps>({} as AuthContextProps);

interface AuthProviderProps {
    children: React.ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = props => {
    const { data, loading, error, refetch } = useGetCurrentUserQuery({
        fetchPolicy: 'no-cache',
        errorPolicy: 'ignore'
    });

    const [loginMutation] = useLoginMutation();

    const user = useMemo(() => {
        if (data) {
            return {
                id: data.me.id,
                firstName: data.me.first_name,
                lastName: data.me.last_name
            };
        }
    }, [data]);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error...</div>;
    }

    const login = ({ email, password }: any, callback: VoidFunction): void => {
        loginMutation({
            variables: { email, password },
            onCompleted: data => {
                localStorage.setItem('token', data.login.token);

                refetch();
            }
        });

        callback();
    };

    const logout = (callback: VoidFunction): void => {
        localStorage.removeItem('token');

        refetch();

        callback();
    };

    return (
        <AuthContext.Provider value={{ user, login, logout }}>
            {props.children}
        </AuthContext.Provider>
    );
};
