import React from 'react';
import { useField } from 'formik';
import { FormFeedback, FormGroup, Input, InputGroup, InputGroupText, Label } from 'reactstrap';

export interface HeightFieldProps {}

const HeightField: React.FC<HeightFieldProps> = () => {
    const [field, meta] = useField('height');

    return (
        <FormGroup>
            <Label for="height">Height</Label>
            <InputGroup>
                <Input
                    type="number"
                    id="height"
                    invalid={!!meta.error && meta.touched}
                    {...field}
                />
                <InputGroupText>cm</InputGroupText>
                <FormFeedback>{meta.error}</FormFeedback>
            </InputGroup>
        </FormGroup>
    );
};

export default HeightField;
