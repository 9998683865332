import React from 'react';
import type {
    ProgrammeFormSubmitFunction,
    ProgrammeFormValues,
    WorkoutsFormHelpers,
    WorkoutsFormValues
} from 'components/organisms';
import { NoMatchPage, UpdateProgrammePage } from 'components/pages';
import {
    useDeleteProgrammeMutation,
    useGetProgrammeQuery,
    useGetWorkoutsLazyQuery,
    useUpdateProgrammeMutation
} from 'graphql/generated';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { deleteRefFromCache } from 'utilities/helpers';

export interface UpdateProgrammeProps {}

const UpdateProgramme: React.FC<UpdateProgrammeProps> = () => {
    const navigate = useNavigate();

    const { programmeId } = useParams() as { programmeId: string };

    const { data, loading, error } = useGetProgrammeQuery({ variables: { programmeId } });

    const [getWorkouts] = useGetWorkoutsLazyQuery();

    const [deleteProgramme] = useDeleteProgrammeMutation();

    const [updateProgramme] = useUpdateProgrammeMutation();

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error</div>;
    }

    if (!data) {
        return <NoMatchPage />;
    }

    const initialProgrammeFormValues: ProgrammeFormValues = {
        name: data.programme.name,
        description: data.programme.description || '',
        startDate: data.programme.start_date,
        endDate: data.programme.end_date
    };

    const initialWorkoutsFormValues: WorkoutsFormValues = {
        workouts:
            data.programme.workouts?.map(workout => ({
                id: workout.id,
                name: workout.name,
                description: workout.description || ''
            })) || []
    };

    const onDeleteButtonClick = (): void => {
        deleteProgramme({
            variables: { programmeId },
            onCompleted: () => {
                toast.success('Programme deleted successfully!');
                navigate('/programmes');
            },
            onError: () => {
                toast.error('Failed to delete programme!');
            },
            update: cache =>
                deleteRefFromCache(cache, {
                    id: `Programme:${programmeId}`,
                    fieldName: 'programmes',
                    refId: programmeId
                })
        });
    };

    const onProgrammeFormSubmit: ProgrammeFormSubmitFunction = (values, helpers): void => {
        updateProgramme({
            variables: {
                programmeId,
                name: values.name,
                description: values.description,
                startDate: values.startDate,
                endDate: values.endDate
            },
            onCompleted: () => {
                toast.success('Programme updated successfully!');
                helpers.setSubmitting(false);
            },
            onError: () => {
                toast.error('Failed to update programme!');
                helpers.setSubmitting(false);
            }
        });
    };

    const onWorkoutsFormSubmit = (
        { workouts }: WorkoutsFormValues,
        { setSubmitting }: WorkoutsFormHelpers
    ): void => {
        updateProgramme({
            variables: {
                programmeId,
                workouts: workouts.map(workout => ({ workout_id: workout.id }))
            },
            onCompleted: () => {
                toast.success('Programme updated successfully!');
                setSubmitting(false);
            },
            onError: () => {
                toast.error('Failed to update programme!');
                setSubmitting(false);
            }
        });
    };

    const promiseOptions = async (_: string) => {
        const { data } = await getWorkouts();

        return (
            data?.workouts?.map(workout => ({
                value: workout.id,
                label: workout.name
            })) || []
        );
    };

    return (
        <UpdateProgrammePage
            {...{
                initialProgrammeFormValues,
                initialWorkoutsFormValues,
                onDeleteButtonClick,
                onProgrammeFormSubmit,
                onWorkoutsFormSubmit,
                promiseOptions
            }}
        />
    );
};

export default UpdateProgramme;
