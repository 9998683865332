import React from 'react';
import type { WorkoutFormSubmitFunction, WorkoutFormValues } from 'components/organisms';
import { CreateWorkoutPage } from 'components/pages';
import { useCreateWorkoutMutation } from 'graphql/generated';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

export interface CreateWorkoutProps {}

const CreateWorkout: React.FC<CreateWorkoutProps> = () => {
    const navigate = useNavigate();

    const [createWorkout] = useCreateWorkoutMutation();

    const initialWorkoutFormValues: WorkoutFormValues = {
        name: '',
        description: ''
    };

    const onWorkoutFormSubmit: WorkoutFormSubmitFunction = (values, helpers): void => {
        createWorkout({
            variables: { name: values.name, description: values.description },
            onCompleted: data => {
                toast.success('Workout created successfully!');
                navigate(`/workouts/${data.createWorkout.id}`);
            },
            onError: () => {
                toast.error('Failed to create workout!');
                helpers.setSubmitting(false);
            },
            update: (cache, result) => {
                cache.modify({
                    fields: {
                        workouts: (existingRefs = []) => [
                            ...existingRefs,
                            result.data!.createWorkout
                        ]
                    }
                });
            }
        });
    };

    return <CreateWorkoutPage {...{ initialWorkoutFormValues, onWorkoutFormSubmit }} />;
};

export default CreateWorkout;
