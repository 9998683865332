import React from 'react';
import { Button } from 'components/molecules';
import { DefaultTemplate } from 'components/templates';
import Moment from 'react-moment';
import { Link } from 'react-router-dom';
import {
    Card,
    CardBody,
    CardHeader,
    CardImg,
    CardText,
    Col,
    Container,
    ListGroup,
    ListGroupItem,
    Row,
    Table
} from 'reactstrap';

export type WorkoutData = {
    id: string;
    name: string;
    description: string;
    createdAt: string;
    updatedAt: string;
    exercises: {
        id: string;
        name: string;
        minSets: number;
        maxSets: number;
        minReps: number;
        maxReps: number;
        rest: number;
    }[];
};

export interface WorkoutPageProps {
    data: WorkoutData;
}

const WorkoutPage: React.FC<WorkoutPageProps> = ({ data }) => (
    <DefaultTemplate
        title={data.name}
        content={<Button.Edit tag={Link} color="primary" to={`/workouts/${data.id}/update`} />}>
        <Container className="mt-n10">
            <Row>
                <Col lg="4" xl="3">
                    <Card className="mb-4">
                        <CardImg src="https://placehold.co/600x400" alt="..." top />
                        {data.description && (
                            <CardBody>
                                <CardText>{data.description}</CardText>
                            </CardBody>
                        )}
                        <ListGroup flush>
                            <ListGroupItem>
                                Created <Moment fromNow>{data.createdAt}</Moment>
                            </ListGroupItem>
                            <ListGroupItem>
                                Updated <Moment fromNow>{data.updatedAt}</Moment>
                            </ListGroupItem>
                        </ListGroup>
                    </Card>
                </Col>
                <Col lg="8" xl="9">
                    <Card className="mb-4">
                        <CardHeader tag="h5">Exercises</CardHeader>
                        <Table className="mb-0" borderless responsive striped>
                            <thead>
                                <tr>
                                    <th scope="col">Name</th>
                                    <th scope="col">Sets</th>
                                    <th scope="col">Reps</th>
                                    <th scope="col">Rest</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.exercises.map((exercise, index) => (
                                    <tr key={index}>
                                        <td>
                                            <Link to={`/exercises/${exercise.id}`}>
                                                {exercise.name}
                                            </Link>
                                        </td>
                                        <td>
                                            {exercise.minSets} - {exercise.maxSets}
                                        </td>
                                        <td>
                                            {exercise.minReps} - {exercise.maxReps}
                                        </td>
                                        <td>{exercise.rest}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Card>
                </Col>
            </Row>
        </Container>
    </DefaultTemplate>
);

export default WorkoutPage;
