import { COLUMN, DATE_RANGE, FREQUENCY, USER_ID } from 'utilities/constants';
import parseDateRange from '../../parseDateRange';

function measurements(url: string, config: { [key: string]: string }): string {
    const queryParams: string[] = [];

    if (config[COLUMN]) {
        queryParams.push(`column=${config[COLUMN]}`);
    }

    if (config[DATE_RANGE]) {
        const { start, end } = parseDateRange(config[DATE_RANGE]);

        queryParams.push(`start=${start}`);
        queryParams.push(`end=${end}`);
    }

    if (config[FREQUENCY]) {
        queryParams.push(`frequency=${config[FREQUENCY]}`);
    }

    if (config[USER_ID]) {
        queryParams.push(`userId=${config[USER_ID]}`);
    }

    return `${url}?${queryParams.join('&')}`.toLowerCase();
}

export default measurements;
