import React from 'react';
import { UserPage } from 'components/pages';
import { useGetUserQuery } from 'graphql/generated';
import { useParams } from 'react-router-dom';

export interface UserProps {}

const User: React.FC<UserProps> = () => {
    const { userId } = useParams() as { userId: string };

    const { data, loading, error } = useGetUserQuery({ variables: { userId } });

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error!</div>;
    }

    if (!data) {
        return null;
    }

    const user = {
        id: data.user.id,
        firstName: data.user.first_name,
        lastName: data.user.last_name
    };

    return <UserPage data={user} />;
};

export default User;
