import { z } from 'zod';
import { ActivityLevel, Gender } from 'graphql/generated';

const LeadFormSchema = z.object({
    activityLevel: z.nativeEnum(ActivityLevel),
    dateOfBirth: z.string().date(),
    email: z.string().email(),
    firstName: z
        .string()
        .min(1, 'First name must be 1 or more characters long')
        .max(100, 'First name must be 100 or fewer characters long'),
    lastName: z
        .string()
        .min(1, 'Last name must be 1 or more characters long')
        .max(100, 'Last name must be 100 or fewer characters long'),
    gender: z.nativeEnum(Gender),
    goal: z.string(),
    height: z.number(),
    weight: z.number(),
    status: z.string()
});

export default LeadFormSchema;
