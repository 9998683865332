import CreateIcon from './CreateIcon';
import EditIcon from './EditIcon';
import FemaleIcon from './FemaleIcon';
import MaleIcon from './MaleIcon';
import RemoveIcon from './RemoveIcon';

export * from './CreateIcon';
export * from './EditIcon';
export * from './FemaleIcon';
export * from './MaleIcon';
export * from './RemoveIcon';

const Icon = {
    Create: CreateIcon,
    Female: FemaleIcon,
    Male: MaleIcon,
    Edit: EditIcon,
    Remove: RemoveIcon
};

export default Icon;
