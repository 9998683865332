import React from 'react';
import { useField } from 'formik';
import { FormFeedback, FormGroup, Input, InputGroup, InputGroupText, Label } from 'reactstrap';

export interface FiberFieldProps {}

const FiberField: React.FC<FiberFieldProps> = () => {
    const [field, meta] = useField('fiber');

    return (
        <FormGroup>
            <Label for="fiber">Fiber</Label>
            <InputGroup>
                <Input type="number" id="fiber" invalid={!!meta.error && meta.touched} {...field} />
                <InputGroupText>g</InputGroupText>
                <FormFeedback>{meta.error}</FormFeedback>
            </InputGroup>
        </FormGroup>
    );
};

export default FiberField;
