import React from 'react';
import { Button } from 'components/molecules';
import type { DashboardsTableData } from 'components/organisms';
import { DashboardsTable } from 'components/organisms';
import { DefaultTemplate } from 'components/templates';
import { Link } from 'react-router-dom';
import { Card, CardHeader, Container } from 'reactstrap';

export interface DashboardsPageProps {
    dashboardsTableData: DashboardsTableData[];
    next: () => void;
}

const DashboardsPage: React.FC<DashboardsPageProps> = ({ dashboardsTableData, next }) => (
    <DefaultTemplate
        title="Dashboards"
        content={<Button.Create tag={Link} color="primary" to="/dashboards/create" />}>
        <Container className="mt-n10">
            <Card className="mb-4">
                <CardHeader tag="h5">Dashboards</CardHeader>
                <DashboardsTable
                    className="mb-0"
                    data={dashboardsTableData}
                    next={next}
                    borderless
                    responsive
                    striped
                />
            </Card>
        </Container>
    </DefaultTemplate>
);

export default DashboardsPage;
