import React from 'react';
import { useField } from 'formik';
import { FormGroup, Label, Input, FormFeedback } from 'reactstrap';

export interface DateFieldProps {}

const DateField: React.FC<DateFieldProps> = () => {
    const [field, meta] = useField('date');

    return (
        <FormGroup>
            <Label for="date">Date</Label>
            <Input type="date" id="date" invalid={!!meta.error && meta.touched} {...field} />
            <FormFeedback>{meta.error}</FormFeedback>
        </FormGroup>
    );
};

export default DateField;
