import React from 'react';
import {
    faCompass,
    faFireAlt,
    faPersonRunning,
    faTachometerAlt,
    faUsers
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    SidenavNavigation,
    SidenavNavigationItems,
    SidenavUserFooter
} from '@typedbootstrap/admin-components';
import { useUser } from 'hooks';
import { Link } from 'react-router-dom';

export interface SidenavProps {}

const Sidenav: React.FC<SidenavProps> = () => {
    const user = useUser();

    const items: SidenavNavigationItems = [
        {
            heading: 'Main Menu'
        },
        {
            name: 'Dashboards',
            tag: Link,
            icon: <FontAwesomeIcon icon={faTachometerAlt} />,
            to: '/dashboards'
        },
        {
            name: 'Users',
            tag: Link,
            icon: <FontAwesomeIcon icon={faUsers} />,
            to: '/users'
        },
        {
            name: 'Exercises',
            tag: Link,
            icon: <FontAwesomeIcon icon={faPersonRunning} />,
            to: '/exercises'
        },
        {
            name: 'Workouts',
            tag: Link,
            icon: <FontAwesomeIcon icon={faFireAlt} />,
            to: '/workouts'
        },
        {
            name: 'Leads',
            tag: Link,
            icon: <FontAwesomeIcon icon={faCompass} />,
            to: '/leads'
        }
    ];

    return (
        <SidenavNavigation
            items={items}
            footer={<SidenavUserFooter name={user.firstName + ' ' + user.lastName} />}
            dark
        />
    );
};

export default Sidenav;
