import { ApolloCache } from '@apollo/client';

type DeleteRefFromCacheHelper = <T>(
    cache: ApolloCache<T>,
    options: {
        id: string;
        fieldName: any;
        refId: string;
    }
) => void;

const deleteRefFromCache: DeleteRefFromCacheHelper = (cache, { id, fieldName, refId }) => {
    cache.modify({
        id,
        fields: {
            [fieldName]: (existingRefs = [], { readField }) => {
                return existingRefs.filter(
                    (existingRef: any) => readField('id', existingRef) !== refId
                );
            }
        }
    });

    cache.evict({ id });
};

export default deleteRefFromCache;
