import React, { useEffect, useState } from 'react';
import { WorkoutData, WorkoutPage } from 'components/pages';
import { useGetWorkoutQuery } from 'graphql/generated';
import { useParams } from 'react-router-dom';

export interface WorkoutProps {}

const Workout: React.FC<WorkoutProps> = () => {
    const { workoutId } = useParams() as { workoutId: string };

    const { data, loading, error } = useGetWorkoutQuery({ variables: { workoutId } });

    const [workoutData, setWorkoutData] = useState<WorkoutData>();

    useEffect(() => {
        if (data?.workout) {
            setWorkoutData({
                id: data.workout.id,
                name: data.workout.name,
                description: data.workout.description || '',
                createdAt: data.workout.created_at,
                updatedAt: data.workout.updated_at || '',
                exercises:
                    data.workout.exercises?.map(exercise => ({
                        id: exercise.id,
                        name: exercise.name,
                        minSets: exercise.pivot?.min_sets || 0,
                        maxSets: exercise.pivot?.max_sets || 0,
                        minReps: exercise.pivot?.min_reps || 0,
                        maxReps: exercise.pivot?.max_reps || 0,
                        rest: exercise.pivot?.rest || 0
                    })) || []
            });
        }
    }, [data]);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error!</div>;
    }

    if (!workoutData) {
        return null;
    }

    return <WorkoutPage data={workoutData} />;
};

export default Workout;
