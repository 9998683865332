import React from 'react';
import classnames from 'classnames';
import CookieConsent from 'react-cookie-consent';

export interface CookieBannerProps {}

const CookieBanner: React.FC<CookieBannerProps> = () => {
    const buttonClasses = classnames('btn', 'btn-primary');

    const containerClasses = classnames(
        'align-items-baseline',
        'bg-dark',
        'd-flex',
        'flex-wrap',
        'justify-content-between',
        'p-3',
        'position-fixed',
        'start-0',
        'text-white',
        'w-100'
    );

    return (
        <CookieConsent
            buttonClasses={buttonClasses}
            containerClasses={containerClasses}
            location="bottom"
            style={{ zIndex: 9999 }}
            disableButtonStyles
            disableStyles>
            This website uses cookies to enhance the user experience.
        </CookieConsent>
    );
};

export default CookieBanner;
