import React, { useEffect, useState } from 'react';
import type { UsersTableData } from 'components/organisms';
import { UsersPage } from 'components/pages';
import { useGetUsersQuery } from 'graphql/generated';

export interface UsersProps {}

const Users: React.FC<UsersProps> = () => {
    const { data, fetchMore } = useGetUsersQuery({ variables: { offset: 0, limit: 25 } });

    const [usersTableData, setUsersTableData] = useState<UsersTableData[]>([]);

    useEffect(() => {
        setUsersTableData(
            data?.users?.map(user => ({
                id: user.id,
                avatar: user.avatar,
                name: user.first_name + ' ' + user.last_name,
                createdAt: user.created_at,
                updatedAt: user.updated_at || ''
            })) || []
        );
    }, [data]);

    const next = (): void => {
        if (data?.users) fetchMore({ variables: { offset: data.users.length } });
    };

    return <UsersPage {...{ usersTableData, next }} />;
};

export default Users;
