import { METRICS } from 'utilities/constants';
import measurements from './measurements';
import progressiveOverload from './progressiveOverload';

const categories = {
    [METRICS.MEASUREMENTS]: measurements,
    [METRICS.PROGRESSIVE_OVERLOAD]: progressiveOverload
};

export default categories;
