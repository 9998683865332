import React from 'react';
import AccountSecurityPage, { type ChangePassword } from 'components/pages/AccountSecurityPage';
import { useChangePasswordMutation } from 'graphql/generated';
import { toast } from 'react-toastify';

interface AccountSecurityProps {}

const AccountSecurity: React.FC<AccountSecurityProps> = () => {
    const [changePassword] = useChangePasswordMutation();

    const handlePasswordChange: ChangePassword = (currentPassword, newPassword, onSuccess) => {
        changePassword({
            variables: { currentPassword, newPassword },
            onCompleted: () => {
                toast.success('Password updated successfully!');
                onSuccess();
            },
            onError: () => {
                toast.error('Failed to update password!');
            }
        });
    };

    return <AccountSecurityPage changePassword={handlePasswordChange} />;
};

export default AccountSecurity;
