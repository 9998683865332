import React from 'react';
import type { LeadFormSubmitFunction, LeadFormValues } from 'components/organisms';
import { LeadForm } from 'components/organisms';
import { DefaultTemplate } from 'components/templates';
import { Card, CardBody, CardHeader, Container } from 'reactstrap';

export interface CreateLeadPageProps {
    initialLeadFormValues: LeadFormValues;
    onLeadFormSubmit: LeadFormSubmitFunction;
}

const CreateLeadPage: React.FC<CreateLeadPageProps> = ({
    initialLeadFormValues,
    onLeadFormSubmit
}) => (
    <DefaultTemplate title="Create Lead">
        <Container className="mt-n10">
            <Card className="mb-4">
                <CardHeader tag="h5">Details</CardHeader>
                <CardBody>
                    <LeadForm initialValues={initialLeadFormValues} onSubmit={onLeadFormSubmit} />
                </CardBody>
            </Card>
        </Container>
    </DefaultTemplate>
);

export default CreateLeadPage;
