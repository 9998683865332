import React from 'react';
import type { ExerciseFormSubmitFunction, ExerciseFormValues } from 'components/organisms';
import { NoMatchPage, UpdateExercisePage } from 'components/pages';
import {
    useDeleteExerciseMutation,
    useGetExerciseQuery,
    useUpdateExerciseMutation
} from 'graphql/generated';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { deleteRefFromCache } from 'utilities/helpers';

export interface UpdateExerciseProps {}

const UpdateExercise: React.FC<UpdateExerciseProps> = () => {
    const navigate = useNavigate();

    const { exerciseId } = useParams() as { exerciseId: string };

    const { data, loading, error } = useGetExerciseQuery({ variables: { exerciseId } });

    const [deleteExercise] = useDeleteExerciseMutation();

    const [updateExercise] = useUpdateExerciseMutation();

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error</div>;
    }

    if (!data) {
        return <NoMatchPage />;
    }

    const initialExerciseFormValues: ExerciseFormValues = {
        name: data.exercise.name,
        description: data.exercise.description || ''
    };

    const onDeleteButtonClick = (): void => {
        deleteExercise({
            variables: { exerciseId },
            onCompleted: () => {
                toast.success('Exercise deleted successfully!');
                navigate('/exercises');
            },
            onError: () => {
                toast.error('Failed to delete exercise!');
            },
            update: cache =>
                deleteRefFromCache(cache, {
                    id: `Exercise:${exerciseId}`,
                    fieldName: 'exercises',
                    refId: exerciseId
                })
        });
    };

    const onExerciseFormSubmit: ExerciseFormSubmitFunction = (values, helpers): void => {
        updateExercise({
            variables: { exerciseId, name: values.name, description: values.description },
            onCompleted: () => {
                toast.success('Exercise updated successfully!');
                helpers.setSubmitting(false);
            },
            onError: () => {
                toast.error('Failed to update exercise!');
                helpers.setSubmitting(false);
            }
        });
    };

    return (
        <UpdateExercisePage
            {...{ initialExerciseFormValues, onDeleteButtonClick, onExerciseFormSubmit }}
        />
    );
};

export default UpdateExercise;
