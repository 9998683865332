import React from 'react';
import { useField } from 'formik';
import { FormGroup, Label, Input, FormFeedback } from 'reactstrap';

export interface LastNameFieldProps {}

const LastNameField: React.FC<LastNameFieldProps> = () => {
    const [field, meta] = useField('lastName');

    return (
        <FormGroup>
            <Label for="lastName">Last Name</Label>
            <Input type="text" id="lastName" invalid={!!meta.error && meta.touched} {...field} />
            <FormFeedback>{meta.error}</FormFeedback>
        </FormGroup>
    );
};

export default LastNameField;
