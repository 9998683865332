import {
    COLUMN,
    COLUMNS,
    DATE_RANGE,
    DATE_RANGES,
    FREQUENCIES,
    FREQUENCY,
    RADIO,
    WIDGET,
    WIDGETS
} from 'utilities/constants';

const measurements = {
    inputs: {
        [WIDGET]: {
            type: RADIO,
            defaultOption: WIDGETS.LINE_CHART,
            options: [WIDGETS.LINE_CHART]
        },
        [COLUMN]: {
            type: RADIO,
            defaultOption: COLUMNS.WEIGHT,
            options: [
                COLUMNS.ARMS,
                COLUMNS.CHEST,
                COLUMNS.HIPS,
                COLUMNS.NECK,
                COLUMNS.THIGHS,
                COLUMNS.WAIST,
                COLUMNS.WEIGHT
            ]
        },
        [DATE_RANGE]: {
            type: RADIO,
            defaultOption: DATE_RANGES.LAST_30_DAYS,
            options: [
                DATE_RANGES.LAST_30_DAYS,
                DATE_RANGES.LAST_60_DAYS,
                DATE_RANGES.LAST_90_DAYS,
                DATE_RANGES.LAST_6_MONTHS,
                DATE_RANGES.LAST_1_YEAR
            ]
        },
        [FREQUENCY]: {
            type: RADIO,
            defaultOption: FREQUENCIES.WEEKLY,
            options: [FREQUENCIES.DAILY, FREQUENCIES.MONTHLY, FREQUENCIES.WEEKLY]
        }
    }
};

export default measurements;
