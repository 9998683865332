import React from 'react';
import { useField } from 'formik';
import { FormGroup, Label, Input, FormFeedback } from 'reactstrap';

export interface DateOfBirthFieldProps {}

const DateOfBirthField: React.FC<DateOfBirthFieldProps> = () => {
    const [field, meta] = useField('dateOfBirth');

    return (
        <FormGroup>
            <Label for="dateOfBirth">Date of Birth</Label>
            <Input type="date" id="dateOfBirth" invalid={!!meta.error && meta.touched} {...field} />
            <FormFeedback>{meta.error}</FormFeedback>
        </FormGroup>
    );
};

export default DateOfBirthField;
