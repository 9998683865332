import React from 'react';
import { faFloppyDisk } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, type ButtonProps } from 'reactstrap';

export interface SaveButtonProps extends ButtonProps {}

const SaveButton: React.FC<SaveButtonProps> = ({
    type = 'submit',
    color = 'primary',
    ...props
}) => (
    <Button type={type} color={color} {...props}>
        <FontAwesomeIcon className="me-3" icon={faFloppyDisk} fixedWidth />
        Save
    </Button>
);

export default SaveButton;
