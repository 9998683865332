import React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    ChartData,
    Point
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

interface LineChartProps {
    data: ChartData<'line', (number | Point | null)[], unknown>;
}

const LineChart: React.FC<LineChartProps> = ({ data }) => <Line data={data} />;

export default LineChart;
