import { z } from 'zod';

const ProgrammeFormSchema = z.object({
    name: z
        .string()
        .min(3, 'Name must be 3 or more characters long')
        .max(100, 'Name must be 100 or fewer characters long'),
    description: z.string().max(250, 'Description must be 250 or fewer characters long'),
    startDate: z.string(),
    endDate: z.string()
});

export default ProgrammeFormSchema;
