import React from 'react';
import type { WorkoutFormSubmitFunction, WorkoutFormValues } from 'components/organisms';
import { WorkoutForm } from 'components/organisms';
import { DefaultTemplate } from 'components/templates';
import { Card, CardBody, CardHeader, Container } from 'reactstrap';

export interface CreateWorkoutPageProps {
    initialWorkoutFormValues: WorkoutFormValues;
    onWorkoutFormSubmit: WorkoutFormSubmitFunction;
}

const CreateWorkoutPage: React.FC<CreateWorkoutPageProps> = props => (
    <DefaultTemplate title="Create Workout">
        <Container className="mt-n10">
            <Card className="mb-4">
                <CardHeader tag="h5">Details</CardHeader>
                <CardBody>
                    <WorkoutForm
                        initialValues={props.initialWorkoutFormValues}
                        onSubmit={props.onWorkoutFormSubmit}
                    />
                </CardBody>
            </Card>
        </Container>
    </DefaultTemplate>
);

export default CreateWorkoutPage;
