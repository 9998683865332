import React from 'react';
import { useField } from 'formik';
import { FormGroup, Label, Input, FormFeedback } from 'reactstrap';

export interface GenderFieldProps {}

const GenderField: React.FC<GenderFieldProps> = () => {
    const [field, meta] = useField('gender');

    return (
        <FormGroup>
            <Label for="gender">Gender</Label>
            <Input type="select" id="gender" invalid={!!meta.error && meta.touched} {...field}>
                <option value="male">Male</option>
                <option value="female">Female</option>
            </Input>
            <FormFeedback>{meta.error}</FormFeedback>
        </FormGroup>
    );
};

export default GenderField;
