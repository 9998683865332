import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import { Draggable, Droppable } from 'components/atoms';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import { Link } from 'react-router-dom';
import { Card, CardBody, CardHeader, CardText, CardTitle, Col, Container, Row } from 'reactstrap';
import { moveDraggableItem, moveItemInArray } from 'utilities/helpers';

type LeadBoardLists = {
    new: LeadBoardItem[];
    inProgress: LeadBoardItem[];
    converted: LeadBoardItem[];
    dead: LeadBoardItem[];
};

export type LeadBoardItem = {
    id: string;
    name: string;
    email: string;
    status: string;
};

export interface LeadsBoardProps {
    items: LeadBoardItem[];
    onMove: (lead: LeadBoardItem) => void;
}

const LeadsBoard: React.FC<LeadsBoardProps> = ({ items, onMove }) => {
    const [leads, setLeads] = useState<LeadBoardLists>({
        new: [],
        inProgress: [],
        converted: [],
        dead: []
    });

    useEffect(() => {
        const initialLeads = items.reduce(
            (acc: LeadBoardLists, item) => {
                switch (item.status) {
                    case 'new':
                        acc.new.push(item);
                        break;
                    case 'inProgress':
                        acc.inProgress.push(item);
                        break;
                    case 'converted':
                        acc.converted.push(item);
                        break;
                    case 'dead':
                        acc.dead.push(item);
                        break;
                    default:
                        break;
                }
                return acc;
            },
            {
                new: [],
                inProgress: [],
                converted: [],
                dead: []
            }
        );

        setLeads(initialLeads);
    }, []);

    const onDragEnd = (result: DropResult): void => {
        const { source, destination } = result;

        if (!destination) {
            return;
        }

        if (source.droppableId === destination.droppableId) {
            const result = moveItemInArray(
                leads[source.droppableId as keyof typeof leads],
                source.index,
                destination.index
            );

            setLeads(prevState => ({ ...prevState, [source.droppableId]: result }));
        } else {
            const result = moveDraggableItem(
                leads[source.droppableId as keyof typeof leads],
                leads[destination.droppableId as keyof typeof leads],
                source,
                destination
            );

            Object.keys(result).map(key => {
                const updatedResult = result[key].map(item => {
                    return {
                        ...item,
                        status: key as 'new' | 'inProgress' | 'converted' | 'dead'
                    };
                });

                const movedLead = updatedResult.find((_, index) => index === destination.index);

                movedLead && onMove(movedLead);

                setLeads(prevState => ({ ...prevState, [key]: updatedResult }));
            });
        }
    };

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Container>
                <Row>
                    {Object.keys(leads).map((key, index) => (
                        <Col key={index} sm="6" md="4" xl="3">
                            <Card>
                                <CardHeader tag="h5">{key}</CardHeader>
                                <CardBody>
                                    <Droppable droppableId={key}>
                                        {leads[key as keyof typeof leads].map(
                                            ({ id, name, email }, index) => (
                                                <Draggable
                                                    key={index}
                                                    draggableId={id}
                                                    index={index}>
                                                    <Card
                                                        className={classnames(
                                                            'border-bottom-0 border-start-lg border-end-0 border-top-0 border-primary h-100 lift user-select-none',
                                                            {
                                                                'mb-4':
                                                                    index !==
                                                                    leads[key as keyof typeof leads]
                                                                        .length -
                                                                        1
                                                            }
                                                        )}>
                                                        <CardBody>
                                                            <CardTitle
                                                                tag={Link}
                                                                to={`/leads/${id}`}>
                                                                {name}
                                                            </CardTitle>
                                                            <CardText>{email}</CardText>
                                                        </CardBody>
                                                    </Card>
                                                </Draggable>
                                            )
                                        )}
                                    </Droppable>
                                </CardBody>
                            </Card>
                        </Col>
                    ))}
                </Row>
            </Container>
        </DragDropContext>
    );
};

export default LeadsBoard;
