import React from 'react';
import type { ExerciseFormSubmitFunction, ExerciseFormValues } from 'components/organisms';
import { ExerciseForm } from 'components/organisms';
import { DefaultTemplate } from 'components/templates';
import { Card, CardBody, CardHeader, Container } from 'reactstrap';

export interface CreateExercisePageProps {
    initialExerciseFormValues: ExerciseFormValues;
    onExerciseFormSubmit: ExerciseFormSubmitFunction;
}

const CreateExercisePage: React.FC<CreateExercisePageProps> = props => (
    <DefaultTemplate title="Create Exercise">
        <Container className="mt-n10">
            <Card className="mb-4">
                <CardHeader tag="h5">Details</CardHeader>
                <CardBody>
                    <ExerciseForm
                        initialValues={props.initialExerciseFormValues}
                        onSubmit={props.onExerciseFormSubmit}
                    />
                </CardBody>
            </Card>
        </Container>
    </DefaultTemplate>
);

export default CreateExercisePage;
