import React from 'react';
import { Button, InfiniteScroll } from 'components/molecules';
import Moment from 'react-moment';
import { Link } from 'react-router-dom';
import type { TableProps } from 'reactstrap';
import { Table } from 'reactstrap';

export type ExercisesTableData = {
    id: string;
    name: string;
    createdAt: string;
    updatedAt: string;
};

export interface ExercisesTableProps extends TableProps {
    data: ExercisesTableData[];
    next: () => void;
}

const ExercisesTable: React.FC<ExercisesTableProps> = ({ data, next, ...props }) => (
    <InfiniteScroll dataLength={data.length} next={next} hasMore>
        <Table {...props}>
            <thead>
                <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Created</th>
                    <th scope="col">Updated</th>
                    <th scope="col"></th>
                </tr>
            </thead>
            <tbody>
                {data.map((exercise, index) => (
                    <tr key={index}>
                        <td>
                            <Link to={`/exercises/${exercise.id}`}>{exercise.name}</Link>
                        </td>
                        <td>
                            <Moment fromNow>{exercise.createdAt}</Moment>
                        </td>
                        <td>
                            <Moment fromNow>{exercise.updatedAt}</Moment>
                        </td>
                        <td>
                            <Button.Edit
                                tag={Link}
                                to={`/exercises/${exercise.id}/update`}
                                color="primary"
                                role="button"
                                size="sm"
                                block
                            />
                        </td>
                    </tr>
                ))}
            </tbody>
        </Table>
    </InfiniteScroll>
);

export default ExercisesTable;
