import React from 'react';
import type { ExerciseFormSubmitFunction, ExerciseFormValues } from 'components/organisms';
import { ExerciseForm } from 'components/organisms';
import { DefaultTemplate } from 'components/templates';
import { Button, Card, CardBody, CardHeader, Container } from 'reactstrap';

export interface UpdateExercisePageProps {
    initialExerciseFormValues: ExerciseFormValues;
    onDeleteButtonClick: () => void;
    onExerciseFormSubmit: ExerciseFormSubmitFunction;
}

const UpdateExercisePage: React.FC<UpdateExercisePageProps> = ({
    initialExerciseFormValues,
    onDeleteButtonClick,
    onExerciseFormSubmit
}) => (
    <DefaultTemplate title="Update Exercise">
        <Container className="mt-n10">
            <Card className="mb-4">
                <CardHeader tag="h5">Details</CardHeader>
                <CardBody>
                    <ExerciseForm
                        initialValues={initialExerciseFormValues}
                        onSubmit={onExerciseFormSubmit}
                    />
                </CardBody>
            </Card>
            <Card className="mb-4">
                <CardHeader tag="h5">Delete Exercise</CardHeader>
                <CardBody>
                    <p className="fw-bold text-danger">Deleted exercises cannot be restored!</p>
                    <Button type="button" color="danger" onClick={onDeleteButtonClick}>
                        Delete Exercise
                    </Button>
                </CardBody>
            </Card>
        </Container>
    </DefaultTemplate>
);

export default UpdateExercisePage;
