import {
    COLUMN,
    COLUMNS,
    DATE_RANGE,
    DATE_RANGES,
    RADIO,
    WIDGET,
    WIDGETS,
    FREQUENCY,
    FREQUENCIES
} from '..';

const progressiveOverload = {
    inputs: {
        [WIDGET]: {
            type: RADIO,
            defaultOption: WIDGETS.LINE_CHART,
            options: [WIDGETS.LINE_CHART]
        },
        [COLUMN]: {
            type: RADIO,
            defaultOption: COLUMNS.WEIGHT,
            options: [COLUMNS.REPS, COLUMNS.WEIGHT]
        },
        [DATE_RANGE]: {
            type: RADIO,
            defaultOption: DATE_RANGES.LAST_30_DAYS,
            options: [DATE_RANGES.LAST_7_DAYS, DATE_RANGES.LAST_14_DAYS, DATE_RANGES.LAST_30_DAYS]
        },
        [FREQUENCY]: {
            type: RADIO,
            defaultOption: FREQUENCIES.WEEKLY,
            options: [FREQUENCIES.DAILY, FREQUENCIES.MONTHLY, FREQUENCIES.WEEKLY]
        }
    }
};

export default progressiveOverload;
