import React from 'react';
import { Button, Field, Form } from 'components/molecules';
import { Col, Row } from 'reactstrap';
import LeadFormSchema from './LeadForm.schema';
import type { LeadFormSubmitFunction, LeadFormValues } from './LeadForm.types';

interface LeadFormProps {
    initialValues: LeadFormValues;
    onSubmit: LeadFormSubmitFunction;
}

const LeadForm: React.FC<LeadFormProps> = ({ initialValues, onSubmit }) => (
    <Form initialValues={initialValues} onSubmit={onSubmit} validationSchema={LeadFormSchema}>
        <Field.Email />
        <Row>
            <Col md="6">
                <Field.FirstName />
            </Col>
            <Col md="6">
                <Field.LastName />
            </Col>
        </Row>
        <Field.DateOfBirth />
        <Field.Gender />
        <Row>
            <Col md="6">
                <Field.Height />
            </Col>
            <Col md="6">
                <Field.Weight />
            </Col>
        </Row>
        <Field.ActivityLevel />
        <Field.Goal />
        <Field.Status />
        <Button.Save />
    </Form>
);

export default LeadForm;
