import { ZodError } from 'zod';

function createValidationError(error: ZodError) {
    const result: Record<string, string> = {};

    for (const x of error.errors) {
        result[x.path.filter(Boolean).join('.')] = x.message;
    }

    return result;
}

export default createValidationError;
