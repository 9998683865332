import React from 'react';
import { Button, Field, Form } from 'components/molecules';
import ExerciseFormSchema from './ExerciseForm.schema';
import type { ExerciseFormSubmitFunction, ExerciseFormValues } from './ExerciseForm.types';

interface ExerciseFormProps {
    initialValues: ExerciseFormValues;
    onSubmit: ExerciseFormSubmitFunction;
}

const ExerciseForm: React.FC<ExerciseFormProps> = ({ initialValues, onSubmit }) => (
    <Form initialValues={initialValues} onSubmit={onSubmit} validationSchema={ExerciseFormSchema}>
        <Field.Name />
        <Field.Description />
        <Button.Save />
    </Form>
);

export default ExerciseForm;
