import React from 'react';
import { Button } from 'components/molecules';
import type { UsersTableData } from 'components/organisms';
import { UsersTable } from 'components/organisms';
import { DefaultTemplate } from 'components/templates';
import { Link } from 'react-router-dom';
import { Card, CardHeader, Container } from 'reactstrap';

export interface UsersPageProps {
    usersTableData: UsersTableData[];
    next: () => void;
}

const UsersPage: React.FC<UsersPageProps> = ({ usersTableData, next }) => (
    <DefaultTemplate
        title="Users"
        content={<Button.Create tag={Link} color="primary" to="/users/create" />}>
        <Container className="mt-n10">
            <Card className="mb-4">
                <CardHeader tag="h5">Users</CardHeader>
                <UsersTable
                    className="mb-0"
                    data={usersTableData}
                    next={next}
                    borderless
                    responsive
                    striped
                />
            </Card>
        </Container>
    </DefaultTemplate>
);

export default UsersPage;
