import React from 'react';
import { Button, Field, Form } from 'components/molecules';
import WorkoutFormSchema from './WorkoutForm.schema';
import type { WorkoutFormSubmitFunction, WorkoutFormValues } from './WorkoutForm.types';

interface WorkoutFormProps {
    initialValues: WorkoutFormValues;
    onSubmit: WorkoutFormSubmitFunction;
}

const WorkoutForm: React.FC<WorkoutFormProps> = ({ initialValues, onSubmit }) => (
    <Form initialValues={initialValues} onSubmit={onSubmit} validationSchema={WorkoutFormSchema}>
        <Field.Name />
        <Field.Description />
        <Button.Save />
    </Form>
);

export default WorkoutForm;
