// Metrics
export const METRIC = 'METRIC';
export const METRICS = {
    MEASUREMENTS: 'MEASUREMENTS',
    PROGRESSIVE_OVERLOAD: 'PROGRESSIVE_OVERLOAD'
};

// Columns
export const COLUMNS = {
    ARMS: 'ARMS',
    CHEST: 'CHEST',
    HEIGHT: 'HEIGHT',
    HIPS: 'HIPS',
    NECK: 'NECK',
    REPS: 'REPS',
    THIGHS: 'THIGHS',
    WAIST: 'WAIST',
    WEIGHT: 'WEIGHT'
};

// Date Ranges
export const DATE_RANGES = {
    LAST_7_DAYS: 'LAST_7_DAYS',
    LAST_14_DAYS: 'LAST_14_DAYS',
    LAST_30_DAYS: 'LAST_30_DAYS',
    LAST_60_DAYS: 'LAST_60_DAYS',
    LAST_90_DAYS: 'LAST_90_DAYS',
    LAST_6_MONTHS: 'LAST_6_MONTHS',
    LAST_1_YEAR: 'LAST_1_YEAR',
    LAST_2_YEARS: 'LAST_2_YEARS',
    LAST_5_YEARS: 'LAST_5_YEARS'
};

// Frequencies
export const FREQUENCIES = {
    DAILY: 'DAILY',
    WEEKLY: 'WEEKLY',
    MONTHLY: 'MONTHLY'
};

// Widgets
export const WIDGETS = {
    LINE_CHART: 'LINE_CHART',
    TABLE: 'TABLE'
};

// Form inputs
export const COLUMN = 'COLUMN';
export const DATE_RANGE = 'DATE_RANGE';
export const EXERCISE_ID = 'EXERCISE_ID';
export const FREQUENCY = 'FREQUENCY';
export const PROGRAMME_ID = 'PROGRAMME_ID';
export const USER_ID = 'USER_ID';
export const WIDGET = 'WIDGET';
export const WORKOUT_ID = 'WORKOUT_ID';

//  Form types
export const CHECKBOX = 'CHECKBOX';
export const RADIO = 'RADIO';
