import React from 'react';
import { useField } from 'formik';
import { FormGroup, Label, Input, FormFeedback } from 'reactstrap';

export interface StartDateFieldProps {}

const StartDateField: React.FC<StartDateFieldProps> = () => {
    const [field, meta] = useField('startDate');

    return (
        <FormGroup>
            <Label for="startDate">Start Date</Label>
            <Input type="date" id="startDate" invalid={!!meta.error && meta.touched} {...field} />
            <FormFeedback>{meta.error}</FormFeedback>
        </FormGroup>
    );
};

export default StartDateField;
