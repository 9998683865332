import React from 'react';
import type { UserFormSubmitFunction, UserFormValues } from 'components/organisms';
import { NoMatchPage, UpdateUserPage } from 'components/pages';
import { useDeleteUserMutation, useGetUserQuery, useUpdateUserMutation } from 'graphql/generated';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { deleteRefFromCache } from 'utilities/helpers';

export interface UpdateUserProps {}

const UpdateUser: React.FC<UpdateUserProps> = () => {
    const navigate = useNavigate();

    const { userId } = useParams() as { userId: string };

    const { data, loading, error } = useGetUserQuery({ variables: { userId } });

    const [deleteUser] = useDeleteUserMutation();

    const [updateUser] = useUpdateUserMutation();

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error</div>;
    }

    if (!data) {
        return <NoMatchPage />;
    }

    const onDeleteButtonClick = (): void => {
        deleteUser({
            variables: { userId },
            onCompleted: () => {
                toast.success('User deleted successfully!');
                navigate('/users');
            },
            onError: () => {
                toast.error('Failed to delete user!');
            },
            update: cache =>
                deleteRefFromCache(cache, {
                    id: `User:${userId}`,
                    fieldName: 'users',
                    refId: userId
                })
        });
    };

    const initialUserFormValues: UserFormValues = {
        activityLevel: data.user.activity_level,
        dateOfBirth: data.user.date_of_birth,
        email: data.user.email,
        firstName: data.user.first_name,
        gender: data.user.gender,
        lastName: data.user.last_name
    };

    const onUserFormSubmit: UserFormSubmitFunction = (values, helpers): void => {
        updateUser({
            variables: {
                userId,
                activityLevel: values.activityLevel,
                dateOfBirth: values.dateOfBirth,
                email: values.email,
                firstName: values.firstName,
                lastName: values.lastName,
                gender: values.gender
            },
            onCompleted: () => {
                toast.success('User updated successfully!');
                helpers.setSubmitting(false);
            },
            onError: () => {
                toast.error('Failed to update user!');
                helpers.setSubmitting(false);
            }
        });
    };

    return <UpdateUserPage {...{ initialUserFormValues, onDeleteButtonClick, onUserFormSubmit }} />;
};

export default UpdateUser;
