import React from 'react';
import type { ChangePasswordFormHelpers, ChangePasswordFormValues } from 'components/organisms';
import { ChangePasswordForm } from 'components/organisms';
import { DefaultTemplate } from 'components/templates';
import { Card, CardBody, CardHeader, Container } from 'reactstrap';
import type { ChangePassword } from './AccountSecurityPage.types';

interface AccountSecurityPageProps {
    changePassword: ChangePassword;
}

const AccountSecurityPage: React.FC<AccountSecurityPageProps> = ({ changePassword }) => {
    const initialChangePasswordFormValues: ChangePasswordFormValues = {
        currentPassword: '',
        newPassword: '',
        confirmPassword: ''
    };

    const handleChangePasswordFormSubmit = (
        { currentPassword, newPassword }: ChangePasswordFormValues,
        { resetForm }: ChangePasswordFormHelpers
    ) => {
        changePassword(currentPassword, newPassword, () => {
            resetForm();
        });
    };

    return (
        <DefaultTemplate title="Account - Security">
            <Container className="mt-n10">
                <Card className="mb-4">
                    <CardHeader tag="h5">Change Password</CardHeader>
                    <CardBody>
                        <ChangePasswordForm
                            initialValues={initialChangePasswordFormValues}
                            onSubmit={handleChangePasswordFormSubmit}
                        />
                    </CardBody>
                </Card>
            </Container>
        </DefaultTemplate>
    );
};

export default AccountSecurityPage;
