import React from 'react';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome';

export interface CreateIconProps extends Omit<FontAwesomeIconProps, 'icon'> {}

const CreateIcon: React.FC<CreateIconProps> = ({ ...props }) => (
    <FontAwesomeIcon icon={faPlus} {...props} />
);

export default CreateIcon;
