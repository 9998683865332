import React from 'react';
import { faVenus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome';

export interface FemaleIconProps extends Omit<FontAwesomeIconProps, 'icon'> {}

const FemaleIcon: React.FC<FemaleIconProps> = ({ ...props }) => (
    <FontAwesomeIcon icon={faVenus} {...props} />
);

export default FemaleIcon;
