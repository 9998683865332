import React from 'react';
import { useField } from 'formik';
import { FormGroup, Label, Input, FormFeedback } from 'reactstrap';

export interface GoalFieldProps {}

const GoalField: React.FC<GoalFieldProps> = () => {
    const [field, meta] = useField('goal');

    return (
        <FormGroup>
            <Label for="goal">Goal</Label>
            <Input type="select" id="goal" invalid={!!meta.error && meta.touched} {...field}>
                <option value="loss">Fat loss</option>
                <option value="gain">Muscle Gain</option>
                <option value="maintenance">Maintenance</option>
            </Input>
            <FormFeedback>{meta.error}</FormFeedback>
        </FormGroup>
    );
};

export default GoalField;
