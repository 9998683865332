import React from 'react';
import { Button, InfiniteScroll, StatusBadge } from 'components/molecules';
import Moment from 'react-moment';
import { Link } from 'react-router-dom';
import type { TableProps } from 'reactstrap';
import { Table } from 'reactstrap';

interface ProgrammesTableProps extends TableProps {
    data: {
        id: string;
        name: string;
        startDate: string;
        endDate: string;
        status: string;
        createdAt: string;
        updatedAt: string;
    }[];
    next: () => void;
}

const ProgrammesTable: React.FC<ProgrammesTableProps> = ({ data, next, ...props }) => (
    <InfiniteScroll dataLength={data.length} next={next} hasMore>
        <Table {...props}>
            <thead>
                <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Status</th>
                    <th scope="col">Start</th>
                    <th scope="col">End</th>
                    <th scope="col">Created</th>
                    <th scope="col">Updated</th>
                    <th scope="col"></th>
                </tr>
            </thead>
            <tbody>
                {data.map((programme, index) => (
                    <tr key={index}>
                        <td>
                            <Link to={`/programmes/${programme.id}`}>{programme.name}</Link>
                        </td>
                        <td>
                            <StatusBadge status={programme.status} />
                        </td>
                        <td>
                            <Moment format="MMM D, YYYY">{programme.startDate}</Moment>
                        </td>
                        <td>
                            <Moment format="MMM D, YYYY">{programme.endDate}</Moment>
                        </td>
                        <td>
                            <Moment fromNow>{programme.createdAt}</Moment>
                        </td>
                        <td>
                            <Moment fromNow>{programme.updatedAt}</Moment>
                        </td>
                        <td>
                            <Button.Edit
                                tag={Link}
                                to={`/programmes/${programme.id}/update`}
                                color="primary"
                                role="button"
                                size="sm"
                                block
                            />
                        </td>
                    </tr>
                ))}
            </tbody>
        </Table>
    </InfiniteScroll>
);

export default ProgrammesTable;
