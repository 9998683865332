import React from 'react';
import { useField } from 'formik';
import { FormGroup, Label, Input, FormFeedback } from 'reactstrap';

export interface FirstNameFieldProps {}

const FirstNameField: React.FC<FirstNameFieldProps> = () => {
    const [field, meta] = useField('firstName');

    return (
        <FormGroup>
            <Label for="firstName">First Name</Label>
            <Input type="text" id="firstName" invalid={!!meta.error && meta.touched} {...field} />
            <FormFeedback>{meta.error}</FormFeedback>
        </FormGroup>
    );
};

export default FirstNameField;
