import React from 'react';
import type {
    ExercisesFormHelpers,
    ExercisesFormValues,
    WorkoutFormSubmitFunction,
    WorkoutFormValues
} from 'components/organisms';
import { ExercisesForm, WorkoutForm } from 'components/organisms';
import { DefaultTemplate } from 'components/templates';
import { Button, Card, CardBody, CardHeader, Container } from 'reactstrap';

export interface UpdateWorkoutPageProps {
    initialExercisesFormValues: ExercisesFormValues;
    initialWorkoutFormValues: WorkoutFormValues;
    loadOptions: (inputValue: string) => Promise<{ value: string; label: string }[]>;
    onDeleteButtonClick: () => void;
    onExercisesFormSubmit: (values: ExercisesFormValues, helpers: ExercisesFormHelpers) => void;
    onWorkoutFormSubmit: WorkoutFormSubmitFunction;
}

const UpdateWorkoutPage: React.FC<UpdateWorkoutPageProps> = ({
    initialExercisesFormValues,
    initialWorkoutFormValues,
    loadOptions,
    onDeleteButtonClick,
    onExercisesFormSubmit,
    onWorkoutFormSubmit
}) => (
    <DefaultTemplate title="Update Workout">
        <Container className="mt-n10">
            <Card className="mb-4">
                <CardHeader tag="h5">Details</CardHeader>
                <CardBody>
                    <WorkoutForm
                        initialValues={initialWorkoutFormValues}
                        onSubmit={onWorkoutFormSubmit}
                    />
                </CardBody>
            </Card>
            <Card className="mb-4">
                <CardHeader tag="h5">Exercises</CardHeader>
                <CardBody>
                    <ExercisesForm
                        initialValues={initialExercisesFormValues}
                        onSubmit={onExercisesFormSubmit}
                        loadOptions={loadOptions}
                    />
                </CardBody>
            </Card>
            <Card className="mb-4">
                <CardHeader tag="h5">Delete Workout</CardHeader>
                <CardBody>
                    <p className="fw-bold text-danger">Deleted workouts cannot be restored!</p>
                    <Button type="button" color="danger" onClick={onDeleteButtonClick}>
                        Delete Workout
                    </Button>
                </CardBody>
            </Card>
        </Container>
    </DefaultTemplate>
);

export default UpdateWorkoutPage;
