import { METRIC, METRICS } from 'utilities/constants';
import { BACKEND_API_DOMAIN } from 'utilities/constants/environment';
import measurements from './measurements';
import progressiveOverload from './progressiveOverload';

function toUrl(config: { [key: string]: string }): string {
    switch (config[METRIC]) {
        case METRICS.MEASUREMENTS:
            return measurements(BACKEND_API_DOMAIN + '/api/v1/metrics/measurements', config);

        case METRICS.PROGRESSIVE_OVERLOAD:
            return progressiveOverload(
                BACKEND_API_DOMAIN + '/api/v1/metrics/progressive-overload',
                config
            );

        default:
            return '';
    }
}

export default toUrl;
