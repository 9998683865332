import React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ChartData
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

interface VerticalBarChartProps {
    data: ChartData<'bar', (number | [number, number] | null)[], unknown>;
}

const VerticalBarChart: React.FC<VerticalBarChartProps> = ({ data }) => <Bar data={data} />;

export default VerticalBarChart;
