import React from 'react';
import useAxios from 'axios-hooks';
import { Chart } from 'components/molecules';
import { toUrl } from 'utilities/helpers';

export interface WidgetProps {
    config: { [key: string]: string };
}

const Widget: React.FC<WidgetProps> = ({ config }) => {
    const [{ data, loading, error }] = useAxios({
        url: toUrl(config),
        headers: { authorization: `Bearer ${localStorage.getItem('token')}` }
    });

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error</div>;
    }

    if (!data) {
        return <div>No data</div>;
    }

    return (
        <Chart.Line
            data={{
                labels: Object.keys(data.result),
                datasets: [
                    {
                        backgroundColor: 'rgba(63, 141, 73, 0.5)',
                        borderColor: 'rgb(63, 141, 73)',
                        data: data.result,
                        label: ''
                    }
                ]
            }}
        />
    );
};

export default Widget;
