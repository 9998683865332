import React from 'react';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome';

export interface EditIconProps extends Omit<FontAwesomeIconProps, 'icon'> {}

const EditIcon: React.FC<EditIconProps> = ({ ...props }) => (
    <FontAwesomeIcon icon={faPencilAlt} {...props} />
);

export default EditIcon;
