import React from 'react';
import { useField } from 'formik';
import { FormFeedback, FormGroup, Input, InputGroup, InputGroupText, Label } from 'reactstrap';

export interface CaloriesFieldProps {}

const CaloriesField: React.FC<CaloriesFieldProps> = () => {
    const [field, meta] = useField('calories');

    return (
        <FormGroup>
            <Label for="calories">Calories</Label>
            <InputGroup>
                <Input
                    type="number"
                    id="calories"
                    invalid={!!meta.error && meta.touched}
                    {...field}
                />
                <InputGroupText>kcal</InputGroupText>
                <FormFeedback>{meta.error}</FormFeedback>
            </InputGroup>
        </FormGroup>
    );
};

export default CaloriesField;
