import React from 'react';
import { faMars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome';

export interface MaleIconProps extends Omit<FontAwesomeIconProps, 'icon'> {}

const MaleIcon: React.FC<MaleIconProps> = ({ ...props }) => (
    <FontAwesomeIcon icon={faMars} {...props} />
);

export default MaleIcon;
