import React from 'react';
import { NutritionTable } from 'components/organisms';
import { Card, CardHeader } from 'reactstrap';
import { UserNutritionPageData } from './UserNutritionPage.types';

interface UserNutritionPageProps {
    data: UserNutritionPageData;
}

const UserNutritionPage: React.FC<UserNutritionPageProps> = ({ data }) => (
    <React.Fragment>
        <Card>
            <CardHeader tag="h5">Nutrition</CardHeader>
            <NutritionTable data={data.nutrition} />
        </Card>
    </React.Fragment>
);

export default UserNutritionPage;
