import React from 'react';
import { useField } from 'formik';
import { FormGroup, Label, Input, FormFeedback } from 'reactstrap';

export interface ActivityLevelFieldProps {}

const ActivityLevelField: React.FC<ActivityLevelFieldProps> = () => {
    const [field, meta] = useField('activityLevel');

    return (
        <FormGroup>
            <Label for="activityLevel">Activity Level</Label>
            <Input
                type="select"
                id="activityLevel"
                invalid={!!meta.error && meta.touched}
                {...field}>
                <option value="sedentary">Sedentary</option>
                <option value="light">Light</option>
                <option value="moderate">Moderate</option>
                <option value="very">Very</option>
            </Input>
            <FormFeedback>{meta.error}</FormFeedback>
        </FormGroup>
    );
};

export default ActivityLevelField;
