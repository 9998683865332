import React from 'react';
import { useField } from 'formik';
import { FormGroup, Label, Input, FormFeedback } from 'reactstrap';

export interface NameFieldProps {}

const NameField: React.FC<NameFieldProps> = () => {
    const [field, meta] = useField('name');

    return (
        <FormGroup>
            <Label for="name">Name</Label>
            <Input type="text" id="name" invalid={!!meta.error && meta.touched} {...field} />
            <FormFeedback>{meta.error}</FormFeedback>
        </FormGroup>
    );
};

export default NameField;
