import React from 'react';
import type { CreateDashboardFunction } from 'components/pages';
import { CreateDashboardPage } from 'components/pages';
import { useCreateDashboardMutation } from 'graphql/generated';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

interface CreateDashboardProps {}

const CreateDashboard: React.FC<CreateDashboardProps> = () => {
    const navigate = useNavigate();

    const [createDashboard] = useCreateDashboardMutation();

    const handleCreateDashboard: CreateDashboardFunction = (name, description) => {
        createDashboard({
            variables: { name, description },
            onCompleted: data => {
                toast.success('Dashboard created successfully!');
                navigate(`/dashboards/${data.createDashboard.id}`);
            },
            onError: () => {
                toast.error('Failed to create dashboard!');
            },
            update: (cache, result) => {
                cache.modify({
                    fields: {
                        dashboards: (existingRefs = []) => [
                            ...existingRefs,
                            result.data!.createDashboard
                        ]
                    }
                });
            }
        });
    };

    return <CreateDashboardPage createDashboard={handleCreateDashboard} />;
};

export default CreateDashboard;
