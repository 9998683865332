import React from 'react';
import { Button } from 'components/molecules';
import { Formik, FormikHelpers } from 'formik';
import { Form, FormFeedback, FormGroup, Input, Label } from 'reactstrap';

export type ChangePasswordFormValues = {
    currentPassword: string;
    newPassword: string;
    confirmPassword: string;
};

export type ChangePasswordFormHelpers = FormikHelpers<ChangePasswordFormValues>;

export interface ChangePasswordFormProps {
    initialValues: ChangePasswordFormValues;
    onSubmit: (values: ChangePasswordFormValues, helpers: ChangePasswordFormHelpers) => void;
}

const ChangePasswordForm: React.FC<ChangePasswordFormProps> = props => (
    <Formik {...props}>
        {form => (
            <Form onSubmit={form.handleSubmit}>
                <FormGroup>
                    <Label for="currentPassword">Current Password</Label>
                    <Input
                        id="currentPassword"
                        type="password"
                        name="currentPassword"
                        disabled={form.isSubmitting}
                        onBlur={form.handleBlur}
                        onChange={form.handleChange}
                        value={form.values.currentPassword}
                        invalid={!!form.errors.currentPassword && form.touched.currentPassword}
                    />
                    <FormFeedback>{form.errors.currentPassword}</FormFeedback>
                </FormGroup>
                <FormGroup>
                    <Label for="newPassword">New Password</Label>
                    <Input
                        id="newPassword"
                        type="password"
                        name="newPassword"
                        disabled={form.isSubmitting}
                        onBlur={form.handleBlur}
                        onChange={form.handleChange}
                        value={form.values.newPassword}
                        invalid={!!form.errors.newPassword && form.touched.newPassword}
                    />
                    <FormFeedback>{form.errors.newPassword}</FormFeedback>
                </FormGroup>
                <FormGroup>
                    <Label for="confirmPassword">Confirm Password</Label>
                    <Input
                        id="confirmPassword"
                        type="password"
                        name="confirmPassword"
                        disabled={form.isSubmitting}
                        onBlur={form.handleBlur}
                        onChange={form.handleChange}
                        value={form.values.confirmPassword}
                        invalid={!!form.errors.confirmPassword && form.touched.confirmPassword}
                    />
                    <FormFeedback>{form.errors.confirmPassword}</FormFeedback>
                </FormGroup>
                <Button.Save />
            </Form>
        )}
    </Formik>
);

export default ChangePasswordForm;
