import React, { useEffect, useState } from 'react';
import { ExerciseData, ExercisePage } from 'components/pages';
import { useGetExerciseQuery } from 'graphql/generated';
import { useParams } from 'react-router-dom';

export interface ExerciseProps {}

const Exercise: React.FC<ExerciseProps> = () => {
    const { exerciseId } = useParams() as { exerciseId: string };

    const { data, loading, error } = useGetExerciseQuery({ variables: { exerciseId } });

    const [exerciseData, setExerciseData] = useState<ExerciseData>();

    useEffect(() => {
        if (data?.exercise) {
            setExerciseData({
                id: data.exercise.id,
                name: data.exercise.name,
                description: data.exercise.description || '',
                createdAt: data.exercise.created_at,
                updatedAt: data.exercise.updated_at || '',
                workouts:
                    data.exercise.workouts?.map(workout => ({
                        id: workout.id,
                        name: workout.name
                    })) || []
            });
        }
    }, [data]);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error!</div>;
    }

    if (!exerciseData) {
        return null;
    }

    return <ExercisePage data={exerciseData} />;
};

export default Exercise;
