import React from 'react';
import { Authenticated, Authentication, Default, Error, Unauthenticated } from 'layouts';
import { Navigate, Route, Routes } from 'react-router-dom';
import {
    AccountSecurity,
    CreateDashboard,
    CreateExercise,
    CreateLead,
    CreateUser,
    CreateWorkout,
    Dashboard,
    Dashboards,
    Exercise,
    Exercises,
    ForgotPassword,
    Lead,
    Leads,
    Login,
    NoMatch,
    Programme,
    UpdateDashboard,
    UpdateExercise,
    UpdateGoal,
    UpdateLead,
    UpdateProgramme,
    UpdateUser,
    UpdateWorkout,
    User,
    UserActivity,
    UserGoals,
    UserNutrition,
    UserProgrammes,
    Users,
    Workout,
    Workouts
} from './routes';

function App() {
    return (
        <Routes>
            {/* Authenticated routes */}
            <Route element={<Authenticated />}>
                <Route element={<Default />}>
                    <Route path="/" element={<Navigate to="/dashboards" replace />} />

                    <Route path="/account/security" element={<AccountSecurity />} />

                    <Route path="/dashboards/:dashboardId/update" element={<UpdateDashboard />} />
                    <Route path="/dashboards/:dashboardId" element={<Dashboard />} />
                    <Route path="/dashboards/create" element={<CreateDashboard />} />
                    <Route path="/dashboards" element={<Dashboards />} />

                    <Route path="/exercises/:exerciseId/update" element={<UpdateExercise />} />
                    <Route path="/exercises/:exerciseId" element={<Exercise />} />
                    <Route path="/exercises/create" element={<CreateExercise />} />
                    <Route path="/exercises" element={<Exercises />} />

                    <Route path="/goals/:goalId/update" element={<UpdateGoal />} />

                    <Route path="/leads/:leadId/update" element={<UpdateLead />} />
                    <Route path="/leads/:leadId" element={<Lead />} />
                    <Route path="/leads/create" element={<CreateLead />} />
                    <Route path="/leads" element={<Leads />} />

                    <Route path="/programmes/:programmeId/update" element={<UpdateProgramme />} />
                    <Route path="/programmes/:programmeId" element={<Programme />} />

                    <Route path="/users/:userId/update" element={<UpdateUser />} />

                    <Route path="/users/:userId" element={<User />}>
                        <Route path="goals" element={<UserGoals />} />
                        <Route path="nutrition" element={<UserNutrition />} />
                        <Route path="programmes" element={<UserProgrammes />} />
                        <Route index element={<UserActivity />} />
                    </Route>

                    <Route path="/users/:userId" element={<User />} />
                    <Route path="/users/create" element={<CreateUser />} />
                    <Route path="/users" element={<Users />} />

                    <Route path="/workouts/:workoutId/update" element={<UpdateWorkout />} />
                    <Route path="/workouts/:workoutId" element={<Workout />} />
                    <Route path="/workouts/create" element={<CreateWorkout />} />
                    <Route path="/workouts" element={<Workouts />} />
                </Route>
            </Route>

            {/* Unauthenticated routes */}
            <Route element={<Unauthenticated />}>
                <Route element={<Authentication />}>
                    <Route path="login" element={<Login />} />
                    <Route path="forgot-password" element={<ForgotPassword />} />
                </Route>
            </Route>

            <Route element={<Error />}>
                <Route path="*" element={<NoMatch />} />
            </Route>
        </Routes>
    );
}

export default App;
