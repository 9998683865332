import React from 'react';
import type { UserFormSubmitFunction, UserFormValues } from 'components/organisms';
import { UserForm } from 'components/organisms';
import { DefaultTemplate } from 'components/templates';
import { Button, Card, CardBody, CardHeader, Container } from 'reactstrap';

export interface UpdateUserPageProps {
    initialUserFormValues: UserFormValues;
    onDeleteButtonClick: () => void;
    onUserFormSubmit: UserFormSubmitFunction;
}

const UpdateUserPage: React.FC<UpdateUserPageProps> = ({
    initialUserFormValues,
    onDeleteButtonClick,
    onUserFormSubmit
}) => (
    <DefaultTemplate title="Update User">
        <Container className="mt-n10">
            <Card className="mb-4">
                <CardHeader tag="h5">Details</CardHeader>
                <CardBody>
                    <UserForm initialValues={initialUserFormValues} onSubmit={onUserFormSubmit} />
                </CardBody>
            </Card>
            <Card className="mb-4">
                <CardHeader tag="h5">Delete User</CardHeader>
                <CardBody>
                    <p className="fw-bold text-danger">Deleted users cannot be restored!</p>
                    <Button type="button" color="danger" onClick={onDeleteButtonClick}>
                        Delete User
                    </Button>
                </CardBody>
            </Card>
        </Container>
    </DefaultTemplate>
);

export default UpdateUserPage;
