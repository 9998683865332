import React from 'react';
import { Button, SelectField } from 'components/molecules';
import { FieldArray, Formik, FormikHelpers } from 'formik';
import { Form, FormGroup, ListGroup, ListGroupItem } from 'reactstrap';
import { z } from 'zod';

export const WorkoutsFormSchema = z.object({
    workouts: z.array(
        z.object({
            id: z.string(),
            name: z.string(),
            description: z.string()
        })
    )
});

export type WorkoutsFormValues = z.infer<typeof WorkoutsFormSchema>;

export type WorkoutsFormHelpers = FormikHelpers<WorkoutsFormValues>;

export interface WorkoutsFormProps {
    initialValues: WorkoutsFormValues;
    onSubmit: (values: WorkoutsFormValues, helpers: WorkoutsFormHelpers) => void;
    promiseOptions: (inputValue: string) => Promise<any[]>;
}

const WorkoutsForm: React.FC<WorkoutsFormProps> = ({ initialValues, onSubmit, promiseOptions }) => (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
        {({ values, handleBlur, handleSubmit, submitForm }) => (
            <Form onSubmit={handleSubmit}>
                <FieldArray name="workouts">
                    {({ push, remove }) => {
                        const addWorkout = (value: any) => {
                            push({
                                id: value.value,
                                name: value.label,
                                description: ''
                            });
                            submitForm();
                        };

                        const removeWorkout = (index: number): void => {
                            remove(index);
                            submitForm();
                        };

                        const loadOptions = async (inputValue: string) => {
                            const options = await promiseOptions(inputValue);
                            return options.filter(
                                option =>
                                    !values.workouts.some(workout => workout.id === option.value)
                            );
                        };
                        return (
                            <React.Fragment>
                                <FormGroup>
                                    <ListGroup>
                                        {values.workouts.map((workout, index) => (
                                            <ListGroupItem key={index}>
                                                <div className="d-flex align-items-center justify-content-between">
                                                    <div className="d-flex flex-column fw-bold">
                                                        <div className="line-height-normal mb-1">
                                                            {workout.name}
                                                        </div>
                                                        <div className="small text-muted line-height-normal">
                                                            {workout.description}
                                                        </div>
                                                    </div>
                                                    <Button.Remove
                                                        type="button"
                                                        onClick={() => removeWorkout(index)}
                                                    />
                                                </div>
                                            </ListGroupItem>
                                        ))}
                                    </ListGroup>
                                </FormGroup>
                                <SelectField
                                    name="seach"
                                    onBlur={handleBlur}
                                    onChange={addWorkout}
                                    loadOptions={loadOptions}
                                />
                            </React.Fragment>
                        );
                    }}
                </FieldArray>
            </Form>
        )}
    </Formik>
);

export default WorkoutsForm;
