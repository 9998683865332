import React from 'react';
import type {
    ExercisesFormHelpers,
    ExercisesFormValues,
    WorkoutFormSubmitFunction,
    WorkoutFormValues
} from 'components/organisms';
import { NoMatchPage, UpdateWorkoutPage } from 'components/pages';
import {
    useDeleteWorkoutMutation,
    useGetExercisesLazyQuery,
    useGetWorkoutQuery,
    useUpdateWorkoutMutation
} from 'graphql/generated';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { deleteRefFromCache } from 'utilities/helpers';

export interface UpdateWorkoutProps {}

const UpdateWorkout: React.FC<UpdateWorkoutProps> = () => {
    const navigate = useNavigate();

    const { workoutId } = useParams() as { workoutId: string };

    const [getExercises] = useGetExercisesLazyQuery({ fetchPolicy: 'no-cache' });

    const { data, loading, error } = useGetWorkoutQuery({ variables: { workoutId } });

    const [deleteWorkout] = useDeleteWorkoutMutation();

    const [updateWorkout] = useUpdateWorkoutMutation();

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error</div>;
    }

    if (!data) {
        return <NoMatchPage />;
    }

    const initialExercisesFormValues: ExercisesFormValues = {
        exercises:
            data.workout.exercises?.map(exercise => ({
                id: exercise.id,
                name: exercise.name,
                minSets: exercise.pivot?.min_sets || 0,
                maxSets: exercise.pivot?.max_sets || 0,
                minReps: exercise.pivot?.min_reps || 0,
                maxReps: exercise.pivot?.max_reps || 0,
                rest: exercise.pivot?.rest || 0
            })) || []
    };

    const initialWorkoutFormValues: WorkoutFormValues = {
        name: data.workout.name,
        description: data.workout.description || ''
    };

    const onDeleteButtonClick = (): void => {
        deleteWorkout({
            variables: { workoutId },
            onCompleted: () => {
                toast.success('Workout deleted successfully!');
                navigate('/workouts');
            },
            onError: () => {
                toast.error('Failed to delete workout!');
            },
            update: cache =>
                deleteRefFromCache(cache, {
                    id: `Workout:${workoutId}`,
                    fieldName: 'workouts',
                    refId: workoutId
                })
        });
    };

    const onExercisesFormSubmit = (
        { exercises }: ExercisesFormValues,
        { setSubmitting }: ExercisesFormHelpers
    ): void => {
        updateWorkout({
            variables: {
                workoutId,
                exercises: exercises.map(exercise => ({
                    exercise_id: exercise.id,
                    min_sets: exercise.minSets,
                    max_sets: exercise.maxSets,
                    min_reps: exercise.minReps,
                    max_reps: exercise.maxReps,
                    rest: exercise.rest
                }))
            },
            onCompleted: () => {
                toast.success('Exercises updated successfully!');
                setSubmitting(false);
            },
            onError: () => {
                toast.error('Failed to update exercises!');
                setSubmitting(false);
            }
        });
    };

    const onWorkoutFormSubmit: WorkoutFormSubmitFunction = (values, helpers): void => {
        updateWorkout({
            variables: { workoutId, name: values.name, description: values.description },
            onCompleted: () => {
                toast.success('Workout updated successfully!');
                helpers.setSubmitting(false);
            },
            onError: () => {
                toast.error('Failed to update workout!');
                helpers.setSubmitting(false);
            }
        });
    };

    const loadOptions = async (_: string): Promise<{ value: string; label: string }[]> => {
        const { data } = await getExercises();

        return (
            data?.exercises?.map(exercise => ({
                value: exercise.id,
                label: exercise.name
            })) || []
        );
    };

    return (
        <UpdateWorkoutPage
            initialExercisesFormValues={initialExercisesFormValues}
            initialWorkoutFormValues={initialWorkoutFormValues}
            loadOptions={loadOptions}
            onDeleteButtonClick={onDeleteButtonClick}
            onExercisesFormSubmit={onExercisesFormSubmit}
            onWorkoutFormSubmit={onWorkoutFormSubmit}
        />
    );
};

export default UpdateWorkout;
