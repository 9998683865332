import moment from 'moment';
import { DATE_RANGES } from 'utilities/constants';

const parseDateRange = (input: string, momentFormat: string = 'YYYY-MM-DD') => {
    let start: string = '';
    let end: string = moment().format(momentFormat);

    switch (input) {
        case DATE_RANGES.LAST_7_DAYS:
            start = moment().subtract(7, 'days').format(momentFormat);
            break;

        case DATE_RANGES.LAST_14_DAYS:
            start = moment().subtract(14, 'days').format(momentFormat);
            break;

        case DATE_RANGES.LAST_30_DAYS:
            start = moment().subtract(30, 'days').format(momentFormat);
            break;

        case DATE_RANGES.LAST_60_DAYS:
            start = moment().subtract(60, 'days').format(momentFormat);
            break;

        case DATE_RANGES.LAST_90_DAYS:
            start = moment().subtract(90, 'days').format(momentFormat);
            break;

        case DATE_RANGES.LAST_6_MONTHS:
            start = moment().subtract(6, 'months').format(momentFormat);
            break;

        case DATE_RANGES.LAST_1_YEAR:
            start = moment().subtract(1, 'year').format(momentFormat);
            break;

        case DATE_RANGES.LAST_2_YEARS:
            start = moment().subtract(2, 'years').format(momentFormat);
            break;

        case DATE_RANGES.LAST_5_YEARS:
            start = moment().subtract(5, 'years').format(momentFormat);
            break;

        case (input.match(/([0-9])+ - ([0-9])+/) || {}).input:
            const splitInput = input.split(' - ');
            start = moment(parseInt(splitInput[0], 10)).local().format(momentFormat);
            end = moment(parseInt(splitInput[1], 10)).local().format(momentFormat);
            break;
    }

    return { start, end };
};

export default parseDateRange;
