import React from 'react';
import { Badge } from 'reactstrap';

export interface StatusBadgeProps {
    status: string;
}

const StatusBadge: React.FC<StatusBadgeProps> = ({ status }) => {
    const badgeColor = status === 'active' ? 'success' : 'danger';

    return <Badge color={badgeColor}>{status}</Badge>;
};

export default StatusBadge;
