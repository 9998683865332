import React from 'react';
import { Button, Field, Form } from 'components/molecules';
import { Col, Row } from 'reactstrap';
import ProgrammeFormSchema from './ProgrammeForm.schema';
import type { ProgrammeFormSubmitFunction, ProgrammeFormValues } from './ProgrammeForm.types';

interface ProgrammeFormProps {
    initialValues: ProgrammeFormValues;
    onSubmit: ProgrammeFormSubmitFunction;
}

const ProgrammeForm: React.FC<ProgrammeFormProps> = ({ initialValues, onSubmit }) => (
    <Form initialValues={initialValues} onSubmit={onSubmit} validationSchema={ProgrammeFormSchema}>
        <Field.Name />
        <Field.Description />
        <Row>
            <Col md="6">
                <Field.StartDate />
            </Col>
            <Col md="6">
                <Field.EndDate />
            </Col>
        </Row>
        <Button.Save />
    </Form>
);

export default ProgrammeForm;
