import React, { useState } from 'react';
import {
    CategorySelector,
    Widget,
    WidgetForm,
    type WidgetFormHelpers,
    type WidgetFormValues
} from 'components/organisms';
import { Col, Row } from 'reactstrap';
import { METRIC } from 'utilities/constants';
import categories from 'utilities/constants/categories';
import { getInputs } from 'utilities/helpers';

export interface WidgetBuilderProps {
    onSubmit: (config: string) => void;
}

const WidgetBuilder: React.FC<WidgetBuilderProps> = ({ onSubmit }) => {
    const [selectedCategory, setSelectedCategory] = useState<string>();

    if (!selectedCategory) {
        return (
            <CategorySelector
                categories={Object.keys(categories)}
                onCategoryClick={category => setSelectedCategory(category)}
            />
        );
    }

    const category = categories[selectedCategory];

    const { initialValues } = getInputs(category);

    const handleSubmit = (values: WidgetFormValues, _: WidgetFormHelpers) => {
        onSubmit(JSON.stringify({ [METRIC]: selectedCategory, ...values }));
    };

    return (
        <Row>
            <Col className="order-2 order-xl-1" xs="12" xl="6">
                <WidgetForm
                    category={category}
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                />
            </Col>
            <Col className="order-1 order-xl-2" xs="12" xl="6">
                <Widget config={{}} />
            </Col>
        </Row>
    );
};

export default WidgetBuilder;
