import React, { ReactNode } from 'react';
import { Droppable as BaseDroppable } from 'react-beautiful-dnd';

export interface Droppablerops {
    children: ReactNode;
    droppableId: string;
}

const Droppable: React.FC<Droppablerops> = ({ children, droppableId }) => (
    <BaseDroppable {...{ droppableId }}>
        {provided => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
                {children}
                {provided.placeholder}
            </div>
        )}
    </BaseDroppable>
);

export default Droppable;
