const getInputs = (category: any) => {
    let initialValues: { [key: string]: any } = {};

    for (const key in category.inputs) {
        initialValues[key] = category.inputs[key].defaultOption;
    }

    return { initialValues };
};

export default getInputs;
