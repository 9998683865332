import React from 'react';
import { UserNutritionPage } from 'components/pages';
import { useGetUserQuery } from 'graphql/generated';
import { useParams } from 'react-router-dom';

interface UserNutritionProps {}

const UserNutrition: React.FC<UserNutritionProps> = () => {
    const { userId } = useParams() as { userId: string };

    const { data, loading, error } = useGetUserQuery({
        variables: { userId, includeNutrition: true }
    });

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error!</div>;
    }

    if (!data) {
        return null;
    }

    const nutrition =
        data.user.nutrition?.map(nutrition => ({
            id: nutrition.id,
            date: nutrition.date,
            protein: nutrition.protein || 0,
            carbohydrates: nutrition.carbohydrates || 0,
            fat: nutrition.fat || 0,
            fiber: nutrition.fiber || 0,
            calories: nutrition.calories || 0
        })) || [];

    return <UserNutritionPage data={{ nutrition }} />;
};

export default UserNutrition;
