import React from 'react';
import { Icon } from 'components/atoms';
import { Button, ButtonProps } from 'reactstrap';

export interface EditButtonProps extends Omit<ButtonProps, 'children'> {}

const EditButton: React.FC<EditButtonProps> = ({ ...props }) => (
    <Button {...props}>
        <Icon.Edit />
    </Button>
);

export default EditButton;
