import DoughnutChart from './DoughnutChart';
import LineChart from './LineChart';
import PieChart from './PieChart';
import VerticalBarChart from './VerticalBarChart';

export * from './DoughnutChart';
export * from './LineChart';
export * from './PieChart';
export * from './VerticalBarChart';

const Chart = {
    Doughnut: DoughnutChart,
    Line: LineChart,
    Pie: PieChart,
    VerticalBar: VerticalBarChart
};

export default Chart;
