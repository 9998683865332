import { ZodSchema } from 'zod';
import createValidationError from '../createValidationError';

const toValidationSchema = <T>(schema: ZodSchema<T>) => {
    return async (values: T) => {
        const result = await schema.safeParseAsync(values);
        if (!result.success) {
            return createValidationError(result.error);
        }
    };
};

export default toValidationSchema;
