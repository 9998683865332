import React from 'react';
import type { DashboardFormSubmitFunction, DashboardFormValues } from 'components/organisms';
import { NoMatchPage, UpdateDashboardPage } from 'components/pages';
import {
    useDeleteDashboardMutation,
    useDeleteWidgetMutation,
    useGetDashboardQuery,
    useUpdateDashboardMutation
} from 'graphql/generated';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { deleteRefFromCache } from 'utilities/helpers';

export interface UpdateDashboardProps {}

const UpdateDashboard: React.FC<UpdateDashboardProps> = () => {
    const navigate = useNavigate();

    const { dashboardId } = useParams() as { dashboardId: string };

    const { data, loading, error } = useGetDashboardQuery({ variables: { dashboardId } });

    const [deleteDashboard] = useDeleteDashboardMutation();

    const [deleteWidgetMutation] = useDeleteWidgetMutation();

    const [updateDashboard] = useUpdateDashboardMutation();

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error</div>;
    }

    if (!data) {
        return <NoMatchPage />;
    }

    const initialDashboardFormValues: DashboardFormValues = {
        name: data.dashboard.name,
        description: data.dashboard.description || ''
    };

    const widgets = data.dashboard.widgets?.map(({ id, name }) => ({ id, name })) || [];

    const onDashboardFormSubmit: DashboardFormSubmitFunction = (values, helpers): void => {
        updateDashboard({
            variables: { dashboardId, name: values.name, description: values.description },
            onCompleted: () => {
                toast.success('Dashboard updated successfully!');
                helpers.setSubmitting(false);
            },
            onError: () => {
                toast.error('Failed to update dashboard!');
                helpers.setSubmitting(false);
            }
        });
    };

    const onDeleteButtonClick = (): void => {
        deleteDashboard({
            variables: { dashboardId },
            onCompleted: () => {
                toast.success('Dashboard deleted successfully!');
                navigate('/dashboards');
            },
            onError: () => {
                toast.error('Failed to delete dashboard!');
            },
            update: cache =>
                deleteRefFromCache(cache, {
                    id: `Dashboard:${dashboardId}`,
                    fieldName: 'dashboards',
                    refId: dashboardId
                })
        });
    };

    const deleteWidget = (widgetId: string): void => {
        deleteWidgetMutation({
            variables: { widgetId },
            onCompleted: () => {
                toast.success('Widget deleted successfully!');
            },
            update: cache =>
                deleteRefFromCache(cache, {
                    id: `Dashboard:${dashboardId}`,
                    fieldName: 'widgets',
                    refId: widgetId
                })
        });
    };

    return (
        <UpdateDashboardPage
            {...{
                initialDashboardFormValues,
                widgets,
                deleteWidget,
                onDashboardFormSubmit,
                onDeleteButtonClick
            }}
        />
    );
};

export default UpdateDashboard;
