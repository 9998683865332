import React from 'react';
import { Button } from 'components/molecules';
import type { ExercisesTableData } from 'components/organisms';
import { ExercisesTable } from 'components/organisms';
import { DefaultTemplate } from 'components/templates';
import { Link } from 'react-router-dom';
import { Card, CardHeader, Container } from 'reactstrap';

export interface ExercisesPageProps {
    exercisesTableData: ExercisesTableData[];
    next: () => void;
}

const ExercisesPage: React.FC<ExercisesPageProps> = ({ exercisesTableData, next }) => (
    <DefaultTemplate
        title="Exercises"
        content={<Button.Create tag={Link} color="primary" to="/exercises/create" />}>
        <Container className="mt-n10">
            <Card className="mb-4">
                <CardHeader tag="h5">Exercises</CardHeader>
                <ExercisesTable
                    className="mb-0"
                    data={exercisesTableData}
                    next={next}
                    borderless
                    responsive
                    striped
                />
            </Card>
        </Container>
    </DefaultTemplate>
);

export default ExercisesPage;
