import React, { useEffect, useState } from 'react';
import { DashboardData, DashboardPage } from 'components/pages';
import { useCreateWidgetMutation, useGetDashboardQuery } from 'graphql/generated';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

export interface DashboardProps {}

const Dashboard: React.FC<DashboardProps> = () => {
    const { dashboardId } = useParams() as { dashboardId: string };

    const [createWidget] = useCreateWidgetMutation();

    const { data, loading, error } = useGetDashboardQuery({ variables: { dashboardId } });

    const [dashboardData, setDashboardData] = useState<DashboardData>();

    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        if (data?.dashboard) {
            setDashboardData({
                id: data.dashboard.id,
                name: data.dashboard.name,
                description: data.dashboard.description || '',
                widgets:
                    data.dashboard.widgets?.map(widget => ({
                        id: widget.id,
                        name: widget.name,
                        config: widget.config
                    })) || []
            });
        }
    }, [data]);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error!</div>;
    }

    if (!dashboardData) {
        return null;
    }

    const onWidgetFormSubmit = (config: string) => {
        createWidget({
            variables: { dashboardId, name: 'Widget', description: '', config },
            onCompleted: () => {
                toast.success('Widget created successfully!');
                setIsOpen(false);
            },
            onError: () => {
                toast.error('Failed to create widget!');
            },
            update: (cache, result) => {
                cache.modify({
                    id: cache.identify(data!.dashboard),
                    fields: {
                        widgets: (existingRefs = []) => [...existingRefs, result.data!.createWidget]
                    }
                });
            }
        });
    };

    const toggle = () => setIsOpen(!isOpen);

    return (
        <DashboardPage
            data={dashboardData}
            isOpen={isOpen}
            onWidgetFormSubmit={onWidgetFormSubmit}
            toggle={toggle}
        />
    );
};

export default Dashboard;
