import React from 'react';
import { Button, Field, Form } from 'components/molecules';
import DashboardFormSchema from './DashboardForm.schema';
import type { DashboardFormSubmitFunction, DashboardFormValues } from './DashboardForm.types';

interface DashboardFormProps {
    initialValues: DashboardFormValues;
    onSubmit: DashboardFormSubmitFunction;
}

const DashboardForm: React.FC<DashboardFormProps> = ({ initialValues, onSubmit }) => (
    <Form initialValues={initialValues} onSubmit={onSubmit} validationSchema={DashboardFormSchema}>
        <Field.Name />
        <Field.Description />
        <Button.Save />
    </Form>
);

export default DashboardForm;
