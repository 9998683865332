import React from 'react';
import { Button } from 'components/molecules';
import { WorkoutsList } from 'components/organisms';
import { DefaultTemplate } from 'components/templates';
import Moment from 'react-moment';
import { Link } from 'react-router-dom';
import {
    Card,
    CardBody,
    CardHeader,
    CardImg,
    CardText,
    Col,
    Container,
    ListGroup,
    ListGroupItem,
    Row
} from 'reactstrap';

export type ProgrammeData = {
    id: string;
    name: string;
    description: string;
    createdAt: string;
    updatedAt: string;
    workouts: {
        id: string;
        name: string;
    }[];
};

export interface ProgrammePageProps {
    data: ProgrammeData;
}

const ProgrammePage: React.FC<ProgrammePageProps> = ({ data }) => (
    <DefaultTemplate
        title={data.name}
        content={<Button.Edit tag={Link} color="primary" to={`/programmes/${data.id}/update`} />}>
        <Container className="mt-n10">
            <Row>
                <Col lg="4" xl="3">
                    <Card className="mb-4">
                        <CardImg src="https://placehold.co/600x400" alt="..." top />
                        {data.description && (
                            <CardBody>
                                <CardText>{data.description}</CardText>
                            </CardBody>
                        )}
                        <ListGroup flush>
                            <ListGroupItem>
                                Created <Moment fromNow>{data.createdAt}</Moment>
                            </ListGroupItem>
                            <ListGroupItem>
                                Updated <Moment fromNow>{data.updatedAt}</Moment>
                            </ListGroupItem>
                        </ListGroup>
                    </Card>
                </Col>
                <Col lg="8" xl="9">
                    <Card className="mb-4">
                        <CardHeader tag="h5">Workouts</CardHeader>
                        <WorkoutsList items={data.workouts} flush />
                    </Card>
                </Col>
            </Row>
        </Container>
    </DefaultTemplate>
);

export default ProgrammePage;
