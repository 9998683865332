import React, { useEffect, useState } from 'react';
import type { DashboardsTableData } from 'components/organisms';
import { DashboardsPage } from 'components/pages';
import { useGetDashboardsQuery } from 'graphql/generated';

export interface DashboardsProps {}

const Dashboards: React.FC<DashboardsProps> = () => {
    const { data, fetchMore } = useGetDashboardsQuery({ variables: { offset: 0, limit: 25 } });

    const [dashboardsTableData, setDashboardsTableData] = useState<DashboardsTableData[]>([]);

    useEffect(() => {
        setDashboardsTableData(
            data?.dashboards?.map(dashboard => ({
                id: dashboard.id,
                name: dashboard.name,
                createdAt: dashboard.created_at,
                updatedAt: dashboard.updated_at || ''
            })) || []
        );
    }, [data]);

    const next = (): void => {
        if (data?.dashboards) fetchMore({ variables: { offset: data.dashboards.length } });
    };

    return <DashboardsPage {...{ dashboardsTableData, next }} />;
};

export default Dashboards;
