import React from 'react';
import { type IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, type ButtonProps } from 'reactstrap';

export interface CategoryButtonProps extends ButtonProps {
    icon?: IconProp;
}

const CategoryButton: React.FC<CategoryButtonProps> = ({
    size = 'xl',
    color = 'primary',
    children,
    icon,
    ...props
}) => (
    <Button color={color} size={size} {...props}>
        <div className="py-2">
            {icon && (
                <span className="d-block mb-3">
                    <FontAwesomeIcon icon={icon} size="2x" fixedWidth />
                </span>
            )}
            {children}
        </div>
    </Button>
);

export default CategoryButton;
