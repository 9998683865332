import { DraggableLocation } from 'react-beautiful-dnd';

function moveDraggableItem<T>(
    source: T[],
    destination: T[],
    droppableSource: DraggableLocation,
    droppableDestination: DraggableLocation
): { [key: string]: T[] } {
    const sourceClone = [...source];
    const destClone = [...destination];
    const [removeDraggableItemd] = sourceClone.splice(droppableSource.index, 1);

    destClone.splice(droppableDestination.index, 0, removeDraggableItemd);

    const result: { [key: string]: T[] } = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;

    return result;
}

export default moveDraggableItem;
