import React from 'react';
import EditButton from 'components/molecules/Button/EditButton';
import { Widget, WidgetBuilder } from 'components/organisms';
import { DefaultTemplate } from 'components/templates';
import { Link } from 'react-router-dom';
import {
    Button,
    ButtonGroup,
    ButtonToolbar,
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    Modal,
    ModalBody,
    ModalHeader,
    Row
} from 'reactstrap';

export type DashboardData = {
    id: string;
    name: string;
    description: string;
    widgets: {
        id: string;
        name: string;
        config: string;
    }[];
};

export interface DashboardPageProps {
    data: DashboardData;
    isOpen: boolean;
    onWidgetFormSubmit: (config: string) => void;
    toggle: () => void;
}

const DashboardPage: React.FC<DashboardPageProps> = ({
    data,
    isOpen,
    onWidgetFormSubmit,
    toggle
}) => (
    <DefaultTemplate
        title={data.name}
        content={
            <ButtonToolbar>
                <ButtonGroup className="me-2">
                    <Button type="button" color="primary" onClick={toggle}>
                        Add Widget
                    </Button>
                </ButtonGroup>
                <ButtonGroup>
                    <EditButton tag={Link} color="primary" to={`/dashboards/${data.id}/update`} />
                </ButtonGroup>
            </ButtonToolbar>
        }>
        <Container className="mt-n10">
            {data.description && (
                <Card className="mb-4">
                    <CardHeader tag="h5">Description</CardHeader>
                    <CardBody>{data.description}</CardBody>
                </Card>
            )}
            <Row className="justify-content-center">
                {data.widgets.map((widget, index) => (
                    <Col key={index} className="mb-4" sm="6" xl="4">
                        <Card className="h-100">
                            <CardHeader tag="h5">{widget.name}</CardHeader>
                            <CardBody>
                                <Widget config={JSON.parse(widget.config)} />
                            </CardBody>
                        </Card>
                    </Col>
                ))}
            </Row>
        </Container>
        <Modal isOpen={isOpen} size="xl" toggle={toggle}>
            <ModalHeader toggle={toggle}>Widget Builder</ModalHeader>
            <ModalBody>
                <WidgetBuilder onSubmit={onWidgetFormSubmit} />
            </ModalBody>
        </Modal>
    </DefaultTemplate>
);

export default DashboardPage;
