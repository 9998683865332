import React from 'react';
import { LeadPage } from 'components/pages';
import { useGetLeadQuery } from 'graphql/generated';
import { useParams } from 'react-router-dom';

export interface LeadProps {}

const Lead: React.FC<LeadProps> = () => {
    const { leadId } = useParams() as { leadId: string };

    const { data, loading, error } = useGetLeadQuery({ variables: { leadId } });

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error!</div>;
    }

    if (!data) {
        return null;
    }

    return (
        <LeadPage
            data={{
                id: data.lead.id,
                email: data.lead.email,
                firstName: data.lead.first_name,
                lastName: data.lead.last_name
            }}
        />
    );
};

export default Lead;
