import React, { useEffect, useState } from 'react';
import { ProgrammeData, ProgrammePage } from 'components/pages';
import { useGetProgrammeQuery } from 'graphql/generated';
import { useParams } from 'react-router-dom';

export interface ProgrammeProps {}

const Programme: React.FC<ProgrammeProps> = () => {
    const { programmeId } = useParams() as { programmeId: string };

    const { data, loading, error } = useGetProgrammeQuery({ variables: { programmeId } });

    const [programmeData, setProgrammeData] = useState<ProgrammeData>();

    useEffect(() => {
        if (data?.programme) {
            setProgrammeData({
                id: data.programme.id,
                name: data.programme.name,
                description: data.programme.description || '',
                createdAt: data.programme.created_at,
                updatedAt: data.programme.updated_at || '',
                workouts:
                    data.programme.workouts?.map(workout => ({
                        id: workout.id,
                        name: workout.name
                    })) || []
            });
        }
    }, [data]);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error!</div>;
    }

    if (!programmeData) {
        return null;
    }

    return <ProgrammePage data={programmeData} />;
};

export default Programme;
