import {
    COLUMN,
    DATE_RANGE,
    EXERCISE_ID,
    FREQUENCY,
    PROGRAMME_ID,
    USER_ID,
    WORKOUT_ID
} from 'utilities/constants';
import parseDateRange from '../../parseDateRange';

function progressiveOverload(url: string, config: { [key: string]: string }): string {
    const queryParams: string[] = [];

    if (config[COLUMN]) {
        queryParams.push(`column=${config[COLUMN]}`);
    }

    if (config[DATE_RANGE]) {
        const { start, end } = parseDateRange(config[DATE_RANGE]);

        queryParams.push(`start=${start}`);
        queryParams.push(`end=${end}`);
    }

    if (config[EXERCISE_ID]) {
        queryParams.push(`exerciseId=${config[EXERCISE_ID]}`);
    }

    if (config[FREQUENCY]) {
        queryParams.push(`frequency=${config[FREQUENCY]}`);
    }

    if (config[PROGRAMME_ID]) {
        queryParams.push(`programmeId=${config[PROGRAMME_ID]}`);
    }

    if (config[USER_ID]) {
        queryParams.push(`userId=${config[USER_ID]}`);
    }

    if (config[WORKOUT_ID]) {
        queryParams.push(`workoutId=${config[WORKOUT_ID]}`);
    }

    return `${url}?${queryParams.join('&')}`.toLowerCase();
}

export default progressiveOverload;
