import React from 'react';
import type { GoalFormSubmitFunction, GoalFormValues } from 'components/organisms';
import { NoMatchPage, UpdateGoalPage } from 'components/pages';
import { useGetGoalQuery, useUpdateGoalMutation } from 'graphql/generated';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

export interface UpdateGoalProps {}

const UpdateGoal: React.FC<UpdateGoalProps> = () => {
    const { goalId } = useParams() as { goalId: string };

    const { data, loading, error } = useGetGoalQuery({ variables: { goalId } });

    const [updateGoalMutation] = useUpdateGoalMutation();

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error</div>;
    }

    if (!data) {
        return <NoMatchPage />;
    }

    const initialGoalFormValues: GoalFormValues = {
        calories: data.goal.calories,
        carbohydrates: data.goal.carbohydrates,
        date: data.goal.date,
        fat: data.goal.fat,
        fiber: data.goal.fiber,
        protein: data.goal.protein
    };

    const onGoalFormSubmit: GoalFormSubmitFunction = (values, helpers): void => {
        updateGoalMutation({
            variables: {
                goalId,
                calories: values.calories,
                carbohydrates: values.carbohydrates,
                date: values.date,
                fat: values.fat,
                fiber: values.fiber,
                protein: values.protein
            },
            onCompleted: () => {
                toast.success('Goal updated successfully!');
                helpers.setSubmitting(false);
            },
            onError: () => {
                toast.error('Failed to update goal!');
                helpers.setSubmitting(false);
            }
        });
    };

    return <UpdateGoalPage {...{ initialGoalFormValues, onGoalFormSubmit }} />;
};

export default UpdateGoal;
