import React from 'react';
import { Button } from 'components/molecules';
import Moment from 'react-moment';
import { Table } from 'reactstrap';
import { z } from 'zod';
import { Link } from 'react-router-dom';

export const GoalsTableDataSchema = z.object({
    id: z.string(),
    calories: z.number(),
    carbohydrates: z.number(),
    date: z.string(),
    fat: z.number(),
    fiber: z.number(),
    protein: z.number(),
    createdAt: z.string(),
    updatedAt: z.string()
});

export type GoalsTableData = z.infer<typeof GoalsTableDataSchema>;

export interface GoalsTableProps {
    data: GoalsTableData[];
}

const GoalsTable: React.FC<GoalsTableProps> = ({ data }) => (
    <Table borderless striped>
        <thead>
            <tr>
                <th scope="col">Date</th>
                <th scope="col">Protein</th>
                <th scope="col">Carbs</th>
                <th scope="col">Fat</th>
                <th scope="col">Fiber</th>
                <th scope="col">Calories</th>
                <th scope="col">Created</th>
                <th scope="col">Updated</th>
                <th scope="col"></th>
            </tr>
        </thead>
        <tbody>
            {data.map((goal, index) => (
                <tr key={index}>
                    <th scope="row">{goal.date}</th>
                    <td>{goal.protein}</td>
                    <td>{goal.carbohydrates}</td>
                    <td>{goal.fat}</td>
                    <td>{goal.fiber}</td>
                    <td>{goal.calories}</td>
                    <td>
                        <Moment fromNow>{goal.createdAt}</Moment>
                    </td>
                    <td>
                        <Moment fromNow>{goal.updatedAt}</Moment>
                    </td>
                    <td>
                        <Button.Edit
                            tag={Link}
                            color="primary"
                            to={`/goals/${goal.id}/update`}
                            size="sm"
                            block
                        />
                    </td>
                </tr>
            ))}
        </tbody>
    </Table>
);

export default GoalsTable;
