import React from 'react';
import { CategoryButton } from 'components/molecules';
import { Col, Row } from 'reactstrap';
import labels from 'utilities/constants/labels';

export interface CategorySelectorProps {
    categories: string[];
    onCategoryClick: (category: string) => void;
}

const CategorySelector: React.FC<CategorySelectorProps> = ({ categories, onCategoryClick }) => (
    <Row className="justify-content-center">
        {categories.map((category, index) => (
            <Col key={index} className="p-3" sm="6" xl="4">
                <CategoryButton type="button" onClick={() => onCategoryClick(category)} block>
                    {labels[category]}
                </CategoryButton>
            </Col>
        ))}
    </Row>
);

export default CategorySelector;
