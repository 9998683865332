import React from 'react';
import { Formik, type FormikProps } from 'formik';
import { Form as BaseForm } from 'reactstrap';
import { toValidationSchema } from 'utilities/helpers';
import { type ZodObject } from 'zod';

export interface FormProps {
    children?: React.ReactNode;
    initialValues: any;
    validationSchema?: ZodObject<any, any>;
    onSubmit: (values: any, helpers: any) => void;
}

const Form: React.FC<FormProps> = ({ children, initialValues, validationSchema, onSubmit }) => (
    <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        {...(validationSchema ? { validate: toValidationSchema(validationSchema) } : {})}>
        {({ handleSubmit }: FormikProps<any>) => (
            <BaseForm onSubmit={handleSubmit}>{children}</BaseForm>
        )}
    </Formik>
);

export default Form;
