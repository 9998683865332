import React from 'react';
import { Button } from 'components/molecules';
import { LeadsBoard, type LeadBoardItem } from 'components/organisms';
import { DefaultTemplate } from 'components/templates';
import { Link } from 'react-router-dom';
import { Container } from 'reactstrap';

interface LeadsPageProps {
    leads: LeadBoardItem[];
    updateStatus: (leadId: string, status: string) => void;
}

const LeadsPage: React.FC<LeadsPageProps> = ({ leads, updateStatus }) => {
    const handleMove = (lead: LeadBoardItem) => {
        updateStatus(lead.id, lead.status);
    };

    return (
        <DefaultTemplate
            title="Leads"
            content={<Button.Create tag={Link} color="primary" to="/leads/create" />}>
            <Container className="mt-n10">
                <LeadsBoard items={leads} onMove={handleMove} />
            </Container>
        </DefaultTemplate>
    );
};

export default LeadsPage;
