import React from 'react';
import { LeadsPage } from 'components/pages';
import { useGetLeadsQuery, useUpdateLeadMutation } from 'graphql/generated';

export interface LeadsProps {}

const Leads: React.FC<LeadsProps> = () => {
    const { data, loading, error } = useGetLeadsQuery();

    const [updateLead] = useUpdateLeadMutation();

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error!</div>;
    }

    if (!data) {
        return null;
    }

    const leads =
        data.leads?.map(lead => ({
            id: lead.id,
            name: lead.first_name + ' ' + lead.last_name,
            email: lead.email,
            status: lead.status
        })) || [];

    const updateStatus = (leadId: string, status: string) => {
        updateLead({ variables: { leadId, status } });
    };

    return <LeadsPage leads={leads} updateStatus={updateStatus} />;
};

export default Leads;
