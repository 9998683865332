import React from 'react';
import { Button } from 'components/molecules';
import { DefaultTemplate } from 'components/templates';
import { Link } from 'react-router-dom';
import { Card, CardBody, CardText, CardTitle, Col, Container, Row } from 'reactstrap';

export interface LeadPageProp {
    data: {
        id: string;
        email: string;
        firstName: string;
        lastName: string;
    };
}

const LeadPage: React.FC<LeadPageProp> = ({ data }) => (
    <DefaultTemplate
        title="Lead"
        content={<Button.Edit tag={Link} color="primary" to={`/leads/${data.id}/update`} />}>
        <Container className="mt-n10">
            <Row>
                <Col lg="4" xl="3">
                    <Card className="mb-4">
                        <CardBody>
                            <CardTitle className="fw-bold">
                                {data.firstName + ' ' + data.lastName}
                            </CardTitle>
                            <CardText>{data.email}</CardText>
                        </CardBody>
                    </Card>
                </Col>
                <Col lg="8" xl="9"></Col>
            </Row>
        </Container>
    </DefaultTemplate>
);

export default LeadPage;
