import React from 'react';
import { UserProgrammesPage } from 'components/pages';
import { useCreateProgrammeMutation, useGetUserQuery } from 'graphql/generated';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

export interface UserProgrammesProps {}

const UserProgrammes: React.FC<UserProgrammesProps> = () => {
    const navigate = useNavigate();

    const { userId } = useParams() as { userId: string };

    const [createProgramme] = useCreateProgrammeMutation();

    const { data, loading, error } = useGetUserQuery({
        variables: { userId, includeProgrammes: true }
    });

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error!</div>;
    }

    if (!data) {
        return null;
    }

    const programmes =
        data.user.programmes?.map(programme => ({
            id: programme.id,
            name: programme.name,
            startDate: programme.start_date,
            endDate: programme.end_date,
            status: programme.status,
            createdAt: programme.created_at,
            updatedAt: programme.updated_at || ''
        })) || [];

    const handleCreateProgramme = (
        values: {
            name: string;
            description: string;
            startDate: string;
            endDate: string;
        },
        onSuccess: () => void
    ): void => {
        createProgramme({
            variables: { userId, ...values },
            onCompleted: data => {
                toast.success('Programme created successfully!');
                onSuccess();
                navigate(`/programmes/${data.createProgramme.id}`);
            },
            onError: () => {
                toast.error('Failed to create programme!');
            },
            update: (cache, result) => {
                cache.modify({
                    id: cache.identify(data!.user),
                    fields: {
                        programmes: (existingRefs = []) => [
                            ...existingRefs,
                            result.data!.createProgramme
                        ]
                    }
                });
            }
        });
    };

    return <UserProgrammesPage {...{ programmes, handleCreateProgramme }} />;
};

export default UserProgrammes;
