import React from 'react';
import { useField } from 'formik';
import { FormGroup, Label, Input, FormFeedback } from 'reactstrap';

export interface DescriptionFieldProps {}

const DescriptionField: React.FC<DescriptionFieldProps> = () => {
    const [field, meta] = useField('description');

    return (
        <FormGroup>
            <Label for="description">Description</Label>
            <Input
                type="textarea"
                id="description"
                invalid={!!meta.error && meta.touched}
                {...field}
            />
            <FormFeedback>{meta.error}</FormFeedback>
        </FormGroup>
    );
};

export default DescriptionField;
