import React from 'react';
import { useField } from 'formik';
import { FormFeedback, FormGroup, Input, InputGroup, InputGroupText, Label } from 'reactstrap';

export interface CarbohydratesFieldProps {}

const CarbohydratesField: React.FC<CarbohydratesFieldProps> = () => {
    const [field, meta] = useField('carbohydrates');

    return (
        <FormGroup>
            <Label for="carbohydrates">Carbohydrates</Label>
            <InputGroup>
                <Input
                    type="number"
                    id="carbohydrates"
                    invalid={!!meta.error && meta.touched}
                    {...field}
                />
                <InputGroupText>g</InputGroupText>
                <FormFeedback>{meta.error}</FormFeedback>
            </InputGroup>
        </FormGroup>
    );
};

export default CarbohydratesField;
