import React from 'react';
import type { UserFormSubmitFunction, UserFormValues } from 'components/organisms';
import { CreateUserPage } from 'components/pages';
import { ActivityLevel, Gender, useCreateUserMutation } from 'graphql/generated';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

export interface CreateUserProps {}

const CreateUser: React.FC<CreateUserProps> = () => {
    const navigate = useNavigate();

    const [createUser] = useCreateUserMutation();

    const initialUserFormValues: UserFormValues = {
        activityLevel: ActivityLevel.Sedentary,
        dateOfBirth: '',
        email: '',
        firstName: '',
        gender: Gender.Male,
        lastName: ''
    };

    const onUserFormSubmit: UserFormSubmitFunction = (values, helpers): void => {
        createUser({
            variables: {
                activityLevel: values.activityLevel,
                dateOfBirth: values.dateOfBirth,
                email: values.email,
                firstName: values.firstName,
                gender: values.gender,
                lastName: values.lastName
            },
            onCompleted: data => {
                toast.success('User created successfully!');
                navigate(`/users/${data.createUser.id}`);
            },
            onError: () => {
                toast.error('Failed to create user!');
                helpers.setSubmitting(false);
            },
            update: (cache, result) => {
                cache.modify({
                    fields: {
                        users: (existingRefs = []) => [...existingRefs, result.data!.createUser]
                    }
                });
            }
        });
    };

    return <CreateUserPage {...{ initialUserFormValues, onUserFormSubmit }} />;
};

export default CreateUser;
