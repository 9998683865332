import React from 'react';
import { Icon } from 'components/atoms';
import { Button, type ButtonProps } from 'reactstrap';

export interface RemoveButtonProps extends Omit<ButtonProps, 'children' | 'color'> {}

const RemoveButton: React.FC<RemoveButtonProps> = ({ ...props }) => (
    <Button color="danger" {...props}>
        <Icon.Remove />
    </Button>
);

export default RemoveButton;
