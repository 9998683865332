import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = {
    [_ in K]?: never;
};
export type Incremental<T> =
    | T
    | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: { input: string; output: string };
    String: { input: string; output: string };
    Boolean: { input: boolean; output: boolean };
    Int: { input: number; output: number };
    Float: { input: number; output: number };
    JSON: { input: any; output: any };
    JSONObject: { input: any; output: any };
};

export type Activity = {
    __typename?: 'Activity';
    created_at: Scalars['String']['output'];
    id: Scalars['ID']['output'];
    subject: Subject;
    updated_at?: Maybe<Scalars['String']['output']>;
    user: User;
};

export enum ActivityLevel {
    Light = 'light',
    Moderate = 'moderate',
    Sedentary = 'sedentary',
    Very = 'very'
}

export type CreateDashboardInput = {
    description?: InputMaybe<Scalars['String']['input']>;
    name: Scalars['String']['input'];
};

export type CreateExerciseInput = {
    description?: InputMaybe<Scalars['String']['input']>;
    name: Scalars['String']['input'];
};

export type CreateGoalInput = {
    calories: Scalars['Int']['input'];
    carbohydrates: Scalars['Int']['input'];
    date: Scalars['String']['input'];
    fat: Scalars['Int']['input'];
    fiber: Scalars['Int']['input'];
    protein: Scalars['Int']['input'];
    user_id: Scalars['ID']['input'];
};

export type CreateLeadInput = {
    activity_level: ActivityLevel;
    bmr: Scalars['Int']['input'];
    date_of_birth: Scalars['String']['input'];
    email: Scalars['String']['input'];
    first_name: Scalars['String']['input'];
    gender: Gender;
    goal: Scalars['String']['input'];
    height_cm: Scalars['Float']['input'];
    last_name: Scalars['String']['input'];
    status: Scalars['String']['input'];
    tdee: Scalars['Int']['input'];
    weight_kg: Scalars['Float']['input'];
};

export type CreateMeasurementInput = {
    arms_cm?: InputMaybe<Scalars['Float']['input']>;
    chest_cm?: InputMaybe<Scalars['Float']['input']>;
    date: Scalars['String']['input'];
    height_cm?: InputMaybe<Scalars['Float']['input']>;
    hips_cm?: InputMaybe<Scalars['Float']['input']>;
    neck_cm?: InputMaybe<Scalars['Float']['input']>;
    thighs_cm?: InputMaybe<Scalars['Float']['input']>;
    user_id: Scalars['ID']['input'];
    waist_cm?: InputMaybe<Scalars['Float']['input']>;
    weight_kg?: InputMaybe<Scalars['Float']['input']>;
};

export type CreateNutritionInput = {
    calories: Scalars['Int']['input'];
    carbohydrates: Scalars['Int']['input'];
    date: Scalars['String']['input'];
    fat: Scalars['Int']['input'];
    fiber: Scalars['Int']['input'];
    protein: Scalars['Int']['input'];
    user_id: Scalars['ID']['input'];
};

export type CreateProgrammeInput = {
    description?: InputMaybe<Scalars['String']['input']>;
    end_date: Scalars['String']['input'];
    name: Scalars['String']['input'];
    start_date: Scalars['String']['input'];
    user_id: Scalars['ID']['input'];
};

export type CreateSetInput = {
    date: Scalars['String']['input'];
    exercise_id: Scalars['ID']['input'];
    programme_id?: InputMaybe<Scalars['ID']['input']>;
    reps: Scalars['Int']['input'];
    user_id: Scalars['ID']['input'];
    weight: Scalars['Float']['input'];
    workout_id?: InputMaybe<Scalars['ID']['input']>;
};

export type CreateSurveyInput = {
    description?: InputMaybe<Scalars['String']['input']>;
    name: Scalars['String']['input'];
};

export type CreateUserInput = {
    activity_level: ActivityLevel;
    date_of_birth: Scalars['String']['input'];
    email: Scalars['String']['input'];
    first_name: Scalars['String']['input'];
    gender: Gender;
    last_name: Scalars['String']['input'];
};

export type CreateWidgetInput = {
    config: Scalars['String']['input'];
    dashboard_id: Scalars['ID']['input'];
    description?: InputMaybe<Scalars['String']['input']>;
    name: Scalars['String']['input'];
};

export type CreateWorkoutInput = {
    description?: InputMaybe<Scalars['String']['input']>;
    exercises?: InputMaybe<Array<WorkoutExerciseInput>>;
    name: Scalars['String']['input'];
};

export type Dashboard = {
    __typename?: 'Dashboard';
    created_at: Scalars['String']['output'];
    description?: Maybe<Scalars['String']['output']>;
    id: Scalars['ID']['output'];
    name: Scalars['String']['output'];
    updated_at?: Maybe<Scalars['String']['output']>;
    user: User;
    widgets?: Maybe<Array<Widget>>;
};

export type Exercise = {
    __typename?: 'Exercise';
    created_at: Scalars['String']['output'];
    description?: Maybe<Scalars['String']['output']>;
    id: Scalars['ID']['output'];
    name: Scalars['String']['output'];
    pivot?: Maybe<WorkoutExercisePivot>;
    sets?: Maybe<Array<Set>>;
    updated_at?: Maybe<Scalars['String']['output']>;
    workouts?: Maybe<Array<Workout>>;
};

export type ExerciseSetsArgs = {
    date?: InputMaybe<Scalars['String']['input']>;
    programmeId?: InputMaybe<Scalars['ID']['input']>;
    userId?: InputMaybe<Scalars['ID']['input']>;
    workoutId?: InputMaybe<Scalars['ID']['input']>;
};

export enum Gender {
    Female = 'female',
    Male = 'male'
}

export type Goal = {
    __typename?: 'Goal';
    calories: Scalars['Int']['output'];
    carbohydrates: Scalars['Int']['output'];
    created_at: Scalars['String']['output'];
    date: Scalars['String']['output'];
    fat: Scalars['Int']['output'];
    fiber: Scalars['Int']['output'];
    id: Scalars['ID']['output'];
    protein: Scalars['Int']['output'];
    updated_at?: Maybe<Scalars['String']['output']>;
    user: User;
};

export type Lead = {
    __typename?: 'Lead';
    activity_level: ActivityLevel;
    bmr: Scalars['Int']['output'];
    created_at: Scalars['String']['output'];
    date_of_birth: Scalars['String']['output'];
    email: Scalars['String']['output'];
    first_name: Scalars['String']['output'];
    gender: Gender;
    goal: Scalars['String']['output'];
    height_cm: Scalars['Float']['output'];
    id: Scalars['ID']['output'];
    last_name: Scalars['String']['output'];
    status: Scalars['String']['output'];
    tdee: Scalars['Int']['output'];
    updated_at?: Maybe<Scalars['String']['output']>;
    weight_kg: Scalars['Float']['output'];
};

export type LoginInput = {
    email: Scalars['String']['input'];
    password: Scalars['String']['input'];
};

export type Measurement = {
    __typename?: 'Measurement';
    arms_cm?: Maybe<Scalars['Float']['output']>;
    chest_cm?: Maybe<Scalars['Float']['output']>;
    created_at: Scalars['String']['output'];
    date: Scalars['String']['output'];
    height_cm?: Maybe<Scalars['Float']['output']>;
    hips_cm?: Maybe<Scalars['Float']['output']>;
    id: Scalars['ID']['output'];
    neck_cm?: Maybe<Scalars['Float']['output']>;
    thighs_cm?: Maybe<Scalars['Float']['output']>;
    updated_at?: Maybe<Scalars['String']['output']>;
    user: User;
    waist_cm?: Maybe<Scalars['Float']['output']>;
    weight_kg?: Maybe<Scalars['Float']['output']>;
};

export type Mutation = {
    __typename?: 'Mutation';
    _empty?: Maybe<Scalars['String']['output']>;
    changePassword: User;
    createDashboard: Dashboard;
    createExercise: Exercise;
    createGoal: Goal;
    createLead: Lead;
    createMeasurement: Measurement;
    createNutrition: Nutrition;
    createProgramme: Programme;
    createSet: Set;
    createSurvey: Survey;
    createUser: User;
    createWidget: Widget;
    createWorkout: Workout;
    deleteDashboard: Scalars['ID']['output'];
    deleteExercise: Scalars['ID']['output'];
    deleteGoal: Scalars['ID']['output'];
    deleteLead: Scalars['ID']['output'];
    deleteMeasurement: Scalars['ID']['output'];
    deleteNutrition: Scalars['ID']['output'];
    deleteProgramme: Scalars['ID']['output'];
    deleteSet: Scalars['ID']['output'];
    deleteSurvey: Scalars['ID']['output'];
    deleteUser: Scalars['ID']['output'];
    deleteWidget: Scalars['ID']['output'];
    deleteWorkout: Scalars['ID']['output'];
    likeDashboard: Scalars['ID']['output'];
    likeExercise: Scalars['ID']['output'];
    likeWorkout: Scalars['ID']['output'];
    login: Token;
    updateDashboard: Dashboard;
    updateExercise: Exercise;
    updateGoal: Goal;
    updateLead: Lead;
    updateMeasurement: Measurement;
    updateNutrition: Nutrition;
    updateProgramme: Programme;
    updateSet: Set;
    updateSurvey: Survey;
    updateUser: User;
    updateWidget: Widget;
    updateWorkout: Workout;
};

export type MutationChangePasswordArgs = {
    currentPassword: Scalars['String']['input'];
    newPassword: Scalars['String']['input'];
};

export type MutationCreateDashboardArgs = {
    input?: InputMaybe<CreateDashboardInput>;
};

export type MutationCreateExerciseArgs = {
    input?: InputMaybe<CreateExerciseInput>;
};

export type MutationCreateGoalArgs = {
    input?: InputMaybe<CreateGoalInput>;
};

export type MutationCreateLeadArgs = {
    input?: InputMaybe<CreateLeadInput>;
};

export type MutationCreateMeasurementArgs = {
    input?: InputMaybe<CreateMeasurementInput>;
};

export type MutationCreateNutritionArgs = {
    input?: InputMaybe<CreateNutritionInput>;
};

export type MutationCreateProgrammeArgs = {
    input?: InputMaybe<CreateProgrammeInput>;
};

export type MutationCreateSetArgs = {
    input: CreateSetInput;
};

export type MutationCreateSurveyArgs = {
    input: CreateSurveyInput;
};

export type MutationCreateUserArgs = {
    input: CreateUserInput;
};

export type MutationCreateWidgetArgs = {
    input?: InputMaybe<CreateWidgetInput>;
};

export type MutationCreateWorkoutArgs = {
    input?: InputMaybe<CreateWorkoutInput>;
};

export type MutationDeleteDashboardArgs = {
    dashboardId: Scalars['ID']['input'];
};

export type MutationDeleteExerciseArgs = {
    exerciseId: Scalars['ID']['input'];
};

export type MutationDeleteGoalArgs = {
    goalId: Scalars['ID']['input'];
};

export type MutationDeleteLeadArgs = {
    leadId: Scalars['ID']['input'];
};

export type MutationDeleteMeasurementArgs = {
    measurementId: Scalars['ID']['input'];
};

export type MutationDeleteNutritionArgs = {
    nutritionId: Scalars['ID']['input'];
};

export type MutationDeleteProgrammeArgs = {
    programmeId: Scalars['ID']['input'];
};

export type MutationDeleteSetArgs = {
    setId: Scalars['ID']['input'];
};

export type MutationDeleteSurveyArgs = {
    surveyId: Scalars['ID']['input'];
};

export type MutationDeleteUserArgs = {
    userId: Scalars['ID']['input'];
};

export type MutationDeleteWidgetArgs = {
    widgetId: Scalars['ID']['input'];
};

export type MutationDeleteWorkoutArgs = {
    workoutId: Scalars['ID']['input'];
};

export type MutationLikeDashboardArgs = {
    dashboardId: Scalars['ID']['input'];
};

export type MutationLikeExerciseArgs = {
    exerciseId: Scalars['ID']['input'];
};

export type MutationLikeWorkoutArgs = {
    workoutId: Scalars['ID']['input'];
};

export type MutationLoginArgs = {
    input?: InputMaybe<LoginInput>;
};

export type MutationUpdateDashboardArgs = {
    dashboardId: Scalars['ID']['input'];
    input?: InputMaybe<UpdateDashboardInput>;
};

export type MutationUpdateExerciseArgs = {
    exerciseId: Scalars['ID']['input'];
    input?: InputMaybe<UpdateExerciseInput>;
};

export type MutationUpdateGoalArgs = {
    goalId: Scalars['ID']['input'];
    input?: InputMaybe<UpdateGoalInput>;
};

export type MutationUpdateLeadArgs = {
    input?: InputMaybe<UpdateLeadInput>;
    leadId: Scalars['ID']['input'];
};

export type MutationUpdateMeasurementArgs = {
    input?: InputMaybe<UpdateMeasurementInput>;
    measurementId: Scalars['ID']['input'];
};

export type MutationUpdateNutritionArgs = {
    input?: InputMaybe<UpdateNutritionInput>;
    nutritionId: Scalars['ID']['input'];
};

export type MutationUpdateProgrammeArgs = {
    input?: InputMaybe<UpdateProgrammeInput>;
    programmeId: Scalars['ID']['input'];
};

export type MutationUpdateSetArgs = {
    input: UpdateSetInput;
    setId: Scalars['ID']['input'];
};

export type MutationUpdateSurveyArgs = {
    input: UpdateSurveyInput;
    surveyId: Scalars['ID']['input'];
};

export type MutationUpdateUserArgs = {
    input: UpdateUserInput;
    userId: Scalars['ID']['input'];
};

export type MutationUpdateWidgetArgs = {
    input?: InputMaybe<UpdateWidgetInput>;
    widgetId: Scalars['ID']['input'];
};

export type MutationUpdateWorkoutArgs = {
    input?: InputMaybe<UpdateWorkoutInput>;
    workoutId: Scalars['ID']['input'];
};

export type Nutrition = {
    __typename?: 'Nutrition';
    calories?: Maybe<Scalars['Int']['output']>;
    carbohydrates?: Maybe<Scalars['Int']['output']>;
    created_at: Scalars['String']['output'];
    date: Scalars['String']['output'];
    fat?: Maybe<Scalars['Int']['output']>;
    fiber?: Maybe<Scalars['Int']['output']>;
    id: Scalars['ID']['output'];
    protein?: Maybe<Scalars['Int']['output']>;
    updated_at?: Maybe<Scalars['String']['output']>;
    user: User;
};

export type Permission = {
    __typename?: 'Permission';
    created_at: Scalars['String']['output'];
    description?: Maybe<Scalars['String']['output']>;
    id: Scalars['ID']['output'];
    name: Scalars['String']['output'];
    updated_at?: Maybe<Scalars['String']['output']>;
};

export type Programme = {
    __typename?: 'Programme';
    created_at: Scalars['String']['output'];
    description?: Maybe<Scalars['String']['output']>;
    end_date: Scalars['String']['output'];
    id: Scalars['ID']['output'];
    name: Scalars['String']['output'];
    start_date: Scalars['String']['output'];
    status: Scalars['String']['output'];
    updated_at?: Maybe<Scalars['String']['output']>;
    user: User;
    workout?: Maybe<Workout>;
    workouts?: Maybe<Array<Workout>>;
};

export type ProgrammeWorkoutArgs = {
    workoutId: Scalars['ID']['input'];
};

export type ProgrammeWorkoutInput = {
    workout_id: Scalars['ID']['input'];
};

export type Query = {
    __typename?: 'Query';
    _empty?: Maybe<Scalars['String']['output']>;
    activities?: Maybe<Array<Activity>>;
    bmr: Scalars['Int']['output'];
    dashboard: Dashboard;
    dashboards?: Maybe<Array<Dashboard>>;
    exercise: Exercise;
    exercises?: Maybe<Array<Exercise>>;
    goal: Goal;
    goals?: Maybe<Array<Goal>>;
    lead: Lead;
    leads?: Maybe<Array<Lead>>;
    me: User;
    measurement: Measurement;
    measurements?: Maybe<Array<Measurement>>;
    nutrition: Nutrition;
    nutritions?: Maybe<Array<Nutrition>>;
    programme: Programme;
    programmes?: Maybe<Array<Programme>>;
    set: Set;
    sets?: Maybe<Array<Set>>;
    survey: Survey;
    surveys?: Maybe<Array<Survey>>;
    tdee: Scalars['Int']['output'];
    user: User;
    users?: Maybe<Array<User>>;
    widget: Widget;
    widgets?: Maybe<Array<Widget>>;
    workout: Workout;
    workouts?: Maybe<Array<Workout>>;
};

export type QueryActivitiesArgs = {
    limit?: InputMaybe<Scalars['Int']['input']>;
    offset?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryBmrArgs = {
    age: Scalars['Int']['input'];
    gender: Gender;
    height_cm: Scalars['Float']['input'];
    weight_kg: Scalars['Float']['input'];
};

export type QueryDashboardArgs = {
    dashboardId: Scalars['ID']['input'];
};

export type QueryDashboardsArgs = {
    limit?: InputMaybe<Scalars['Int']['input']>;
    name?: InputMaybe<Scalars['String']['input']>;
    offset?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryExerciseArgs = {
    exerciseId: Scalars['ID']['input'];
};

export type QueryExercisesArgs = {
    limit?: InputMaybe<Scalars['Int']['input']>;
    name?: InputMaybe<Scalars['String']['input']>;
    offset?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryGoalArgs = {
    goalId: Scalars['ID']['input'];
};

export type QueryGoalsArgs = {
    limit?: InputMaybe<Scalars['Int']['input']>;
    offset?: InputMaybe<Scalars['Int']['input']>;
    user?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryLeadArgs = {
    leadId: Scalars['ID']['input'];
};

export type QueryLeadsArgs = {
    limit?: InputMaybe<Scalars['Int']['input']>;
    offset?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryMeasurementArgs = {
    measurementId: Scalars['ID']['input'];
};

export type QueryNutritionArgs = {
    nutritionId: Scalars['ID']['input'];
};

export type QueryProgrammeArgs = {
    programmeId: Scalars['ID']['input'];
};

export type QueryProgrammesArgs = {
    limit?: InputMaybe<Scalars['Int']['input']>;
    name?: InputMaybe<Scalars['String']['input']>;
    offset?: InputMaybe<Scalars['Int']['input']>;
};

export type QuerySetArgs = {
    setId: Scalars['ID']['input'];
};

export type QuerySetsArgs = {
    date?: InputMaybe<Scalars['String']['input']>;
    exerciseId?: InputMaybe<Scalars['ID']['input']>;
    programmeId?: InputMaybe<Scalars['ID']['input']>;
    userId?: InputMaybe<Scalars['ID']['input']>;
    workoutId?: InputMaybe<Scalars['ID']['input']>;
};

export type QuerySurveyArgs = {
    surveyId: Scalars['ID']['input'];
};

export type QuerySurveysArgs = {
    limit?: InputMaybe<Scalars['Int']['input']>;
    offset?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryTdeeArgs = {
    activity_level: ActivityLevel;
    calories: Scalars['Int']['input'];
};

export type QueryUserArgs = {
    userId: Scalars['ID']['input'];
};

export type QueryUsersArgs = {
    limit?: InputMaybe<Scalars['Int']['input']>;
    offset?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryWidgetArgs = {
    widgetId: Scalars['ID']['input'];
};

export type QueryWorkoutArgs = {
    workoutId: Scalars['ID']['input'];
};

export type QueryWorkoutsArgs = {
    limit?: InputMaybe<Scalars['Int']['input']>;
    name?: InputMaybe<Scalars['String']['input']>;
    offset?: InputMaybe<Scalars['Int']['input']>;
};

export type Question = {
    __typename?: 'Question';
    created_at: Scalars['String']['output'];
    id: Scalars['ID']['output'];
    question: Scalars['String']['output'];
    updated_at?: Maybe<Scalars['String']['output']>;
};

export type Role = {
    __typename?: 'Role';
    created_at: Scalars['String']['output'];
    description?: Maybe<Scalars['String']['output']>;
    id: Scalars['ID']['output'];
    name: Scalars['String']['output'];
    updated_at?: Maybe<Scalars['String']['output']>;
};

export type Set = {
    __typename?: 'Set';
    created_at: Scalars['String']['output'];
    date: Scalars['String']['output'];
    exercise: Exercise;
    id: Scalars['ID']['output'];
    programme?: Maybe<Programme>;
    reps?: Maybe<Scalars['Int']['output']>;
    updated_at?: Maybe<Scalars['String']['output']>;
    user: User;
    weight?: Maybe<Scalars['Float']['output']>;
    workout?: Maybe<Workout>;
};

export type Subject = Set;

export type Survey = {
    __typename?: 'Survey';
    created_at: Scalars['String']['output'];
    description?: Maybe<Scalars['String']['output']>;
    id: Scalars['ID']['output'];
    name: Scalars['String']['output'];
    questions?: Maybe<Array<Question>>;
    updated_at?: Maybe<Scalars['String']['output']>;
};

export type Token = {
    __typename?: 'Token';
    token: Scalars['String']['output'];
};

export type UpdateDashboardInput = {
    description?: InputMaybe<Scalars['String']['input']>;
    name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateExerciseInput = {
    description?: InputMaybe<Scalars['String']['input']>;
    name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateGoalInput = {
    calories?: InputMaybe<Scalars['Int']['input']>;
    carbohydrates?: InputMaybe<Scalars['Int']['input']>;
    date?: InputMaybe<Scalars['String']['input']>;
    fat?: InputMaybe<Scalars['Int']['input']>;
    fiber?: InputMaybe<Scalars['Int']['input']>;
    protein?: InputMaybe<Scalars['Int']['input']>;
    user_id?: InputMaybe<Scalars['ID']['input']>;
};

export type UpdateLeadInput = {
    activity_level?: InputMaybe<ActivityLevel>;
    bmr?: InputMaybe<Scalars['Int']['input']>;
    date_of_birth?: InputMaybe<Scalars['String']['input']>;
    email?: InputMaybe<Scalars['String']['input']>;
    first_name?: InputMaybe<Scalars['String']['input']>;
    gender?: InputMaybe<Gender>;
    goal?: InputMaybe<Scalars['String']['input']>;
    height_cm?: InputMaybe<Scalars['Float']['input']>;
    last_name?: InputMaybe<Scalars['String']['input']>;
    status?: InputMaybe<Scalars['String']['input']>;
    tdee?: InputMaybe<Scalars['Int']['input']>;
    weight_kg?: InputMaybe<Scalars['Float']['input']>;
};

export type UpdateMeasurementInput = {
    arms_cm?: InputMaybe<Scalars['Float']['input']>;
    chest_cm?: InputMaybe<Scalars['Float']['input']>;
    date?: InputMaybe<Scalars['String']['input']>;
    height_cm?: InputMaybe<Scalars['Float']['input']>;
    hips_cm?: InputMaybe<Scalars['Float']['input']>;
    neck_cm?: InputMaybe<Scalars['Float']['input']>;
    thighs_cm?: InputMaybe<Scalars['Float']['input']>;
    waist_cm?: InputMaybe<Scalars['Float']['input']>;
    weight_kg?: InputMaybe<Scalars['Float']['input']>;
};

export type UpdateNutritionInput = {
    calories?: InputMaybe<Scalars['Int']['input']>;
    carbohydrates?: InputMaybe<Scalars['Int']['input']>;
    date?: InputMaybe<Scalars['String']['input']>;
    fat?: InputMaybe<Scalars['Int']['input']>;
    fiber?: InputMaybe<Scalars['Int']['input']>;
    protein?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateProgrammeInput = {
    description?: InputMaybe<Scalars['String']['input']>;
    end_date?: InputMaybe<Scalars['String']['input']>;
    name?: InputMaybe<Scalars['String']['input']>;
    start_date?: InputMaybe<Scalars['String']['input']>;
    user_id?: InputMaybe<Scalars['ID']['input']>;
    workouts?: InputMaybe<Array<ProgrammeWorkoutInput>>;
};

export type UpdateSetInput = {
    date?: InputMaybe<Scalars['String']['input']>;
    reps?: InputMaybe<Scalars['Int']['input']>;
    weight?: InputMaybe<Scalars['Float']['input']>;
};

export type UpdateSurveyInput = {
    description?: InputMaybe<Scalars['String']['input']>;
    name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateUserInput = {
    activity_level?: InputMaybe<ActivityLevel>;
    date_of_birth?: InputMaybe<Scalars['String']['input']>;
    email?: InputMaybe<Scalars['String']['input']>;
    first_name?: InputMaybe<Scalars['String']['input']>;
    gender?: InputMaybe<Gender>;
    last_name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateWidgetInput = {
    config?: InputMaybe<Scalars['String']['input']>;
    description?: InputMaybe<Scalars['String']['input']>;
    name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateWorkoutInput = {
    description?: InputMaybe<Scalars['String']['input']>;
    exercises?: InputMaybe<Array<WorkoutExerciseInput>>;
    name?: InputMaybe<Scalars['String']['input']>;
};

export type User = {
    __typename?: 'User';
    activities?: Maybe<Array<Activity>>;
    activity_level: ActivityLevel;
    avatar: Scalars['String']['output'];
    created_at: Scalars['String']['output'];
    date_of_birth: Scalars['String']['output'];
    email: Scalars['String']['output'];
    first_name: Scalars['String']['output'];
    gender: Gender;
    goal?: Maybe<Goal>;
    goals?: Maybe<Array<Goal>>;
    id: Scalars['ID']['output'];
    last_name: Scalars['String']['output'];
    measurements?: Maybe<Array<Measurement>>;
    nutrition?: Maybe<Array<Nutrition>>;
    permissions?: Maybe<Array<Permission>>;
    programme: Programme;
    programmes?: Maybe<Array<Programme>>;
    roles?: Maybe<Array<Role>>;
    updated_at?: Maybe<Scalars['String']['output']>;
};

export type UserActivitiesArgs = {
    limit?: InputMaybe<Scalars['Int']['input']>;
    offset?: InputMaybe<Scalars['Int']['input']>;
};

export type UserGoalsArgs = {
    limit?: InputMaybe<Scalars['Int']['input']>;
    offset?: InputMaybe<Scalars['Int']['input']>;
};

export type UserMeasurementsArgs = {
    date?: InputMaybe<Scalars['String']['input']>;
    limit?: InputMaybe<Scalars['Int']['input']>;
    offset?: InputMaybe<Scalars['Int']['input']>;
};

export type UserNutritionArgs = {
    date?: InputMaybe<Scalars['String']['input']>;
    limit?: InputMaybe<Scalars['Int']['input']>;
    offset?: InputMaybe<Scalars['Int']['input']>;
};

export type UserProgrammeArgs = {
    programmeId: Scalars['ID']['input'];
};

export type UserProgrammesArgs = {
    limit?: InputMaybe<Scalars['Int']['input']>;
    offset?: InputMaybe<Scalars['Int']['input']>;
};

export type Widget = {
    __typename?: 'Widget';
    config: Scalars['String']['output'];
    created_at: Scalars['String']['output'];
    dashboard: Dashboard;
    description?: Maybe<Scalars['String']['output']>;
    id: Scalars['ID']['output'];
    name: Scalars['String']['output'];
    updated_at?: Maybe<Scalars['String']['output']>;
};

export type Workout = {
    __typename?: 'Workout';
    created_at: Scalars['String']['output'];
    description?: Maybe<Scalars['String']['output']>;
    exercises?: Maybe<Array<Exercise>>;
    id: Scalars['ID']['output'];
    name: Scalars['String']['output'];
    pivot?: Maybe<WorkoutExercisePivot>;
    updated_at?: Maybe<Scalars['String']['output']>;
};

export type WorkoutExerciseInput = {
    exercise_id: Scalars['ID']['input'];
    max_reps?: InputMaybe<Scalars['Int']['input']>;
    max_sets?: InputMaybe<Scalars['Int']['input']>;
    min_reps?: InputMaybe<Scalars['Int']['input']>;
    min_sets?: InputMaybe<Scalars['Int']['input']>;
    rest?: InputMaybe<Scalars['Int']['input']>;
};

export type WorkoutExercisePivot = {
    __typename?: 'WorkoutExercisePivot';
    created_at: Scalars['String']['output'];
    is_drop_set: Scalars['Boolean']['output'];
    is_giant_set: Scalars['Boolean']['output'];
    is_super_set: Scalars['Boolean']['output'];
    max_reps?: Maybe<Scalars['Int']['output']>;
    max_sets?: Maybe<Scalars['Int']['output']>;
    min_reps?: Maybe<Scalars['Int']['output']>;
    min_sets?: Maybe<Scalars['Int']['output']>;
    notes?: Maybe<Scalars['String']['output']>;
    rest?: Maybe<Scalars['Int']['output']>;
    updated_at?: Maybe<Scalars['String']['output']>;
};

export type WorkoutsFilters = {
    __typename?: 'WorkoutsFilters';
    name?: Maybe<Scalars['String']['output']>;
};

export type ChangePasswordMutationVariables = Exact<{
    currentPassword: Scalars['String']['input'];
    newPassword: Scalars['String']['input'];
}>;

export type ChangePasswordMutation = {
    __typename?: 'Mutation';
    changePassword: { __typename?: 'User'; id: string };
};

export type CreateDashboardMutationVariables = Exact<{
    name: Scalars['String']['input'];
    description?: InputMaybe<Scalars['String']['input']>;
}>;

export type CreateDashboardMutation = {
    __typename?: 'Mutation';
    createDashboard: {
        __typename?: 'Dashboard';
        id: string;
        name: string;
        created_at: string;
        updated_at?: string | null;
    };
};

export type CreateExerciseMutationVariables = Exact<{
    name: Scalars['String']['input'];
    description: Scalars['String']['input'];
}>;

export type CreateExerciseMutation = {
    __typename?: 'Mutation';
    createExercise: {
        __typename?: 'Exercise';
        id: string;
        name: string;
        created_at: string;
        updated_at?: string | null;
    };
};

export type CreateGoalMutationVariables = Exact<{
    calories: Scalars['Int']['input'];
    carbohydrates: Scalars['Int']['input'];
    date: Scalars['String']['input'];
    fat: Scalars['Int']['input'];
    fiber: Scalars['Int']['input'];
    protein: Scalars['Int']['input'];
    userId: Scalars['ID']['input'];
}>;

export type CreateGoalMutation = {
    __typename?: 'Mutation';
    createGoal: {
        __typename?: 'Goal';
        id: string;
        calories: number;
        carbohydrates: number;
        date: string;
        fat: number;
        fiber: number;
        protein: number;
        created_at: string;
        updated_at?: string | null;
    };
};

export type CreateLeadMutationVariables = Exact<{
    activityLevel: ActivityLevel;
    dateOfBirth: Scalars['String']['input'];
    email: Scalars['String']['input'];
    firstName: Scalars['String']['input'];
    lastName: Scalars['String']['input'];
    gender: Gender;
    goal: Scalars['String']['input'];
    height: Scalars['Float']['input'];
    weight: Scalars['Float']['input'];
    bmr: Scalars['Int']['input'];
    tdee: Scalars['Int']['input'];
    status: Scalars['String']['input'];
}>;

export type CreateLeadMutation = {
    __typename?: 'Mutation';
    createLead: {
        __typename?: 'Lead';
        id: string;
        email: string;
        gender: Gender;
        first_name: string;
        last_name: string;
        status: string;
    };
};

export type CreateProgrammeMutationVariables = Exact<{
    name: Scalars['String']['input'];
    description: Scalars['String']['input'];
    startDate: Scalars['String']['input'];
    endDate: Scalars['String']['input'];
    userId: Scalars['ID']['input'];
}>;

export type CreateProgrammeMutation = {
    __typename?: 'Mutation';
    createProgramme: {
        __typename?: 'Programme';
        id: string;
        name: string;
        description?: string | null;
        start_date: string;
        end_date: string;
        created_at: string;
        updated_at?: string | null;
    };
};

export type CreateUserMutationVariables = Exact<{
    activityLevel: ActivityLevel;
    dateOfBirth: Scalars['String']['input'];
    email: Scalars['String']['input'];
    firstName: Scalars['String']['input'];
    lastName: Scalars['String']['input'];
    gender: Gender;
}>;

export type CreateUserMutation = {
    __typename?: 'Mutation';
    createUser: {
        __typename?: 'User';
        id: string;
        first_name: string;
        last_name: string;
        created_at: string;
        updated_at?: string | null;
    };
};

export type CreateWidgetMutationVariables = Exact<{
    name: Scalars['String']['input'];
    description?: InputMaybe<Scalars['String']['input']>;
    config: Scalars['String']['input'];
    dashboardId: Scalars['ID']['input'];
}>;

export type CreateWidgetMutation = {
    __typename?: 'Mutation';
    createWidget: { __typename?: 'Widget'; id: string };
};

export type CreateWorkoutMutationVariables = Exact<{
    name: Scalars['String']['input'];
    description: Scalars['String']['input'];
}>;

export type CreateWorkoutMutation = {
    __typename?: 'Mutation';
    createWorkout: {
        __typename?: 'Workout';
        id: string;
        name: string;
        created_at: string;
        updated_at?: string | null;
    };
};

export type DeleteDashboardMutationVariables = Exact<{
    dashboardId: Scalars['ID']['input'];
}>;

export type DeleteDashboardMutation = { __typename?: 'Mutation'; deleteDashboard: string };

export type DeleteExerciseMutationVariables = Exact<{
    exerciseId: Scalars['ID']['input'];
}>;

export type DeleteExerciseMutation = { __typename?: 'Mutation'; deleteExercise: string };

export type DeleteLeadMutationVariables = Exact<{
    leadId: Scalars['ID']['input'];
}>;

export type DeleteLeadMutation = { __typename?: 'Mutation'; deleteLead: string };

export type DeleteProgrammeMutationVariables = Exact<{
    programmeId: Scalars['ID']['input'];
}>;

export type DeleteProgrammeMutation = { __typename?: 'Mutation'; deleteProgramme: string };

export type DeleteUserMutationVariables = Exact<{
    userId: Scalars['ID']['input'];
}>;

export type DeleteUserMutation = { __typename?: 'Mutation'; deleteUser: string };

export type DeleteWidgetMutationVariables = Exact<{
    widgetId: Scalars['ID']['input'];
}>;

export type DeleteWidgetMutation = { __typename?: 'Mutation'; deleteWidget: string };

export type DeleteWorkoutMutationVariables = Exact<{
    workoutId: Scalars['ID']['input'];
}>;

export type DeleteWorkoutMutation = { __typename?: 'Mutation'; deleteWorkout: string };

export type LoginMutationVariables = Exact<{
    email: Scalars['String']['input'];
    password: Scalars['String']['input'];
}>;

export type LoginMutation = {
    __typename?: 'Mutation';
    login: { __typename?: 'Token'; token: string };
};

export type UpdateDashboardMutationVariables = Exact<{
    dashboardId: Scalars['ID']['input'];
    name?: InputMaybe<Scalars['String']['input']>;
    description?: InputMaybe<Scalars['String']['input']>;
}>;

export type UpdateDashboardMutation = {
    __typename?: 'Mutation';
    updateDashboard: {
        __typename?: 'Dashboard';
        id: string;
        name: string;
        description?: string | null;
        created_at: string;
        updated_at?: string | null;
    };
};

export type UpdateExerciseMutationVariables = Exact<{
    exerciseId: Scalars['ID']['input'];
    name?: InputMaybe<Scalars['String']['input']>;
    description?: InputMaybe<Scalars['String']['input']>;
}>;

export type UpdateExerciseMutation = {
    __typename?: 'Mutation';
    updateExercise: {
        __typename?: 'Exercise';
        id: string;
        name: string;
        description?: string | null;
        created_at: string;
        updated_at?: string | null;
    };
};

export type UpdateGoalMutationVariables = Exact<{
    goalId: Scalars['ID']['input'];
    calories?: InputMaybe<Scalars['Int']['input']>;
    carbohydrates?: InputMaybe<Scalars['Int']['input']>;
    date?: InputMaybe<Scalars['String']['input']>;
    fat?: InputMaybe<Scalars['Int']['input']>;
    fiber?: InputMaybe<Scalars['Int']['input']>;
    protein?: InputMaybe<Scalars['Int']['input']>;
}>;

export type UpdateGoalMutation = {
    __typename?: 'Mutation';
    updateGoal: {
        __typename?: 'Goal';
        id: string;
        calories: number;
        carbohydrates: number;
        date: string;
        fat: number;
        fiber: number;
        protein: number;
        created_at: string;
        updated_at?: string | null;
    };
};

export type UpdateLeadMutationVariables = Exact<{
    leadId: Scalars['ID']['input'];
    activityLevel?: InputMaybe<ActivityLevel>;
    dateOfBirth?: InputMaybe<Scalars['String']['input']>;
    email?: InputMaybe<Scalars['String']['input']>;
    firstName?: InputMaybe<Scalars['String']['input']>;
    lastName?: InputMaybe<Scalars['String']['input']>;
    gender?: InputMaybe<Gender>;
    goal?: InputMaybe<Scalars['String']['input']>;
    height?: InputMaybe<Scalars['Float']['input']>;
    weight?: InputMaybe<Scalars['Float']['input']>;
    bmr?: InputMaybe<Scalars['Int']['input']>;
    tdee?: InputMaybe<Scalars['Int']['input']>;
    status?: InputMaybe<Scalars['String']['input']>;
}>;

export type UpdateLeadMutation = {
    __typename?: 'Mutation';
    updateLead: {
        __typename?: 'Lead';
        id: string;
        email: string;
        gender: Gender;
        first_name: string;
        last_name: string;
        status: string;
    };
};

export type UpdateProgrammeMutationVariables = Exact<{
    programmeId: Scalars['ID']['input'];
    name?: InputMaybe<Scalars['String']['input']>;
    description?: InputMaybe<Scalars['String']['input']>;
    startDate?: InputMaybe<Scalars['String']['input']>;
    endDate?: InputMaybe<Scalars['String']['input']>;
    workouts?: InputMaybe<Array<ProgrammeWorkoutInput> | ProgrammeWorkoutInput>;
}>;

export type UpdateProgrammeMutation = {
    __typename?: 'Mutation';
    updateProgramme: {
        __typename?: 'Programme';
        id: string;
        name: string;
        description?: string | null;
        created_at: string;
        updated_at?: string | null;
    };
};

export type UpdateUserMutationVariables = Exact<{
    userId: Scalars['ID']['input'];
    activityLevel: ActivityLevel;
    dateOfBirth?: InputMaybe<Scalars['String']['input']>;
    email?: InputMaybe<Scalars['String']['input']>;
    firstName?: InputMaybe<Scalars['String']['input']>;
    lastName?: InputMaybe<Scalars['String']['input']>;
    gender?: InputMaybe<Gender>;
}>;

export type UpdateUserMutation = {
    __typename?: 'Mutation';
    updateUser: {
        __typename?: 'User';
        id: string;
        date_of_birth: string;
        email: string;
        first_name: string;
        last_name: string;
        gender: Gender;
    };
};

export type UpdateWorkoutMutationVariables = Exact<{
    workoutId: Scalars['ID']['input'];
    name?: InputMaybe<Scalars['String']['input']>;
    description?: InputMaybe<Scalars['String']['input']>;
    exercises?: InputMaybe<Array<WorkoutExerciseInput> | WorkoutExerciseInput>;
}>;

export type UpdateWorkoutMutation = {
    __typename?: 'Mutation';
    updateWorkout: {
        __typename?: 'Workout';
        id: string;
        name: string;
        description?: string | null;
        created_at: string;
        updated_at?: string | null;
    };
};

export type GetCurrentUserQueryVariables = Exact<{ [key: string]: never }>;

export type GetCurrentUserQuery = {
    __typename?: 'Query';
    me: { __typename?: 'User'; id: string; first_name: string; last_name: string };
};

export type GetDashboardQueryVariables = Exact<{
    dashboardId: Scalars['ID']['input'];
}>;

export type GetDashboardQuery = {
    __typename?: 'Query';
    dashboard: {
        __typename?: 'Dashboard';
        id: string;
        name: string;
        description?: string | null;
        created_at: string;
        updated_at?: string | null;
        widgets?: Array<{ __typename?: 'Widget'; id: string; name: string; config: string }> | null;
    };
};

export type GetDashboardsQueryVariables = Exact<{
    offset?: InputMaybe<Scalars['Int']['input']>;
    limit?: InputMaybe<Scalars['Int']['input']>;
}>;

export type GetDashboardsQuery = {
    __typename?: 'Query';
    dashboards?: Array<{
        __typename?: 'Dashboard';
        id: string;
        name: string;
        created_at: string;
        updated_at?: string | null;
    }> | null;
};

export type GetExerciseQueryVariables = Exact<{
    exerciseId: Scalars['ID']['input'];
}>;

export type GetExerciseQuery = {
    __typename?: 'Query';
    exercise: {
        __typename?: 'Exercise';
        id: string;
        name: string;
        description?: string | null;
        created_at: string;
        updated_at?: string | null;
        workouts?: Array<{ __typename?: 'Workout'; id: string; name: string }> | null;
    };
};

export type GetExercisesQueryVariables = Exact<{
    offset?: InputMaybe<Scalars['Int']['input']>;
    limit?: InputMaybe<Scalars['Int']['input']>;
}>;

export type GetExercisesQuery = {
    __typename?: 'Query';
    exercises?: Array<{
        __typename?: 'Exercise';
        id: string;
        name: string;
        created_at: string;
        updated_at?: string | null;
    }> | null;
};

export type GetGoalQueryVariables = Exact<{
    goalId: Scalars['ID']['input'];
}>;

export type GetGoalQuery = {
    __typename?: 'Query';
    goal: {
        __typename?: 'Goal';
        id: string;
        calories: number;
        carbohydrates: number;
        date: string;
        fat: number;
        fiber: number;
        protein: number;
        created_at: string;
        updated_at?: string | null;
    };
};

export type GetLeadQueryVariables = Exact<{
    leadId: Scalars['ID']['input'];
}>;

export type GetLeadQuery = {
    __typename?: 'Query';
    lead: {
        __typename?: 'Lead';
        id: string;
        activity_level: ActivityLevel;
        date_of_birth: string;
        email: string;
        first_name: string;
        last_name: string;
        gender: Gender;
        goal: string;
        height_cm: number;
        weight_kg: number;
        bmr: number;
        status: string;
        created_at: string;
        updated_at?: string | null;
    };
};

export type GetLeadsQueryVariables = Exact<{ [key: string]: never }>;

export type GetLeadsQuery = {
    __typename?: 'Query';
    leads?: Array<{
        __typename?: 'Lead';
        id: string;
        email: string;
        first_name: string;
        last_name: string;
        status: string;
    }> | null;
};

export type GetProgrammeQueryVariables = Exact<{
    programmeId: Scalars['ID']['input'];
}>;

export type GetProgrammeQuery = {
    __typename?: 'Query';
    programme: {
        __typename?: 'Programme';
        id: string;
        name: string;
        description?: string | null;
        start_date: string;
        end_date: string;
        created_at: string;
        updated_at?: string | null;
        workouts?: Array<{
            __typename?: 'Workout';
            id: string;
            name: string;
            description?: string | null;
        }> | null;
    };
};

export type GetUserQueryVariables = Exact<{
    userId: Scalars['ID']['input'];
    includeActivities?: InputMaybe<Scalars['Boolean']['input']>;
    includeGoals?: InputMaybe<Scalars['Boolean']['input']>;
    includeNutrition?: InputMaybe<Scalars['Boolean']['input']>;
    includeProgrammes?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type GetUserQuery = {
    __typename?: 'Query';
    user: {
        __typename?: 'User';
        id: string;
        activity_level: ActivityLevel;
        avatar: string;
        date_of_birth: string;
        email: string;
        first_name: string;
        last_name: string;
        gender: Gender;
        created_at: string;
        updated_at?: string | null;
        activities?: Array<{ __typename?: 'Activity'; id: string; created_at: string }> | null;
        goals?: Array<{
            __typename?: 'Goal';
            id: string;
            calories: number;
            carbohydrates: number;
            date: string;
            fat: number;
            fiber: number;
            protein: number;
            created_at: string;
            updated_at?: string | null;
        }> | null;
        nutrition?: Array<{
            __typename?: 'Nutrition';
            id: string;
            date: string;
            protein?: number | null;
            carbohydrates?: number | null;
            fat?: number | null;
            fiber?: number | null;
            calories?: number | null;
        }> | null;
        programmes?: Array<{
            __typename?: 'Programme';
            id: string;
            name: string;
            start_date: string;
            end_date: string;
            status: string;
            created_at: string;
            updated_at?: string | null;
        }> | null;
    };
};

export type GetUsersQueryVariables = Exact<{
    offset?: InputMaybe<Scalars['Int']['input']>;
    limit?: InputMaybe<Scalars['Int']['input']>;
}>;

export type GetUsersQuery = {
    __typename?: 'Query';
    users?: Array<{
        __typename?: 'User';
        id: string;
        avatar: string;
        first_name: string;
        last_name: string;
        created_at: string;
        updated_at?: string | null;
    }> | null;
};

export type GetWorkoutQueryVariables = Exact<{
    workoutId: Scalars['ID']['input'];
}>;

export type GetWorkoutQuery = {
    __typename?: 'Query';
    workout: {
        __typename?: 'Workout';
        id: string;
        name: string;
        description?: string | null;
        created_at: string;
        updated_at?: string | null;
        exercises?: Array<{
            __typename?: 'Exercise';
            id: string;
            name: string;
            pivot?: {
                __typename?: 'WorkoutExercisePivot';
                min_sets?: number | null;
                max_sets?: number | null;
                min_reps?: number | null;
                max_reps?: number | null;
                rest?: number | null;
            } | null;
        }> | null;
    };
};

export type GetWorkoutsQueryVariables = Exact<{
    offset?: InputMaybe<Scalars['Int']['input']>;
    limit?: InputMaybe<Scalars['Int']['input']>;
}>;

export type GetWorkoutsQuery = {
    __typename?: 'Query';
    workouts?: Array<{
        __typename?: 'Workout';
        id: string;
        name: string;
        created_at: string;
        updated_at?: string | null;
    }> | null;
};

export const ChangePasswordDocument = gql`
    mutation changePassword($currentPassword: String!, $newPassword: String!) {
        changePassword(currentPassword: $currentPassword, newPassword: $newPassword) {
            id
        }
    }
`;
export type ChangePasswordMutationFn = Apollo.MutationFunction<
    ChangePasswordMutation,
    ChangePasswordMutationVariables
>;

/**
 * __useChangePasswordMutation__
 *
 * To run a mutation, you first call `useChangePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordMutation, { data, loading, error }] = useChangePasswordMutation({
 *   variables: {
 *      currentPassword: // value for 'currentPassword'
 *      newPassword: // value for 'newPassword'
 *   },
 * });
 */
export function useChangePasswordMutation(
    baseOptions?: Apollo.MutationHookOptions<
        ChangePasswordMutation,
        ChangePasswordMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<ChangePasswordMutation, ChangePasswordMutationVariables>(
        ChangePasswordDocument,
        options
    );
}
export type ChangePasswordMutationHookResult = ReturnType<typeof useChangePasswordMutation>;
export type ChangePasswordMutationResult = Apollo.MutationResult<ChangePasswordMutation>;
export type ChangePasswordMutationOptions = Apollo.BaseMutationOptions<
    ChangePasswordMutation,
    ChangePasswordMutationVariables
>;
export const CreateDashboardDocument = gql`
    mutation createDashboard($name: String!, $description: String) {
        createDashboard(input: { name: $name, description: $description }) {
            id
            name
            created_at
            updated_at
        }
    }
`;
export type CreateDashboardMutationFn = Apollo.MutationFunction<
    CreateDashboardMutation,
    CreateDashboardMutationVariables
>;

/**
 * __useCreateDashboardMutation__
 *
 * To run a mutation, you first call `useCreateDashboardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDashboardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDashboardMutation, { data, loading, error }] = useCreateDashboardMutation({
 *   variables: {
 *      name: // value for 'name'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useCreateDashboardMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CreateDashboardMutation,
        CreateDashboardMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<CreateDashboardMutation, CreateDashboardMutationVariables>(
        CreateDashboardDocument,
        options
    );
}
export type CreateDashboardMutationHookResult = ReturnType<typeof useCreateDashboardMutation>;
export type CreateDashboardMutationResult = Apollo.MutationResult<CreateDashboardMutation>;
export type CreateDashboardMutationOptions = Apollo.BaseMutationOptions<
    CreateDashboardMutation,
    CreateDashboardMutationVariables
>;
export const CreateExerciseDocument = gql`
    mutation createExercise($name: String!, $description: String!) {
        createExercise(input: { name: $name, description: $description }) {
            id
            name
            created_at
            updated_at
        }
    }
`;
export type CreateExerciseMutationFn = Apollo.MutationFunction<
    CreateExerciseMutation,
    CreateExerciseMutationVariables
>;

/**
 * __useCreateExerciseMutation__
 *
 * To run a mutation, you first call `useCreateExerciseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateExerciseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createExerciseMutation, { data, loading, error }] = useCreateExerciseMutation({
 *   variables: {
 *      name: // value for 'name'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useCreateExerciseMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CreateExerciseMutation,
        CreateExerciseMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<CreateExerciseMutation, CreateExerciseMutationVariables>(
        CreateExerciseDocument,
        options
    );
}
export type CreateExerciseMutationHookResult = ReturnType<typeof useCreateExerciseMutation>;
export type CreateExerciseMutationResult = Apollo.MutationResult<CreateExerciseMutation>;
export type CreateExerciseMutationOptions = Apollo.BaseMutationOptions<
    CreateExerciseMutation,
    CreateExerciseMutationVariables
>;
export const CreateGoalDocument = gql`
    mutation createGoal(
        $calories: Int!
        $carbohydrates: Int!
        $date: String!
        $fat: Int!
        $fiber: Int!
        $protein: Int!
        $userId: ID!
    ) {
        createGoal(
            input: {
                calories: $calories
                carbohydrates: $carbohydrates
                date: $date
                fat: $fat
                fiber: $fiber
                protein: $protein
                user_id: $userId
            }
        ) {
            id
            calories
            carbohydrates
            date
            fat
            fiber
            protein
            created_at
            updated_at
        }
    }
`;
export type CreateGoalMutationFn = Apollo.MutationFunction<
    CreateGoalMutation,
    CreateGoalMutationVariables
>;

/**
 * __useCreateGoalMutation__
 *
 * To run a mutation, you first call `useCreateGoalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGoalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGoalMutation, { data, loading, error }] = useCreateGoalMutation({
 *   variables: {
 *      calories: // value for 'calories'
 *      carbohydrates: // value for 'carbohydrates'
 *      date: // value for 'date'
 *      fat: // value for 'fat'
 *      fiber: // value for 'fiber'
 *      protein: // value for 'protein'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useCreateGoalMutation(
    baseOptions?: Apollo.MutationHookOptions<CreateGoalMutation, CreateGoalMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<CreateGoalMutation, CreateGoalMutationVariables>(
        CreateGoalDocument,
        options
    );
}
export type CreateGoalMutationHookResult = ReturnType<typeof useCreateGoalMutation>;
export type CreateGoalMutationResult = Apollo.MutationResult<CreateGoalMutation>;
export type CreateGoalMutationOptions = Apollo.BaseMutationOptions<
    CreateGoalMutation,
    CreateGoalMutationVariables
>;
export const CreateLeadDocument = gql`
    mutation CreateLead(
        $activityLevel: ActivityLevel!
        $dateOfBirth: String!
        $email: String!
        $firstName: String!
        $lastName: String!
        $gender: Gender!
        $goal: String!
        $height: Float!
        $weight: Float!
        $bmr: Int!
        $tdee: Int!
        $status: String!
    ) {
        createLead(
            input: {
                activity_level: $activityLevel
                date_of_birth: $dateOfBirth
                email: $email
                first_name: $firstName
                last_name: $lastName
                gender: $gender
                goal: $goal
                height_cm: $height
                weight_kg: $weight
                bmr: $bmr
                tdee: $tdee
                status: $status
            }
        ) {
            id
            email
            gender
            first_name
            last_name
            status
        }
    }
`;
export type CreateLeadMutationFn = Apollo.MutationFunction<
    CreateLeadMutation,
    CreateLeadMutationVariables
>;

/**
 * __useCreateLeadMutation__
 *
 * To run a mutation, you first call `useCreateLeadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLeadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLeadMutation, { data, loading, error }] = useCreateLeadMutation({
 *   variables: {
 *      activityLevel: // value for 'activityLevel'
 *      dateOfBirth: // value for 'dateOfBirth'
 *      email: // value for 'email'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      gender: // value for 'gender'
 *      goal: // value for 'goal'
 *      height: // value for 'height'
 *      weight: // value for 'weight'
 *      bmr: // value for 'bmr'
 *      tdee: // value for 'tdee'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useCreateLeadMutation(
    baseOptions?: Apollo.MutationHookOptions<CreateLeadMutation, CreateLeadMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<CreateLeadMutation, CreateLeadMutationVariables>(
        CreateLeadDocument,
        options
    );
}
export type CreateLeadMutationHookResult = ReturnType<typeof useCreateLeadMutation>;
export type CreateLeadMutationResult = Apollo.MutationResult<CreateLeadMutation>;
export type CreateLeadMutationOptions = Apollo.BaseMutationOptions<
    CreateLeadMutation,
    CreateLeadMutationVariables
>;
export const CreateProgrammeDocument = gql`
    mutation createProgramme(
        $name: String!
        $description: String!
        $startDate: String!
        $endDate: String!
        $userId: ID!
    ) {
        createProgramme(
            input: {
                name: $name
                description: $description
                start_date: $startDate
                end_date: $endDate
                user_id: $userId
            }
        ) {
            id
            name
            description
            start_date
            end_date
            created_at
            updated_at
        }
    }
`;
export type CreateProgrammeMutationFn = Apollo.MutationFunction<
    CreateProgrammeMutation,
    CreateProgrammeMutationVariables
>;

/**
 * __useCreateProgrammeMutation__
 *
 * To run a mutation, you first call `useCreateProgrammeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProgrammeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProgrammeMutation, { data, loading, error }] = useCreateProgrammeMutation({
 *   variables: {
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useCreateProgrammeMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CreateProgrammeMutation,
        CreateProgrammeMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<CreateProgrammeMutation, CreateProgrammeMutationVariables>(
        CreateProgrammeDocument,
        options
    );
}
export type CreateProgrammeMutationHookResult = ReturnType<typeof useCreateProgrammeMutation>;
export type CreateProgrammeMutationResult = Apollo.MutationResult<CreateProgrammeMutation>;
export type CreateProgrammeMutationOptions = Apollo.BaseMutationOptions<
    CreateProgrammeMutation,
    CreateProgrammeMutationVariables
>;
export const CreateUserDocument = gql`
    mutation createUser(
        $activityLevel: ActivityLevel!
        $dateOfBirth: String!
        $email: String!
        $firstName: String!
        $lastName: String!
        $gender: Gender!
    ) {
        createUser(
            input: {
                activity_level: $activityLevel
                date_of_birth: $dateOfBirth
                email: $email
                first_name: $firstName
                last_name: $lastName
                gender: $gender
            }
        ) {
            id
            first_name
            last_name
            created_at
            updated_at
        }
    }
`;
export type CreateUserMutationFn = Apollo.MutationFunction<
    CreateUserMutation,
    CreateUserMutationVariables
>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      activityLevel: // value for 'activityLevel'
 *      dateOfBirth: // value for 'dateOfBirth'
 *      email: // value for 'email'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      gender: // value for 'gender'
 *   },
 * });
 */
export function useCreateUserMutation(
    baseOptions?: Apollo.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(
        CreateUserDocument,
        options
    );
}
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<
    CreateUserMutation,
    CreateUserMutationVariables
>;
export const CreateWidgetDocument = gql`
    mutation createWidget(
        $name: String!
        $description: String
        $config: String!
        $dashboardId: ID!
    ) {
        createWidget(
            input: {
                name: $name
                description: $description
                config: $config
                dashboard_id: $dashboardId
            }
        ) {
            id
        }
    }
`;
export type CreateWidgetMutationFn = Apollo.MutationFunction<
    CreateWidgetMutation,
    CreateWidgetMutationVariables
>;

/**
 * __useCreateWidgetMutation__
 *
 * To run a mutation, you first call `useCreateWidgetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWidgetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWidgetMutation, { data, loading, error }] = useCreateWidgetMutation({
 *   variables: {
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      config: // value for 'config'
 *      dashboardId: // value for 'dashboardId'
 *   },
 * });
 */
export function useCreateWidgetMutation(
    baseOptions?: Apollo.MutationHookOptions<CreateWidgetMutation, CreateWidgetMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<CreateWidgetMutation, CreateWidgetMutationVariables>(
        CreateWidgetDocument,
        options
    );
}
export type CreateWidgetMutationHookResult = ReturnType<typeof useCreateWidgetMutation>;
export type CreateWidgetMutationResult = Apollo.MutationResult<CreateWidgetMutation>;
export type CreateWidgetMutationOptions = Apollo.BaseMutationOptions<
    CreateWidgetMutation,
    CreateWidgetMutationVariables
>;
export const CreateWorkoutDocument = gql`
    mutation createWorkout($name: String!, $description: String!) {
        createWorkout(input: { name: $name, description: $description }) {
            id
            name
            created_at
            updated_at
        }
    }
`;
export type CreateWorkoutMutationFn = Apollo.MutationFunction<
    CreateWorkoutMutation,
    CreateWorkoutMutationVariables
>;

/**
 * __useCreateWorkoutMutation__
 *
 * To run a mutation, you first call `useCreateWorkoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWorkoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWorkoutMutation, { data, loading, error }] = useCreateWorkoutMutation({
 *   variables: {
 *      name: // value for 'name'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useCreateWorkoutMutation(
    baseOptions?: Apollo.MutationHookOptions<CreateWorkoutMutation, CreateWorkoutMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<CreateWorkoutMutation, CreateWorkoutMutationVariables>(
        CreateWorkoutDocument,
        options
    );
}
export type CreateWorkoutMutationHookResult = ReturnType<typeof useCreateWorkoutMutation>;
export type CreateWorkoutMutationResult = Apollo.MutationResult<CreateWorkoutMutation>;
export type CreateWorkoutMutationOptions = Apollo.BaseMutationOptions<
    CreateWorkoutMutation,
    CreateWorkoutMutationVariables
>;
export const DeleteDashboardDocument = gql`
    mutation deleteDashboard($dashboardId: ID!) {
        deleteDashboard(dashboardId: $dashboardId)
    }
`;
export type DeleteDashboardMutationFn = Apollo.MutationFunction<
    DeleteDashboardMutation,
    DeleteDashboardMutationVariables
>;

/**
 * __useDeleteDashboardMutation__
 *
 * To run a mutation, you first call `useDeleteDashboardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDashboardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDashboardMutation, { data, loading, error }] = useDeleteDashboardMutation({
 *   variables: {
 *      dashboardId: // value for 'dashboardId'
 *   },
 * });
 */
export function useDeleteDashboardMutation(
    baseOptions?: Apollo.MutationHookOptions<
        DeleteDashboardMutation,
        DeleteDashboardMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<DeleteDashboardMutation, DeleteDashboardMutationVariables>(
        DeleteDashboardDocument,
        options
    );
}
export type DeleteDashboardMutationHookResult = ReturnType<typeof useDeleteDashboardMutation>;
export type DeleteDashboardMutationResult = Apollo.MutationResult<DeleteDashboardMutation>;
export type DeleteDashboardMutationOptions = Apollo.BaseMutationOptions<
    DeleteDashboardMutation,
    DeleteDashboardMutationVariables
>;
export const DeleteExerciseDocument = gql`
    mutation deleteExercise($exerciseId: ID!) {
        deleteExercise(exerciseId: $exerciseId)
    }
`;
export type DeleteExerciseMutationFn = Apollo.MutationFunction<
    DeleteExerciseMutation,
    DeleteExerciseMutationVariables
>;

/**
 * __useDeleteExerciseMutation__
 *
 * To run a mutation, you first call `useDeleteExerciseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteExerciseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteExerciseMutation, { data, loading, error }] = useDeleteExerciseMutation({
 *   variables: {
 *      exerciseId: // value for 'exerciseId'
 *   },
 * });
 */
export function useDeleteExerciseMutation(
    baseOptions?: Apollo.MutationHookOptions<
        DeleteExerciseMutation,
        DeleteExerciseMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<DeleteExerciseMutation, DeleteExerciseMutationVariables>(
        DeleteExerciseDocument,
        options
    );
}
export type DeleteExerciseMutationHookResult = ReturnType<typeof useDeleteExerciseMutation>;
export type DeleteExerciseMutationResult = Apollo.MutationResult<DeleteExerciseMutation>;
export type DeleteExerciseMutationOptions = Apollo.BaseMutationOptions<
    DeleteExerciseMutation,
    DeleteExerciseMutationVariables
>;
export const DeleteLeadDocument = gql`
    mutation deleteLead($leadId: ID!) {
        deleteLead(leadId: $leadId)
    }
`;
export type DeleteLeadMutationFn = Apollo.MutationFunction<
    DeleteLeadMutation,
    DeleteLeadMutationVariables
>;

/**
 * __useDeleteLeadMutation__
 *
 * To run a mutation, you first call `useDeleteLeadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLeadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLeadMutation, { data, loading, error }] = useDeleteLeadMutation({
 *   variables: {
 *      leadId: // value for 'leadId'
 *   },
 * });
 */
export function useDeleteLeadMutation(
    baseOptions?: Apollo.MutationHookOptions<DeleteLeadMutation, DeleteLeadMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<DeleteLeadMutation, DeleteLeadMutationVariables>(
        DeleteLeadDocument,
        options
    );
}
export type DeleteLeadMutationHookResult = ReturnType<typeof useDeleteLeadMutation>;
export type DeleteLeadMutationResult = Apollo.MutationResult<DeleteLeadMutation>;
export type DeleteLeadMutationOptions = Apollo.BaseMutationOptions<
    DeleteLeadMutation,
    DeleteLeadMutationVariables
>;
export const DeleteProgrammeDocument = gql`
    mutation deleteProgramme($programmeId: ID!) {
        deleteProgramme(programmeId: $programmeId)
    }
`;
export type DeleteProgrammeMutationFn = Apollo.MutationFunction<
    DeleteProgrammeMutation,
    DeleteProgrammeMutationVariables
>;

/**
 * __useDeleteProgrammeMutation__
 *
 * To run a mutation, you first call `useDeleteProgrammeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProgrammeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProgrammeMutation, { data, loading, error }] = useDeleteProgrammeMutation({
 *   variables: {
 *      programmeId: // value for 'programmeId'
 *   },
 * });
 */
export function useDeleteProgrammeMutation(
    baseOptions?: Apollo.MutationHookOptions<
        DeleteProgrammeMutation,
        DeleteProgrammeMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<DeleteProgrammeMutation, DeleteProgrammeMutationVariables>(
        DeleteProgrammeDocument,
        options
    );
}
export type DeleteProgrammeMutationHookResult = ReturnType<typeof useDeleteProgrammeMutation>;
export type DeleteProgrammeMutationResult = Apollo.MutationResult<DeleteProgrammeMutation>;
export type DeleteProgrammeMutationOptions = Apollo.BaseMutationOptions<
    DeleteProgrammeMutation,
    DeleteProgrammeMutationVariables
>;
export const DeleteUserDocument = gql`
    mutation deleteUser($userId: ID!) {
        deleteUser(userId: $userId)
    }
`;
export type DeleteUserMutationFn = Apollo.MutationFunction<
    DeleteUserMutation,
    DeleteUserMutationVariables
>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useDeleteUserMutation(
    baseOptions?: Apollo.MutationHookOptions<DeleteUserMutation, DeleteUserMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(
        DeleteUserDocument,
        options
    );
}
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>;
export type DeleteUserMutationResult = Apollo.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<
    DeleteUserMutation,
    DeleteUserMutationVariables
>;
export const DeleteWidgetDocument = gql`
    mutation deleteWidget($widgetId: ID!) {
        deleteWidget(widgetId: $widgetId)
    }
`;
export type DeleteWidgetMutationFn = Apollo.MutationFunction<
    DeleteWidgetMutation,
    DeleteWidgetMutationVariables
>;

/**
 * __useDeleteWidgetMutation__
 *
 * To run a mutation, you first call `useDeleteWidgetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteWidgetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteWidgetMutation, { data, loading, error }] = useDeleteWidgetMutation({
 *   variables: {
 *      widgetId: // value for 'widgetId'
 *   },
 * });
 */
export function useDeleteWidgetMutation(
    baseOptions?: Apollo.MutationHookOptions<DeleteWidgetMutation, DeleteWidgetMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<DeleteWidgetMutation, DeleteWidgetMutationVariables>(
        DeleteWidgetDocument,
        options
    );
}
export type DeleteWidgetMutationHookResult = ReturnType<typeof useDeleteWidgetMutation>;
export type DeleteWidgetMutationResult = Apollo.MutationResult<DeleteWidgetMutation>;
export type DeleteWidgetMutationOptions = Apollo.BaseMutationOptions<
    DeleteWidgetMutation,
    DeleteWidgetMutationVariables
>;
export const DeleteWorkoutDocument = gql`
    mutation deleteWorkout($workoutId: ID!) {
        deleteWorkout(workoutId: $workoutId)
    }
`;
export type DeleteWorkoutMutationFn = Apollo.MutationFunction<
    DeleteWorkoutMutation,
    DeleteWorkoutMutationVariables
>;

/**
 * __useDeleteWorkoutMutation__
 *
 * To run a mutation, you first call `useDeleteWorkoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteWorkoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteWorkoutMutation, { data, loading, error }] = useDeleteWorkoutMutation({
 *   variables: {
 *      workoutId: // value for 'workoutId'
 *   },
 * });
 */
export function useDeleteWorkoutMutation(
    baseOptions?: Apollo.MutationHookOptions<DeleteWorkoutMutation, DeleteWorkoutMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<DeleteWorkoutMutation, DeleteWorkoutMutationVariables>(
        DeleteWorkoutDocument,
        options
    );
}
export type DeleteWorkoutMutationHookResult = ReturnType<typeof useDeleteWorkoutMutation>;
export type DeleteWorkoutMutationResult = Apollo.MutationResult<DeleteWorkoutMutation>;
export type DeleteWorkoutMutationOptions = Apollo.BaseMutationOptions<
    DeleteWorkoutMutation,
    DeleteWorkoutMutationVariables
>;
export const LoginDocument = gql`
    mutation login($email: String!, $password: String!) {
        login(input: { email: $email, password: $password }) {
            token
        }
    }
`;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginMutation(
    baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
}
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<
    LoginMutation,
    LoginMutationVariables
>;
export const UpdateDashboardDocument = gql`
    mutation updateDashboard($dashboardId: ID!, $name: String, $description: String) {
        updateDashboard(
            dashboardId: $dashboardId
            input: { name: $name, description: $description }
        ) {
            id
            name
            description
            created_at
            updated_at
        }
    }
`;
export type UpdateDashboardMutationFn = Apollo.MutationFunction<
    UpdateDashboardMutation,
    UpdateDashboardMutationVariables
>;

/**
 * __useUpdateDashboardMutation__
 *
 * To run a mutation, you first call `useUpdateDashboardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDashboardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDashboardMutation, { data, loading, error }] = useUpdateDashboardMutation({
 *   variables: {
 *      dashboardId: // value for 'dashboardId'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useUpdateDashboardMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdateDashboardMutation,
        UpdateDashboardMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<UpdateDashboardMutation, UpdateDashboardMutationVariables>(
        UpdateDashboardDocument,
        options
    );
}
export type UpdateDashboardMutationHookResult = ReturnType<typeof useUpdateDashboardMutation>;
export type UpdateDashboardMutationResult = Apollo.MutationResult<UpdateDashboardMutation>;
export type UpdateDashboardMutationOptions = Apollo.BaseMutationOptions<
    UpdateDashboardMutation,
    UpdateDashboardMutationVariables
>;
export const UpdateExerciseDocument = gql`
    mutation updateExercise($exerciseId: ID!, $name: String, $description: String) {
        updateExercise(exerciseId: $exerciseId, input: { name: $name, description: $description }) {
            id
            name
            description
            created_at
            updated_at
        }
    }
`;
export type UpdateExerciseMutationFn = Apollo.MutationFunction<
    UpdateExerciseMutation,
    UpdateExerciseMutationVariables
>;

/**
 * __useUpdateExerciseMutation__
 *
 * To run a mutation, you first call `useUpdateExerciseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateExerciseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateExerciseMutation, { data, loading, error }] = useUpdateExerciseMutation({
 *   variables: {
 *      exerciseId: // value for 'exerciseId'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useUpdateExerciseMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdateExerciseMutation,
        UpdateExerciseMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<UpdateExerciseMutation, UpdateExerciseMutationVariables>(
        UpdateExerciseDocument,
        options
    );
}
export type UpdateExerciseMutationHookResult = ReturnType<typeof useUpdateExerciseMutation>;
export type UpdateExerciseMutationResult = Apollo.MutationResult<UpdateExerciseMutation>;
export type UpdateExerciseMutationOptions = Apollo.BaseMutationOptions<
    UpdateExerciseMutation,
    UpdateExerciseMutationVariables
>;
export const UpdateGoalDocument = gql`
    mutation updateGoal(
        $goalId: ID!
        $calories: Int
        $carbohydrates: Int
        $date: String
        $fat: Int
        $fiber: Int
        $protein: Int
    ) {
        updateGoal(
            goalId: $goalId
            input: {
                calories: $calories
                carbohydrates: $carbohydrates
                date: $date
                fat: $fat
                fiber: $fiber
                protein: $protein
            }
        ) {
            id
            calories
            carbohydrates
            date
            fat
            fiber
            protein
            created_at
            updated_at
        }
    }
`;
export type UpdateGoalMutationFn = Apollo.MutationFunction<
    UpdateGoalMutation,
    UpdateGoalMutationVariables
>;

/**
 * __useUpdateGoalMutation__
 *
 * To run a mutation, you first call `useUpdateGoalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGoalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGoalMutation, { data, loading, error }] = useUpdateGoalMutation({
 *   variables: {
 *      goalId: // value for 'goalId'
 *      calories: // value for 'calories'
 *      carbohydrates: // value for 'carbohydrates'
 *      date: // value for 'date'
 *      fat: // value for 'fat'
 *      fiber: // value for 'fiber'
 *      protein: // value for 'protein'
 *   },
 * });
 */
export function useUpdateGoalMutation(
    baseOptions?: Apollo.MutationHookOptions<UpdateGoalMutation, UpdateGoalMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<UpdateGoalMutation, UpdateGoalMutationVariables>(
        UpdateGoalDocument,
        options
    );
}
export type UpdateGoalMutationHookResult = ReturnType<typeof useUpdateGoalMutation>;
export type UpdateGoalMutationResult = Apollo.MutationResult<UpdateGoalMutation>;
export type UpdateGoalMutationOptions = Apollo.BaseMutationOptions<
    UpdateGoalMutation,
    UpdateGoalMutationVariables
>;
export const UpdateLeadDocument = gql`
    mutation UpdateLead(
        $leadId: ID!
        $activityLevel: ActivityLevel
        $dateOfBirth: String
        $email: String
        $firstName: String
        $lastName: String
        $gender: Gender
        $goal: String
        $height: Float
        $weight: Float
        $bmr: Int
        $tdee: Int
        $status: String
    ) {
        updateLead(
            leadId: $leadId
            input: {
                activity_level: $activityLevel
                date_of_birth: $dateOfBirth
                email: $email
                first_name: $firstName
                last_name: $lastName
                gender: $gender
                goal: $goal
                height_cm: $height
                weight_kg: $weight
                bmr: $bmr
                tdee: $tdee
                status: $status
            }
        ) {
            id
            email
            gender
            first_name
            last_name
            status
        }
    }
`;
export type UpdateLeadMutationFn = Apollo.MutationFunction<
    UpdateLeadMutation,
    UpdateLeadMutationVariables
>;

/**
 * __useUpdateLeadMutation__
 *
 * To run a mutation, you first call `useUpdateLeadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLeadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLeadMutation, { data, loading, error }] = useUpdateLeadMutation({
 *   variables: {
 *      leadId: // value for 'leadId'
 *      activityLevel: // value for 'activityLevel'
 *      dateOfBirth: // value for 'dateOfBirth'
 *      email: // value for 'email'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      gender: // value for 'gender'
 *      goal: // value for 'goal'
 *      height: // value for 'height'
 *      weight: // value for 'weight'
 *      bmr: // value for 'bmr'
 *      tdee: // value for 'tdee'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useUpdateLeadMutation(
    baseOptions?: Apollo.MutationHookOptions<UpdateLeadMutation, UpdateLeadMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<UpdateLeadMutation, UpdateLeadMutationVariables>(
        UpdateLeadDocument,
        options
    );
}
export type UpdateLeadMutationHookResult = ReturnType<typeof useUpdateLeadMutation>;
export type UpdateLeadMutationResult = Apollo.MutationResult<UpdateLeadMutation>;
export type UpdateLeadMutationOptions = Apollo.BaseMutationOptions<
    UpdateLeadMutation,
    UpdateLeadMutationVariables
>;
export const UpdateProgrammeDocument = gql`
    mutation updateProgramme(
        $programmeId: ID!
        $name: String
        $description: String
        $startDate: String
        $endDate: String
        $workouts: [ProgrammeWorkoutInput!]
    ) {
        updateProgramme(
            programmeId: $programmeId
            input: {
                name: $name
                description: $description
                start_date: $startDate
                end_date: $endDate
                workouts: $workouts
            }
        ) {
            id
            name
            description
            created_at
            updated_at
        }
    }
`;
export type UpdateProgrammeMutationFn = Apollo.MutationFunction<
    UpdateProgrammeMutation,
    UpdateProgrammeMutationVariables
>;

/**
 * __useUpdateProgrammeMutation__
 *
 * To run a mutation, you first call `useUpdateProgrammeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProgrammeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProgrammeMutation, { data, loading, error }] = useUpdateProgrammeMutation({
 *   variables: {
 *      programmeId: // value for 'programmeId'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      workouts: // value for 'workouts'
 *   },
 * });
 */
export function useUpdateProgrammeMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdateProgrammeMutation,
        UpdateProgrammeMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<UpdateProgrammeMutation, UpdateProgrammeMutationVariables>(
        UpdateProgrammeDocument,
        options
    );
}
export type UpdateProgrammeMutationHookResult = ReturnType<typeof useUpdateProgrammeMutation>;
export type UpdateProgrammeMutationResult = Apollo.MutationResult<UpdateProgrammeMutation>;
export type UpdateProgrammeMutationOptions = Apollo.BaseMutationOptions<
    UpdateProgrammeMutation,
    UpdateProgrammeMutationVariables
>;
export const UpdateUserDocument = gql`
    mutation updateUser(
        $userId: ID!
        $activityLevel: ActivityLevel!
        $dateOfBirth: String
        $email: String
        $firstName: String
        $lastName: String
        $gender: Gender
    ) {
        updateUser(
            userId: $userId
            input: {
                activity_level: $activityLevel
                date_of_birth: $dateOfBirth
                email: $email
                first_name: $firstName
                last_name: $lastName
                gender: $gender
            }
        ) {
            id
            date_of_birth
            email
            first_name
            last_name
            gender
        }
    }
`;
export type UpdateUserMutationFn = Apollo.MutationFunction<
    UpdateUserMutation,
    UpdateUserMutationVariables
>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      activityLevel: // value for 'activityLevel'
 *      dateOfBirth: // value for 'dateOfBirth'
 *      email: // value for 'email'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      gender: // value for 'gender'
 *   },
 * });
 */
export function useUpdateUserMutation(
    baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(
        UpdateUserDocument,
        options
    );
}
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<
    UpdateUserMutation,
    UpdateUserMutationVariables
>;
export const UpdateWorkoutDocument = gql`
    mutation updateWorkout(
        $workoutId: ID!
        $name: String
        $description: String
        $exercises: [WorkoutExerciseInput!]
    ) {
        updateWorkout(
            workoutId: $workoutId
            input: { name: $name, description: $description, exercises: $exercises }
        ) {
            id
            name
            description
            created_at
            updated_at
        }
    }
`;
export type UpdateWorkoutMutationFn = Apollo.MutationFunction<
    UpdateWorkoutMutation,
    UpdateWorkoutMutationVariables
>;

/**
 * __useUpdateWorkoutMutation__
 *
 * To run a mutation, you first call `useUpdateWorkoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWorkoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWorkoutMutation, { data, loading, error }] = useUpdateWorkoutMutation({
 *   variables: {
 *      workoutId: // value for 'workoutId'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      exercises: // value for 'exercises'
 *   },
 * });
 */
export function useUpdateWorkoutMutation(
    baseOptions?: Apollo.MutationHookOptions<UpdateWorkoutMutation, UpdateWorkoutMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<UpdateWorkoutMutation, UpdateWorkoutMutationVariables>(
        UpdateWorkoutDocument,
        options
    );
}
export type UpdateWorkoutMutationHookResult = ReturnType<typeof useUpdateWorkoutMutation>;
export type UpdateWorkoutMutationResult = Apollo.MutationResult<UpdateWorkoutMutation>;
export type UpdateWorkoutMutationOptions = Apollo.BaseMutationOptions<
    UpdateWorkoutMutation,
    UpdateWorkoutMutationVariables
>;
export const GetCurrentUserDocument = gql`
    query getCurrentUser {
        me {
            id
            first_name
            last_name
        }
    }
`;

/**
 * __useGetCurrentUserQuery__
 *
 * To run a query within a React component, call `useGetCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentUserQuery(
    baseOptions?: Apollo.QueryHookOptions<GetCurrentUserQuery, GetCurrentUserQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GetCurrentUserQuery, GetCurrentUserQueryVariables>(
        GetCurrentUserDocument,
        options
    );
}
export function useGetCurrentUserLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GetCurrentUserQuery, GetCurrentUserQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GetCurrentUserQuery, GetCurrentUserQueryVariables>(
        GetCurrentUserDocument,
        options
    );
}
export function useGetCurrentUserSuspenseQuery(
    baseOptions?: Apollo.SuspenseQueryHookOptions<GetCurrentUserQuery, GetCurrentUserQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<GetCurrentUserQuery, GetCurrentUserQueryVariables>(
        GetCurrentUserDocument,
        options
    );
}
export type GetCurrentUserQueryHookResult = ReturnType<typeof useGetCurrentUserQuery>;
export type GetCurrentUserLazyQueryHookResult = ReturnType<typeof useGetCurrentUserLazyQuery>;
export type GetCurrentUserSuspenseQueryHookResult = ReturnType<
    typeof useGetCurrentUserSuspenseQuery
>;
export type GetCurrentUserQueryResult = Apollo.QueryResult<
    GetCurrentUserQuery,
    GetCurrentUserQueryVariables
>;
export const GetDashboardDocument = gql`
    query getDashboard($dashboardId: ID!) {
        dashboard(dashboardId: $dashboardId) {
            id
            name
            description
            created_at
            updated_at
            widgets {
                id
                name
                config
            }
        }
    }
`;

/**
 * __useGetDashboardQuery__
 *
 * To run a query within a React component, call `useGetDashboardQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDashboardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDashboardQuery({
 *   variables: {
 *      dashboardId: // value for 'dashboardId'
 *   },
 * });
 */
export function useGetDashboardQuery(
    baseOptions: Apollo.QueryHookOptions<GetDashboardQuery, GetDashboardQueryVariables> &
        ({ variables: GetDashboardQueryVariables; skip?: boolean } | { skip: boolean })
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GetDashboardQuery, GetDashboardQueryVariables>(
        GetDashboardDocument,
        options
    );
}
export function useGetDashboardLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GetDashboardQuery, GetDashboardQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GetDashboardQuery, GetDashboardQueryVariables>(
        GetDashboardDocument,
        options
    );
}
export function useGetDashboardSuspenseQuery(
    baseOptions?: Apollo.SuspenseQueryHookOptions<GetDashboardQuery, GetDashboardQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<GetDashboardQuery, GetDashboardQueryVariables>(
        GetDashboardDocument,
        options
    );
}
export type GetDashboardQueryHookResult = ReturnType<typeof useGetDashboardQuery>;
export type GetDashboardLazyQueryHookResult = ReturnType<typeof useGetDashboardLazyQuery>;
export type GetDashboardSuspenseQueryHookResult = ReturnType<typeof useGetDashboardSuspenseQuery>;
export type GetDashboardQueryResult = Apollo.QueryResult<
    GetDashboardQuery,
    GetDashboardQueryVariables
>;
export const GetDashboardsDocument = gql`
    query getDashboards($offset: Int, $limit: Int) {
        dashboards(offset: $offset, limit: $limit) {
            id
            name
            created_at
            updated_at
        }
    }
`;

/**
 * __useGetDashboardsQuery__
 *
 * To run a query within a React component, call `useGetDashboardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDashboardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDashboardsQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetDashboardsQuery(
    baseOptions?: Apollo.QueryHookOptions<GetDashboardsQuery, GetDashboardsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GetDashboardsQuery, GetDashboardsQueryVariables>(
        GetDashboardsDocument,
        options
    );
}
export function useGetDashboardsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GetDashboardsQuery, GetDashboardsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GetDashboardsQuery, GetDashboardsQueryVariables>(
        GetDashboardsDocument,
        options
    );
}
export function useGetDashboardsSuspenseQuery(
    baseOptions?: Apollo.SuspenseQueryHookOptions<GetDashboardsQuery, GetDashboardsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<GetDashboardsQuery, GetDashboardsQueryVariables>(
        GetDashboardsDocument,
        options
    );
}
export type GetDashboardsQueryHookResult = ReturnType<typeof useGetDashboardsQuery>;
export type GetDashboardsLazyQueryHookResult = ReturnType<typeof useGetDashboardsLazyQuery>;
export type GetDashboardsSuspenseQueryHookResult = ReturnType<typeof useGetDashboardsSuspenseQuery>;
export type GetDashboardsQueryResult = Apollo.QueryResult<
    GetDashboardsQuery,
    GetDashboardsQueryVariables
>;
export const GetExerciseDocument = gql`
    query getExercise($exerciseId: ID!) {
        exercise(exerciseId: $exerciseId) {
            id
            name
            description
            created_at
            updated_at
            workouts {
                id
                name
            }
        }
    }
`;

/**
 * __useGetExerciseQuery__
 *
 * To run a query within a React component, call `useGetExerciseQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExerciseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExerciseQuery({
 *   variables: {
 *      exerciseId: // value for 'exerciseId'
 *   },
 * });
 */
export function useGetExerciseQuery(
    baseOptions: Apollo.QueryHookOptions<GetExerciseQuery, GetExerciseQueryVariables> &
        ({ variables: GetExerciseQueryVariables; skip?: boolean } | { skip: boolean })
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GetExerciseQuery, GetExerciseQueryVariables>(
        GetExerciseDocument,
        options
    );
}
export function useGetExerciseLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GetExerciseQuery, GetExerciseQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GetExerciseQuery, GetExerciseQueryVariables>(
        GetExerciseDocument,
        options
    );
}
export function useGetExerciseSuspenseQuery(
    baseOptions?: Apollo.SuspenseQueryHookOptions<GetExerciseQuery, GetExerciseQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<GetExerciseQuery, GetExerciseQueryVariables>(
        GetExerciseDocument,
        options
    );
}
export type GetExerciseQueryHookResult = ReturnType<typeof useGetExerciseQuery>;
export type GetExerciseLazyQueryHookResult = ReturnType<typeof useGetExerciseLazyQuery>;
export type GetExerciseSuspenseQueryHookResult = ReturnType<typeof useGetExerciseSuspenseQuery>;
export type GetExerciseQueryResult = Apollo.QueryResult<
    GetExerciseQuery,
    GetExerciseQueryVariables
>;
export const GetExercisesDocument = gql`
    query getExercises($offset: Int, $limit: Int) {
        exercises(offset: $offset, limit: $limit) {
            id
            name
            created_at
            updated_at
        }
    }
`;

/**
 * __useGetExercisesQuery__
 *
 * To run a query within a React component, call `useGetExercisesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExercisesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExercisesQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetExercisesQuery(
    baseOptions?: Apollo.QueryHookOptions<GetExercisesQuery, GetExercisesQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GetExercisesQuery, GetExercisesQueryVariables>(
        GetExercisesDocument,
        options
    );
}
export function useGetExercisesLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GetExercisesQuery, GetExercisesQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GetExercisesQuery, GetExercisesQueryVariables>(
        GetExercisesDocument,
        options
    );
}
export function useGetExercisesSuspenseQuery(
    baseOptions?: Apollo.SuspenseQueryHookOptions<GetExercisesQuery, GetExercisesQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<GetExercisesQuery, GetExercisesQueryVariables>(
        GetExercisesDocument,
        options
    );
}
export type GetExercisesQueryHookResult = ReturnType<typeof useGetExercisesQuery>;
export type GetExercisesLazyQueryHookResult = ReturnType<typeof useGetExercisesLazyQuery>;
export type GetExercisesSuspenseQueryHookResult = ReturnType<typeof useGetExercisesSuspenseQuery>;
export type GetExercisesQueryResult = Apollo.QueryResult<
    GetExercisesQuery,
    GetExercisesQueryVariables
>;
export const GetGoalDocument = gql`
    query getGoal($goalId: ID!) {
        goal(goalId: $goalId) {
            id
            calories
            carbohydrates
            date
            fat
            fiber
            protein
            created_at
            updated_at
        }
    }
`;

/**
 * __useGetGoalQuery__
 *
 * To run a query within a React component, call `useGetGoalQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGoalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGoalQuery({
 *   variables: {
 *      goalId: // value for 'goalId'
 *   },
 * });
 */
export function useGetGoalQuery(
    baseOptions: Apollo.QueryHookOptions<GetGoalQuery, GetGoalQueryVariables> &
        ({ variables: GetGoalQueryVariables; skip?: boolean } | { skip: boolean })
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GetGoalQuery, GetGoalQueryVariables>(GetGoalDocument, options);
}
export function useGetGoalLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GetGoalQuery, GetGoalQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GetGoalQuery, GetGoalQueryVariables>(GetGoalDocument, options);
}
export function useGetGoalSuspenseQuery(
    baseOptions?: Apollo.SuspenseQueryHookOptions<GetGoalQuery, GetGoalQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<GetGoalQuery, GetGoalQueryVariables>(GetGoalDocument, options);
}
export type GetGoalQueryHookResult = ReturnType<typeof useGetGoalQuery>;
export type GetGoalLazyQueryHookResult = ReturnType<typeof useGetGoalLazyQuery>;
export type GetGoalSuspenseQueryHookResult = ReturnType<typeof useGetGoalSuspenseQuery>;
export type GetGoalQueryResult = Apollo.QueryResult<GetGoalQuery, GetGoalQueryVariables>;
export const GetLeadDocument = gql`
    query getLead($leadId: ID!) {
        lead(leadId: $leadId) {
            id
            activity_level
            date_of_birth
            email
            first_name
            last_name
            gender
            goal
            height_cm
            weight_kg
            bmr
            id
            status
            created_at
            updated_at
        }
    }
`;

/**
 * __useGetLeadQuery__
 *
 * To run a query within a React component, call `useGetLeadQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLeadQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLeadQuery({
 *   variables: {
 *      leadId: // value for 'leadId'
 *   },
 * });
 */
export function useGetLeadQuery(
    baseOptions: Apollo.QueryHookOptions<GetLeadQuery, GetLeadQueryVariables> &
        ({ variables: GetLeadQueryVariables; skip?: boolean } | { skip: boolean })
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GetLeadQuery, GetLeadQueryVariables>(GetLeadDocument, options);
}
export function useGetLeadLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GetLeadQuery, GetLeadQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GetLeadQuery, GetLeadQueryVariables>(GetLeadDocument, options);
}
export function useGetLeadSuspenseQuery(
    baseOptions?: Apollo.SuspenseQueryHookOptions<GetLeadQuery, GetLeadQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<GetLeadQuery, GetLeadQueryVariables>(GetLeadDocument, options);
}
export type GetLeadQueryHookResult = ReturnType<typeof useGetLeadQuery>;
export type GetLeadLazyQueryHookResult = ReturnType<typeof useGetLeadLazyQuery>;
export type GetLeadSuspenseQueryHookResult = ReturnType<typeof useGetLeadSuspenseQuery>;
export type GetLeadQueryResult = Apollo.QueryResult<GetLeadQuery, GetLeadQueryVariables>;
export const GetLeadsDocument = gql`
    query getLeads {
        leads {
            id
            email
            first_name
            last_name
            status
        }
    }
`;

/**
 * __useGetLeadsQuery__
 *
 * To run a query within a React component, call `useGetLeadsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLeadsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLeadsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLeadsQuery(
    baseOptions?: Apollo.QueryHookOptions<GetLeadsQuery, GetLeadsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GetLeadsQuery, GetLeadsQueryVariables>(GetLeadsDocument, options);
}
export function useGetLeadsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GetLeadsQuery, GetLeadsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GetLeadsQuery, GetLeadsQueryVariables>(GetLeadsDocument, options);
}
export function useGetLeadsSuspenseQuery(
    baseOptions?: Apollo.SuspenseQueryHookOptions<GetLeadsQuery, GetLeadsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<GetLeadsQuery, GetLeadsQueryVariables>(
        GetLeadsDocument,
        options
    );
}
export type GetLeadsQueryHookResult = ReturnType<typeof useGetLeadsQuery>;
export type GetLeadsLazyQueryHookResult = ReturnType<typeof useGetLeadsLazyQuery>;
export type GetLeadsSuspenseQueryHookResult = ReturnType<typeof useGetLeadsSuspenseQuery>;
export type GetLeadsQueryResult = Apollo.QueryResult<GetLeadsQuery, GetLeadsQueryVariables>;
export const GetProgrammeDocument = gql`
    query getProgramme($programmeId: ID!) {
        programme(programmeId: $programmeId) {
            id
            name
            description
            start_date
            end_date
            created_at
            updated_at
            workouts {
                id
                name
                description
            }
        }
    }
`;

/**
 * __useGetProgrammeQuery__
 *
 * To run a query within a React component, call `useGetProgrammeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProgrammeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProgrammeQuery({
 *   variables: {
 *      programmeId: // value for 'programmeId'
 *   },
 * });
 */
export function useGetProgrammeQuery(
    baseOptions: Apollo.QueryHookOptions<GetProgrammeQuery, GetProgrammeQueryVariables> &
        ({ variables: GetProgrammeQueryVariables; skip?: boolean } | { skip: boolean })
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GetProgrammeQuery, GetProgrammeQueryVariables>(
        GetProgrammeDocument,
        options
    );
}
export function useGetProgrammeLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GetProgrammeQuery, GetProgrammeQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GetProgrammeQuery, GetProgrammeQueryVariables>(
        GetProgrammeDocument,
        options
    );
}
export function useGetProgrammeSuspenseQuery(
    baseOptions?: Apollo.SuspenseQueryHookOptions<GetProgrammeQuery, GetProgrammeQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<GetProgrammeQuery, GetProgrammeQueryVariables>(
        GetProgrammeDocument,
        options
    );
}
export type GetProgrammeQueryHookResult = ReturnType<typeof useGetProgrammeQuery>;
export type GetProgrammeLazyQueryHookResult = ReturnType<typeof useGetProgrammeLazyQuery>;
export type GetProgrammeSuspenseQueryHookResult = ReturnType<typeof useGetProgrammeSuspenseQuery>;
export type GetProgrammeQueryResult = Apollo.QueryResult<
    GetProgrammeQuery,
    GetProgrammeQueryVariables
>;
export const GetUserDocument = gql`
    query getUser(
        $userId: ID!
        $includeActivities: Boolean = false
        $includeGoals: Boolean = false
        $includeNutrition: Boolean = false
        $includeProgrammes: Boolean = false
    ) {
        user(userId: $userId) {
            id
            activity_level
            avatar
            date_of_birth
            email
            first_name
            last_name
            gender
            created_at
            updated_at
            activities @include(if: $includeActivities) {
                id
                created_at
            }
            goals @include(if: $includeGoals) {
                id
                calories
                carbohydrates
                date
                fat
                fiber
                protein
                created_at
                updated_at
            }
            nutrition @include(if: $includeNutrition) {
                id
                date
                protein
                carbohydrates
                fat
                fiber
                calories
            }
            programmes @include(if: $includeProgrammes) {
                id
                name
                start_date
                end_date
                status
                created_at
                updated_at
            }
        }
    }
`;

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      includeActivities: // value for 'includeActivities'
 *      includeGoals: // value for 'includeGoals'
 *      includeNutrition: // value for 'includeNutrition'
 *      includeProgrammes: // value for 'includeProgrammes'
 *   },
 * });
 */
export function useGetUserQuery(
    baseOptions: Apollo.QueryHookOptions<GetUserQuery, GetUserQueryVariables> &
        ({ variables: GetUserQueryVariables; skip?: boolean } | { skip: boolean })
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
}
export function useGetUserLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GetUserQuery, GetUserQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
}
export function useGetUserSuspenseQuery(
    baseOptions?: Apollo.SuspenseQueryHookOptions<GetUserQuery, GetUserQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
}
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserSuspenseQueryHookResult = ReturnType<typeof useGetUserSuspenseQuery>;
export type GetUserQueryResult = Apollo.QueryResult<GetUserQuery, GetUserQueryVariables>;
export const GetUsersDocument = gql`
    query getUsers($offset: Int, $limit: Int) {
        users(offset: $offset, limit: $limit) {
            id
            avatar
            first_name
            last_name
            created_at
            updated_at
        }
    }
`;

/**
 * __useGetUsersQuery__
 *
 * To run a query within a React component, call `useGetUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetUsersQuery(
    baseOptions?: Apollo.QueryHookOptions<GetUsersQuery, GetUsersQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
}
export function useGetUsersLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GetUsersQuery, GetUsersQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
}
export function useGetUsersSuspenseQuery(
    baseOptions?: Apollo.SuspenseQueryHookOptions<GetUsersQuery, GetUsersQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<GetUsersQuery, GetUsersQueryVariables>(
        GetUsersDocument,
        options
    );
}
export type GetUsersQueryHookResult = ReturnType<typeof useGetUsersQuery>;
export type GetUsersLazyQueryHookResult = ReturnType<typeof useGetUsersLazyQuery>;
export type GetUsersSuspenseQueryHookResult = ReturnType<typeof useGetUsersSuspenseQuery>;
export type GetUsersQueryResult = Apollo.QueryResult<GetUsersQuery, GetUsersQueryVariables>;
export const GetWorkoutDocument = gql`
    query getWorkout($workoutId: ID!) {
        workout(workoutId: $workoutId) {
            id
            name
            description
            created_at
            updated_at
            exercises {
                id
                name
                pivot {
                    min_sets
                    max_sets
                    min_reps
                    max_reps
                    rest
                }
            }
        }
    }
`;

/**
 * __useGetWorkoutQuery__
 *
 * To run a query within a React component, call `useGetWorkoutQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkoutQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkoutQuery({
 *   variables: {
 *      workoutId: // value for 'workoutId'
 *   },
 * });
 */
export function useGetWorkoutQuery(
    baseOptions: Apollo.QueryHookOptions<GetWorkoutQuery, GetWorkoutQueryVariables> &
        ({ variables: GetWorkoutQueryVariables; skip?: boolean } | { skip: boolean })
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GetWorkoutQuery, GetWorkoutQueryVariables>(GetWorkoutDocument, options);
}
export function useGetWorkoutLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GetWorkoutQuery, GetWorkoutQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GetWorkoutQuery, GetWorkoutQueryVariables>(
        GetWorkoutDocument,
        options
    );
}
export function useGetWorkoutSuspenseQuery(
    baseOptions?: Apollo.SuspenseQueryHookOptions<GetWorkoutQuery, GetWorkoutQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<GetWorkoutQuery, GetWorkoutQueryVariables>(
        GetWorkoutDocument,
        options
    );
}
export type GetWorkoutQueryHookResult = ReturnType<typeof useGetWorkoutQuery>;
export type GetWorkoutLazyQueryHookResult = ReturnType<typeof useGetWorkoutLazyQuery>;
export type GetWorkoutSuspenseQueryHookResult = ReturnType<typeof useGetWorkoutSuspenseQuery>;
export type GetWorkoutQueryResult = Apollo.QueryResult<GetWorkoutQuery, GetWorkoutQueryVariables>;
export const GetWorkoutsDocument = gql`
    query getWorkouts($offset: Int, $limit: Int) {
        workouts(offset: $offset, limit: $limit) {
            id
            name
            created_at
            updated_at
        }
    }
`;

/**
 * __useGetWorkoutsQuery__
 *
 * To run a query within a React component, call `useGetWorkoutsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkoutsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkoutsQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetWorkoutsQuery(
    baseOptions?: Apollo.QueryHookOptions<GetWorkoutsQuery, GetWorkoutsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GetWorkoutsQuery, GetWorkoutsQueryVariables>(
        GetWorkoutsDocument,
        options
    );
}
export function useGetWorkoutsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GetWorkoutsQuery, GetWorkoutsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GetWorkoutsQuery, GetWorkoutsQueryVariables>(
        GetWorkoutsDocument,
        options
    );
}
export function useGetWorkoutsSuspenseQuery(
    baseOptions?: Apollo.SuspenseQueryHookOptions<GetWorkoutsQuery, GetWorkoutsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<GetWorkoutsQuery, GetWorkoutsQueryVariables>(
        GetWorkoutsDocument,
        options
    );
}
export type GetWorkoutsQueryHookResult = ReturnType<typeof useGetWorkoutsQuery>;
export type GetWorkoutsLazyQueryHookResult = ReturnType<typeof useGetWorkoutsLazyQuery>;
export type GetWorkoutsSuspenseQueryHookResult = ReturnType<typeof useGetWorkoutsSuspenseQuery>;
export type GetWorkoutsQueryResult = Apollo.QueryResult<
    GetWorkoutsQuery,
    GetWorkoutsQueryVariables
>;
