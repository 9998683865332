import React, { useEffect, useState } from 'react';
import type { WorkoutsTableData } from 'components/organisms';
import { WorkoutsPage } from 'components/pages';
import { useGetWorkoutsQuery } from 'graphql/generated';

export interface WorkoutsProps {}

const Workouts: React.FC<WorkoutsProps> = () => {
    const { data, fetchMore } = useGetWorkoutsQuery({ variables: { offset: 0, limit: 25 } });

    const [workoutsTableData, setWorkoutsTableData] = useState<WorkoutsTableData[]>([]);

    useEffect(() => {
        setWorkoutsTableData(
            data?.workouts?.map(workout => ({
                id: workout.id,
                name: workout.name,
                createdAt: workout.created_at,
                updatedAt: workout.updated_at || ''
            })) || []
        );
    }, [data]);

    const next = (): void => {
        if (data?.workouts) fetchMore({ variables: { offset: data.workouts.length } });
    };

    return <WorkoutsPage {...{ workoutsTableData, next }} />;
};

export default Workouts;
