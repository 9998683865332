import React from 'react';
import ReactDOM from 'react-dom/client';
import '@bulldogbarbells/design-system-admin-styles/dist/css/styles.css';
import 'react-toastify/dist/ReactToastify.css';
import { ApolloProvider } from '@apollo/client';
import App from 'App';
import client from 'client';
import { CookieBanner } from 'components/organisms';
import { AuthProvider } from 'contexts';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import reportWebVitals from './reportWebVitals';
import store from './store';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
    <React.StrictMode>
        <BrowserRouter>
            <ReduxProvider store={store}>
                <ApolloProvider client={client}>
                    <AuthProvider>
                        <App />
                        <ToastContainer />
                    </AuthProvider>
                </ApolloProvider>
            </ReduxProvider>
        </BrowserRouter>
        <CookieBanner />
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
