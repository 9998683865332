import React from 'react';
import { ArcElement, ChartData, Chart as ChartJS, Legend, Tooltip } from 'chart.js';
import { Pie } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

interface PieChartProps {
    data: ChartData<'pie', number[], unknown>;
}

const PieChart: React.FC<PieChartProps> = ({ data }) => <Pie data={data} />;

export default PieChart;
