import React from 'react';
import type {
    ProgrammeFormSubmitFunction,
    ProgrammeFormValues,
    WorkoutsFormHelpers,
    WorkoutsFormValues
} from 'components/organisms';
import { ProgrammeForm, WorkoutsForm } from 'components/organisms';
import { DefaultTemplate } from 'components/templates';
import { Button, Card, CardBody, CardHeader, Container } from 'reactstrap';

export interface UpdateProgrammePageProps {
    initialProgrammeFormValues: ProgrammeFormValues;
    initialWorkoutsFormValues: WorkoutsFormValues;
    onDeleteButtonClick: () => void;
    onProgrammeFormSubmit: ProgrammeFormSubmitFunction;
    onWorkoutsFormSubmit: (values: WorkoutsFormValues, helpers: WorkoutsFormHelpers) => void;
    promiseOptions: (inputValue: string) => Promise<any[]>;
}

const UpdateProgrammePage: React.FC<UpdateProgrammePageProps> = ({
    initialProgrammeFormValues,
    initialWorkoutsFormValues,
    onDeleteButtonClick,
    onProgrammeFormSubmit,
    onWorkoutsFormSubmit,
    promiseOptions
}) => (
    <DefaultTemplate title="Update Programme">
        <Container className="mt-n10">
            <Card className="mb-4">
                <CardHeader tag="h5">Details</CardHeader>
                <CardBody>
                    <ProgrammeForm
                        initialValues={initialProgrammeFormValues}
                        onSubmit={onProgrammeFormSubmit}
                    />
                </CardBody>
            </Card>
            <Card className="mb-4">
                <CardHeader tag="h5">Workouts</CardHeader>
                <CardBody>
                    <WorkoutsForm
                        initialValues={initialWorkoutsFormValues}
                        onSubmit={onWorkoutsFormSubmit}
                        promiseOptions={promiseOptions}
                    />
                </CardBody>
            </Card>
            <Card className="mb-4">
                <CardHeader tag="h5">Delete Programme</CardHeader>
                <CardBody>
                    <p className="fw-bold text-danger">Deleted programmes cannot be restored!</p>
                    <Button type="button" color="danger" onClick={onDeleteButtonClick}>
                        Delete Programme
                    </Button>
                </CardBody>
            </Card>
        </Container>
    </DefaultTemplate>
);

export default UpdateProgrammePage;
