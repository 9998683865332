import React from 'react';
import { Link } from 'react-router-dom';
import { ListGroup, ListGroupItem, ListGroupProps } from 'reactstrap';

export type WorkoutsListItem = {
    id: string;
    name: string;
};

export interface WorkoutsListProps extends ListGroupProps {
    items: WorkoutsListItem[];
}

const WorkoutsList: React.FC<WorkoutsListProps> = ({ items, ...props }) => (
    <ListGroup {...props}>
        {items.map((workout, index) => (
            <ListGroupItem key={index} tag={Link} to={`/workouts/${workout.id}`} action>
                {workout.name}
            </ListGroupItem>
        ))}
    </ListGroup>
);

export default WorkoutsList;
