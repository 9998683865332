import React from 'react';
import type { LeadFormSubmitFunction, LeadFormValues } from 'components/organisms';
import { CreateLeadPage } from 'components/pages';
import { ActivityLevel, Gender, useCreateLeadMutation } from 'graphql/generated';

export interface CreateLeadProps {}

const CreateLead: React.FC<CreateLeadProps> = () => {
    const [createLead] = useCreateLeadMutation();

    const initialLeadFormValues: LeadFormValues = {
        activityLevel: ActivityLevel.Sedentary,
        dateOfBirth: '',
        email: '',
        firstName: '',
        lastName: '',
        gender: Gender.Male,
        goal: 'loss',
        height: 0,
        weight: 0,
        status: 'new'
    };

    const onLeadFormSubmit: LeadFormSubmitFunction = (values, helpers) => {
        createLead({
            variables: {
                activityLevel: values.activityLevel,
                dateOfBirth: values.dateOfBirth,
                email: values.email,
                firstName: values.firstName,
                lastName: values.lastName,
                gender: values.gender,
                goal: values.goal,
                height: values.height,
                weight: values.weight,
                bmr: 0,
                tdee: 0,
                status: values.status
            },
            onCompleted: () => {
                helpers.resetForm();
            }
        });
    };

    return <CreateLeadPage {...{ initialLeadFormValues, onLeadFormSubmit }} />;
};

export default CreateLead;
