import React from 'react';
import { Button, InfiniteScroll } from 'components/molecules';
import Moment from 'react-moment';
import { Link } from 'react-router-dom';
import type { TableProps } from 'reactstrap';
import { Table } from 'reactstrap';

export type WorkoutsTableData = {
    id: string;
    name: string;
    createdAt: string;
    updatedAt: string;
};

export interface WorkoutsTableProps extends TableProps {
    data: WorkoutsTableData[];
    next: () => void;
}

const WorkoutsTable: React.FC<WorkoutsTableProps> = ({ data, next, ...props }) => (
    <InfiniteScroll dataLength={data.length} next={next} hasMore>
        <Table {...props}>
            <thead>
                <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Created</th>
                    <th scope="col">Updated</th>
                    <th scope="col"></th>
                </tr>
            </thead>
            <tbody>
                {data.map((workout, index) => (
                    <tr key={index}>
                        <td>
                            <Link to={`/workouts/${workout.id}`}>{workout.name}</Link>
                        </td>
                        <td>
                            <Moment fromNow>{workout.createdAt}</Moment>
                        </td>
                        <td>
                            <Moment fromNow>{workout.updatedAt}</Moment>
                        </td>
                        <td>
                            <Button.Edit
                                tag={Link}
                                to={`/workouts/${workout.id}/update`}
                                color="primary"
                                role="button"
                                size="sm"
                                block
                            />
                        </td>
                    </tr>
                ))}
            </tbody>
        </Table>
    </InfiniteScroll>
);

export default WorkoutsTable;
