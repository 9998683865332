import React from 'react';
import { ArcElement, ChartData, Chart as ChartJS, Legend, Tooltip } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

interface DoughnutChartProps {
    data: ChartData<'doughnut', number[], unknown>;
}

const DoughnutChart: React.FC<DoughnutChartProps> = ({ data }) => <Doughnut data={data} />;

export default DoughnutChart;
