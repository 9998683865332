import React from 'react';
import { Button, Field, Form } from 'components/molecules';
import GoalFormSchema from './GoalForm.schema';
import type { GoalFormSubmitFunction, GoalFormValues } from './GoalForm.types';

interface GoalFormProps {
    initialValues: GoalFormValues;
    onSubmit: GoalFormSubmitFunction;
}

const GoalForm: React.FC<GoalFormProps> = ({ initialValues, onSubmit }) => (
    <Form initialValues={initialValues} validationSchema={GoalFormSchema} onSubmit={onSubmit}>
        <Field.Carbohydrates />
        <Field.Fat />
        <Field.Protein />
        <Field.Fiber />
        <Field.Calories />
        <Field.Date />
        <Button.Save />
    </Form>
);

export default GoalForm;
