import React from 'react';
import { Button } from 'components/molecules';
import type { GoalFormSubmitFunction, GoalFormValues } from 'components/organisms';
import { GoalForm, GoalsTable } from 'components/organisms';
import moment from 'moment';
import { Card, CardHeader, Modal, ModalBody, ModalHeader } from 'reactstrap';

interface UserGoalsPageProps {
    goals: {
        id: string;
        date: string;
        calories: number;
        carbohydrates: number;
        fat: number;
        fiber: number;
        protein: number;
        createdAt: string;
        updatedAt: string;
    }[];
    handleCreateGoal: (
        values: {
            calories: number;
            carbohydrates: number;
            date: string;
            fat: number;
            fiber: number;
            protein: number;
        },
        onSuccess: () => void
    ) => void;
}

const UserGoalsPage: React.FC<UserGoalsPageProps> = ({ goals, handleCreateGoal }) => {
    const [modal, setModal] = React.useState(false);

    const initialValues: GoalFormValues = {
        calories: 0,
        carbohydrates: 0,
        date: moment().format('YYYY-MM-DD'),
        fat: 0,
        fiber: 0,
        protein: 0
    };

    const onSubmit: GoalFormSubmitFunction = (
        { calories, carbohydrates, date, fat, fiber, protein },
        helpers
    ): void => {
        handleCreateGoal({ calories, carbohydrates, date, fat, fiber, protein }, () => {
            setModal(false);
            helpers.resetForm();
        });
    };

    const toggle = () => setModal(!modal);

    return (
        <React.Fragment>
            <Card className="card-header-actions mb-4">
                <CardHeader tag="h5">
                    Goals
                    <Button.Create color="primary" size="sm" onClick={toggle} />
                </CardHeader>
                <GoalsTable data={goals} />
            </Card>
            <Modal isOpen={modal} toggle={toggle} centered>
                <ModalHeader toggle={toggle}>Goal</ModalHeader>
                <ModalBody>
                    <GoalForm initialValues={initialValues} onSubmit={onSubmit} />
                </ModalBody>
            </Modal>
        </React.Fragment>
    );
};

export default UserGoalsPage;
