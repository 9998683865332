import React from 'react';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome';

export interface RemoveIconProps extends Omit<FontAwesomeIconProps, 'icon'> {}

const RemoveIcon: React.FC<RemoveIconProps> = ({ ...props }) => (
    <FontAwesomeIcon icon={faXmark} {...props} />
);

export default RemoveIcon;
