import {
    COLUMN,
    COLUMNS,
    DATE_RANGE,
    DATE_RANGES,
    FREQUENCIES,
    FREQUENCY,
    METRICS,
    WIDGET,
    WIDGETS
} from '.';

const labels = {
    // Columns
    [COLUMN]: 'Column',
    [COLUMNS.ARMS]: 'Arms',
    [COLUMNS.CHEST]: 'Chest',
    [COLUMNS.HEIGHT]: 'Height',
    [COLUMNS.HIPS]: 'Hips',
    [COLUMNS.NECK]: 'Neck',
    [COLUMNS.REPS]: 'Reps',
    [COLUMNS.THIGHS]: 'Thighs',
    [COLUMNS.WAIST]: 'Waist',
    [COLUMNS.WEIGHT]: 'Weight',

    // Date Ranges
    [DATE_RANGE]: 'Date Range',
    [DATE_RANGES.LAST_7_DAYS]: 'Last 7 days',
    [DATE_RANGES.LAST_14_DAYS]: 'Last 14 days',
    [DATE_RANGES.LAST_30_DAYS]: 'Last 30 days',
    [DATE_RANGES.LAST_60_DAYS]: 'Last 60 days',
    [DATE_RANGES.LAST_90_DAYS]: 'Last 90 days',
    [DATE_RANGES.LAST_6_MONTHS]: 'Last 6 months',
    [DATE_RANGES.LAST_1_YEAR]: 'Last 12 months',
    [DATE_RANGES.LAST_2_YEARS]: 'Last 2 years',
    [DATE_RANGES.LAST_5_YEARS]: 'Last 5 years',

    // Frequencies
    [FREQUENCY]: 'Frequency',
    [FREQUENCIES.DAILY]: 'Daily',
    [FREQUENCIES.WEEKLY]: 'Weekly',
    [FREQUENCIES.MONTHLY]: 'Monthly',

    // Metrics
    [METRICS.MEASUREMENTS]: 'Measurements',
    [METRICS.PROGRESSIVE_OVERLOAD]: 'Progressive Overload',

    // Widgets
    [WIDGET]: 'Widget',
    [WIDGETS.LINE_CHART]: 'Line Chart',
    [WIDGETS.TABLE]: 'Table'
};

export default labels;
