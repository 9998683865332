import ActivityLevelField from './ActivityLevelField';
import CaloriesField from './CaloriesField';
import CarbohydratesField from './CarbohydratesField';
import DateField from './DateField';
import DateOfBirthField from './DateOfBirthField';
import DescriptionField from './DescriptionField';
import EmailField from './EmailField';
import EndDateField from './EndDateField';
import FatField from './FatField';
import FiberField from './FiberField';
import FirstNameField from './FirstNameField';
import GenderField from './GenderField';
import GoalField from './GoalField';
import HeightField from './HeightField';
import LastNameField from './LastNameField';
import ProteinField from './ProteinField';
import NameField from './NameField';
import StartDateField from './StartDateField';
import StatusField from './StatusField';
import WeightField from './WeightField';

export * from './ActivityLevelField';
export * from './CaloriesField';
export * from './CarbohydratesField';
export * from './DateField';
export * from './DateOfBirthField';
export * from './DescriptionField';
export * from './EmailField';
export * from './EndDateField';
export * from './FatField';
export * from './FiberField';
export * from './FirstNameField';
export * from './GenderField';
export * from './GoalField';
export * from './HeightField';
export * from './LastNameField';
export * from './ProteinField';
export * from './NameField';
export * from './StartDateField';
export * from './StatusField';
export * from './WeightField';

const Field = {
    ActivityLevel: ActivityLevelField,
    Calories: CaloriesField,
    Carbohydrates: CarbohydratesField,
    Date: DateField,
    DateOfBirth: DateOfBirthField,
    Description: DescriptionField,
    Email: EmailField,
    EndDate: EndDateField,
    Fat: FatField,
    Fiber: FiberField,
    FirstName: FirstNameField,
    Gender: GenderField,
    Goal: GoalField,
    Height: HeightField,
    LastName: LastNameField,
    Name: NameField,
    Protein: ProteinField,
    StartDate: StartDateField,
    Status: StatusField,
    Weight: WeightField
};

export default Field;
