import React from 'react';
import { Icon } from 'components/atoms';
import { SelectField } from 'components/molecules';
import { FieldArray, Formik, FormikHelpers } from 'formik';
import { Button, Form, Input, Table } from 'reactstrap';
import { z } from 'zod';

export const ExercisesFormSchema = z.object({
    exercises: z.array(
        z.object({
            id: z.string(),
            name: z.string(),
            minSets: z.number(),
            maxSets: z.number(),
            minReps: z.number(),
            maxReps: z.number(),
            rest: z.number()
        })
    )
});

export type ExercisesFormValues = z.infer<typeof ExercisesFormSchema>;

export type ExercisesFormHelpers = FormikHelpers<ExercisesFormValues>;

export interface ExercisesFormProps {
    initialValues: ExercisesFormValues;
    loadOptions: (inputValue: string) => Promise<{ value: string; label: string }[]>;
    onSubmit: (values: ExercisesFormValues, helpers: ExercisesFormHelpers) => void;
}

const ExercisesForm: React.FC<ExercisesFormProps> = ({ loadOptions, ...props }) => (
    <Formik {...props}>
        {form => (
            <Form onSubmit={form.handleSubmit}>
                <FieldArray name="exercises">
                    {arrayHelpers => {
                        const promiseOptions = async (inputValue: string) => {
                            const options = await loadOptions(inputValue);
                            return options.filter(
                                option =>
                                    !form.values.exercises.some(
                                        exercise => exercise.id === option.value
                                    )
                            );
                        };

                        const onChange = (option: any) => {
                            arrayHelpers.push({
                                id: option.value,
                                name: option.label,
                                minSets: 0,
                                maxSets: 0,
                                minReps: 0,
                                maxReps: 0,
                                rest: 0
                            });
                        };

                        return (
                            <React.Fragment>
                                <Table borderless striped>
                                    <thead>
                                        <tr>
                                            <th scope="col">Name</th>
                                            <th scope="col">Min Sets</th>
                                            <th scope="col">Max Sets</th>
                                            <th scope="col">Min Reps</th>
                                            <th scope="col">Max Reps</th>
                                            <th scope="col">Rest</th>
                                            <th scope="col"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {form.values.exercises.map((exercise, index) => (
                                            <tr key={index}>
                                                <td className="align-middle">{exercise.name}</td>
                                                <td>
                                                    <Input
                                                        type="number"
                                                        id={`exercises.${index}.minSets`}
                                                        name={`exercises.${index}.minSets`}
                                                        disabled={form.isSubmitting}
                                                        onBlur={form.handleBlur}
                                                        onChange={form.handleChange}
                                                        value={form.values.exercises[index].minSets}
                                                    />
                                                </td>
                                                <td>
                                                    <Input
                                                        type="number"
                                                        id={`exercises.${index}.maxSets`}
                                                        name={`exercises.${index}.maxSets`}
                                                        disabled={form.isSubmitting}
                                                        onBlur={form.handleBlur}
                                                        onChange={form.handleChange}
                                                        value={form.values.exercises[index].maxSets}
                                                    />
                                                </td>
                                                <td>
                                                    <Input
                                                        type="number"
                                                        id={`exercises.${index}.minReps`}
                                                        name={`exercises.${index}.minReps`}
                                                        disabled={form.isSubmitting}
                                                        onBlur={form.handleBlur}
                                                        onChange={form.handleChange}
                                                        value={form.values.exercises[index].minReps}
                                                    />
                                                </td>
                                                <td>
                                                    <Input
                                                        type="number"
                                                        id={`exercises.${index}.maxReps`}
                                                        name={`exercises.${index}.maxReps`}
                                                        disabled={form.isSubmitting}
                                                        onBlur={form.handleBlur}
                                                        onChange={form.handleChange}
                                                        value={form.values.exercises[index].maxReps}
                                                    />
                                                </td>
                                                <td>
                                                    <Input
                                                        type="number"
                                                        id={`exercises.${index}.rest`}
                                                        name={`exercises.${index}.rest`}
                                                        disabled={form.isSubmitting}
                                                        onBlur={form.handleBlur}
                                                        onChange={form.handleChange}
                                                        value={form.values.exercises[index].rest}
                                                    />
                                                </td>
                                                <td>
                                                    <Button
                                                        type="button"
                                                        color="danger"
                                                        disabled={form.isSubmitting}
                                                        onClick={() => arrayHelpers.remove(index)}>
                                                        <Icon.Remove />
                                                    </Button>
                                                </td>
                                            </tr>
                                        ))}
                                        <tr>
                                            <td className="align-middle fw-bold">Add Exercise</td>
                                            <td colSpan={5}>
                                                <SelectField
                                                    name="exercise"
                                                    loadOptions={promiseOptions}
                                                    onChange={onChange}
                                                />
                                            </td>
                                            <td></td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </React.Fragment>
                        );
                    }}
                </FieldArray>
                <Button type="submit" color="primary" disabled={form.isSubmitting}>
                    Save
                </Button>
            </Form>
        )}
    </Formik>
);

export default ExercisesForm;
