import React from 'react';
import { Header } from 'components/organisms';

export interface DefaultTemplateProps {
    children?: React.ReactNode;
    content?: React.ReactNode;
    subtitle?: string;
    title: string;
}

const DefaultTemplate: React.FC<DefaultTemplateProps> = ({
    children,
    content,
    subtitle,
    title
}) => (
    <React.Fragment>
        <Header title={title} subtitle={subtitle} overlapped>
            {content}
        </Header>
        {children}
    </React.Fragment>
);

export default DefaultTemplate;
