import { z } from 'zod';

const GoalFormSchema = z.object({
    calories: z.number().int().min(0, 'Calories must be greater than or equal to 0'),
    carbohydrates: z.number().int().min(0, 'Carbohydrates must be greater than or equal to 0'),
    date: z.string().regex(/^\d{4}-\d{2}-\d{2}$/, 'Invalid date format'),
    fat: z.number().int().min(0, 'Fat must be greater than or equal to 0'),
    fiber: z.number().int().min(0, 'Fiber must be greater than or equal to 0'),
    protein: z.number().int().min(0, 'Protein must be greater than or equal to 0')
});

export default GoalFormSchema;
