import React from 'react';
import { UserActivityPage } from 'components/pages';
import { useGetUserQuery } from 'graphql/generated';
import { useParams } from 'react-router-dom';

export interface UserActivityProps {}

const UserActivity: React.FC<UserActivityProps> = () => {
    const { userId } = useParams() as { userId: string };

    const { loading, error, data } = useGetUserQuery({
        variables: { userId, includeActivities: true }
    });

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error! {error.message}</div>;
    }

    if (!data) {
        return null;
    }

    const name = data.user.first_name + ' ' + data.user.last_name;

    const activities =
        data.user.activities?.map(activity => ({
            id: activity.id,
            name: '',
            createdAt: activity.created_at
        })) || [];

    return <UserActivityPage {...{ name, activities }} />;
};

export default UserActivity;
