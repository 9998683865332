import React from 'react';
import { Button } from 'components/molecules';
import { DefaultTemplate } from 'components/templates';
import { Link, Outlet } from 'react-router-dom';
import { Card, CardImg, Col, Container, ListGroup, ListGroupItem, Row } from 'reactstrap';

export type UserData = {
    id: string;
    firstName: string;
    lastName: string;
};

export interface UserPageProps {
    data: UserData;
}

const UserPage: React.FC<UserPageProps> = ({ data }) => (
    <DefaultTemplate
        title={`${data.firstName} ${data.lastName}`}
        content={<Button.Edit tag={Link} color="primary" to={`/users/${data.id}/update`} />}>
        <Container className="mt-n10">
            <Row>
                <Col lg="4" xl="3">
                    <Card className="mb-4">
                        <ListGroup flush>
                            <ListGroupItem
                                tag={Link}
                                className="p-3"
                                to={`/users/${data.id}`}
                                action>
                                Activity
                            </ListGroupItem>
                            <ListGroupItem
                                tag={Link}
                                className="p-3"
                                to={`/users/${data.id}/goals`}
                                action>
                                Goals
                            </ListGroupItem>
                            <ListGroupItem
                                tag={Link}
                                className="p-3"
                                to={`/users/${data.id}/nutrition`}
                                action>
                                Nutrition
                            </ListGroupItem>
                            <ListGroupItem
                                tag={Link}
                                className="p-3"
                                to={`/users/${data.id}/programmes`}
                                action>
                                Programmes
                            </ListGroupItem>
                        </ListGroup>
                    </Card>
                </Col>
                <Col lg="8" xl="9">
                    <Outlet />
                </Col>
            </Row>
        </Container>
    </DefaultTemplate>
);

export default UserPage;
