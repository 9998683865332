import React from 'react';
import { useField } from 'formik';
import { FormFeedback, FormGroup, Input, InputGroup, InputGroupText, Label } from 'reactstrap';

export interface ProteinFieldProps {}

const ProteinField: React.FC<ProteinFieldProps> = () => {
    const [field, meta] = useField('protein');

    return (
        <FormGroup>
            <Label for="protein">Protein</Label>
            <InputGroup>
                <Input
                    type="number"
                    id="protein"
                    invalid={!!meta.error && meta.touched}
                    {...field}
                />
                <InputGroupText>g</InputGroupText>
                <FormFeedback>{meta.error}</FormFeedback>
            </InputGroup>
        </FormGroup>
    );
};

export default ProteinField;
