function moveItemInArray<T>(array: T[], startIndex: number, endIndex: number): T[] {
    const result = [...array];
    const [removed] = result.splice(startIndex, 1);

    result.splice(endIndex, 0, removed);

    return result;
}

export default moveItemInArray;
