import React from 'react';
import { useField } from 'formik';
import { FormGroup, Label, Input, FormFeedback } from 'reactstrap';

export interface StatusFieldProps {}

const StatusField: React.FC<StatusFieldProps> = () => {
    const [field, meta] = useField('status');

    return (
        <FormGroup>
            <Label for="status">Status</Label>
            <Input type="select" id="status" invalid={!!meta.error && meta.touched} {...field}>
                <option value="new">New</option>
                <option value="inProgress">In Progress</option>
                <option value="converted">Converted</option>
                <option value="dead">Dead</option>
            </Input>
            <FormFeedback>{meta.error}</FormFeedback>
        </FormGroup>
    );
};

export default StatusField;
