import React from 'react';
import type { LeadFormSubmitFunction, LeadFormValues } from 'components/organisms';
import { NoMatchPage, UpdateLeadPage } from 'components/pages';
import { useDeleteLeadMutation, useGetLeadQuery, useUpdateLeadMutation } from 'graphql/generated';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { deleteRefFromCache } from 'utilities/helpers';

interface UpdateLeadProps {}

const UpdateLead: React.FC<UpdateLeadProps> = () => {
    const navigate = useNavigate();

    const { leadId } = useParams() as { leadId: string };

    const { data, loading, error } = useGetLeadQuery({ variables: { leadId } });

    const [deleteLead] = useDeleteLeadMutation();

    const [updateLead] = useUpdateLeadMutation();

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error</div>;
    }

    if (!data) {
        return <NoMatchPage />;
    }

    const initialLeadFormValues: LeadFormValues = {
        activityLevel: data.lead.activity_level,
        dateOfBirth: data.lead.date_of_birth,
        email: data.lead.email,
        firstName: data.lead.first_name,
        lastName: data.lead.last_name,
        gender: data.lead.gender,
        goal: data.lead.goal,
        height: data.lead.height_cm,
        weight: data.lead.weight_kg,
        status: data.lead.status
    };

    const onDeleteButtonClick = (): void => {
        deleteLead({
            variables: { leadId },
            onCompleted: () => {
                toast.success('Lead deleted successfully!');
                navigate('/leads');
            },
            onError: () => {
                toast.error('Failed to delete lead!');
            },
            update: cache => {
                deleteRefFromCache(cache, {
                    id: `Lead:${leadId}`,
                    fieldName: 'leads',
                    refId: leadId
                });
            }
        });
    };

    const onLeadFormSubmit: LeadFormSubmitFunction = (values): void => {
        updateLead({
            variables: {
                leadId,
                activityLevel: values.activityLevel,
                dateOfBirth: values.dateOfBirth,
                email: values.email,
                firstName: values.firstName,
                lastName: values.lastName,
                gender: values.gender,
                goal: values.goal,
                height: values.height,
                weight: values.weight,
                bmr: 0,
                tdee: 0,
                status: values.status
            },
            onCompleted: () => {
                toast.success('Lead updated successfully!');
            },
            onError: () => {
                toast.error('Failed to update lead!');
            }
        });
    };

    return (
        <UpdateLeadPage
            initialLeadFormValues={initialLeadFormValues}
            onDeleteButtonClick={onDeleteButtonClick}
            onLeadFormSubmit={onLeadFormSubmit}
        />
    );
};

export default UpdateLead;
