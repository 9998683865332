import React from 'react';
import {
    PageHeader,
    PageHeaderContent,
    PageHeaderSubtitle,
    PageHeaderTitle
} from '@typedbootstrap/admin-pro-components';
import classnames from 'classnames';
import { Col, Container, Row } from 'reactstrap';

export interface HeaderProps extends React.HTMLAttributes<HTMLElement> {
    overlapped?: boolean;
    subtitle?: string;
    title: string;
}

const Header: React.FC<HeaderProps> = ({ children, className, overlapped, subtitle, title }) => {
    const classNames = classnames({ 'pb-10': overlapped }, className);

    return (
        <PageHeader bg="primary" className={classNames} dark gradient>
            <Container>
                <PageHeaderContent className="pt-4">
                    <Row className="align-items-center justify-content-between">
                        <Col className="mt-4" xs="auto">
                            <PageHeaderTitle>{title}</PageHeaderTitle>
                            {subtitle && <PageHeaderSubtitle>{subtitle}</PageHeaderSubtitle>}
                        </Col>
                        {children && (
                            <Col className="mt-4" xs="12" xl="auto">
                                {children}
                            </Col>
                        )}
                    </Row>
                </PageHeaderContent>
            </Container>
        </PageHeader>
    );
};

export default Header;
