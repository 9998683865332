import React from 'react';
import { Field } from 'formik';
import AsyncSelect from 'react-select/async';
import { FormGroup, InputProps, Label } from 'reactstrap';

export interface SelectFieldProps extends InputProps {
    label?: string;
    loadOptions?: (inputValue: string) => Promise<any[]>;
}

const SelectField: React.FC<SelectFieldProps> = ({ label, ...props }) => {
    return (
        <FormGroup>
            {label && <Label>{label}</Label>}
            <Field as={AsyncSelect} {...props} />
        </FormGroup>
    );
};

export default SelectField;
