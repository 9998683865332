import React from 'react';
import type { GoalFormSubmitFunction, GoalFormValues } from 'components/organisms';
import { GoalForm } from 'components/organisms';
import { DefaultTemplate } from 'components/templates';
import { Card, CardBody, CardHeader, Container } from 'reactstrap';

export interface UpdateGoalPageProps {
    initialGoalFormValues: GoalFormValues;
    onGoalFormSubmit: GoalFormSubmitFunction;
}

const UpdateGoalPage: React.FC<UpdateGoalPageProps> = props => (
    <DefaultTemplate title="Update Goal">
        <Container className="mt-n10">
            <Card className="mb-4">
                <CardHeader tag="h5">Details</CardHeader>
                <CardBody>
                    <GoalForm
                        initialValues={props.initialGoalFormValues}
                        onSubmit={props.onGoalFormSubmit}
                    />
                </CardBody>
            </Card>
        </Container>
    </DefaultTemplate>
);

export default UpdateGoalPage;
