import React from 'react';
import type { UserFormSubmitFunction, UserFormValues } from 'components/organisms';
import { UserForm } from 'components/organisms';
import { DefaultTemplate } from 'components/templates';
import { Card, CardBody, CardHeader, Container } from 'reactstrap';

export interface CreateUserPageProps {
    initialUserFormValues: UserFormValues;
    onUserFormSubmit: UserFormSubmitFunction;
}

const CreateUserPage: React.FC<CreateUserPageProps> = props => (
    <DefaultTemplate title="Create User">
        <Container className="mt-n10">
            <Card className="mb-4">
                <CardHeader tag="h5">Details</CardHeader>
                <CardBody>
                    <UserForm
                        initialValues={props.initialUserFormValues}
                        onSubmit={props.onUserFormSubmit}
                    />
                </CardBody>
            </Card>
        </Container>
    </DefaultTemplate>
);

export default CreateUserPage;
