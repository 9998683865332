import React from 'react';
import { Button } from 'components/molecules';
import type { WorkoutsTableData } from 'components/organisms';
import { WorkoutsTable } from 'components/organisms';
import { DefaultTemplate } from 'components/templates';
import { Link } from 'react-router-dom';
import { Card, CardHeader, Container } from 'reactstrap';

export interface WorkoutsPageProps {
    workoutsTableData: WorkoutsTableData[];
    next: () => void;
}

const WorkoutsPage: React.FC<WorkoutsPageProps> = ({ workoutsTableData, next }) => (
    <DefaultTemplate
        title="Workouts"
        content={<Button.Create tag={Link} color="primary" to="/workouts/create" />}>
        <Container className="mt-n10">
            <Card className="mb-4">
                <CardHeader tag="h5">Workouts</CardHeader>
                <WorkoutsTable
                    className="mb-0"
                    data={workoutsTableData}
                    next={next}
                    borderless
                    responsive
                    striped
                />
            </Card>
        </Container>
    </DefaultTemplate>
);

export default WorkoutsPage;
