import React from 'react';
import { Button, Field, Form } from 'components/molecules';
import { Col, Row } from 'reactstrap';
import UserFormSchema from './UserForm.schema';
import type { UserFormSubmitFunction, UserFormValues } from './UserForm.types';

interface UserFormProps {
    initialValues: UserFormValues;
    onSubmit: UserFormSubmitFunction;
}

const UserForm: React.FC<UserFormProps> = ({ initialValues, onSubmit }) => (
    <Form initialValues={initialValues} onSubmit={onSubmit} validationSchema={UserFormSchema}>
        <Field.Email />
        <Row>
            <Col md="6">
                <Field.FirstName />
            </Col>
            <Col md="6">
                <Field.LastName />
            </Col>
        </Row>
        <Field.DateOfBirth />
        <Field.Gender />
        <Field.ActivityLevel />
        <Button.Save />
    </Form>
);

export default UserForm;
