import { ActivityLevel, Gender } from 'graphql/generated';
import { z } from 'zod';

const UserFormSchema = z.object({
    activityLevel: z.nativeEnum(ActivityLevel),
    dateOfBirth: z.string().date(),
    email: z.string().email(),
    firstName: z
        .string()
        .min(1, 'First name must be 1 or more characters long')
        .max(100, 'First name must be 100 or fewer characters long'),
    lastName: z
        .string()
        .min(1, 'Last name must be 1 or more characters long')
        .max(100, 'Last name must be 100 or fewer characters long'),
    gender: z.nativeEnum(Gender)
});

export default UserFormSchema;
