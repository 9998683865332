import React, { useMemo, useState } from 'react';
import { faCircle, faCircleDot } from '@fortawesome/free-regular-svg-icons';
import { faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'components/molecules';
import { Formik, FormikHelpers } from 'formik';
import { Card, Col, Form, ListGroup, ListGroupItem, Row } from 'reactstrap';
import labels from '../../../utilities/constants/labels';

export type WidgetFormValues = {
    [key: string]: string;
};

export type WidgetFormHelpers = FormikHelpers<WidgetFormValues>;

export interface WidgetFormProps {
    category: any;
    initialValues: WidgetFormValues;
    onSubmit: (values: WidgetFormValues, helpers: WidgetFormHelpers) => void;
}

const WidgetForm: React.FC<WidgetFormProps> = ({ category, initialValues, onSubmit }) => {
    const [selectedInput, setSelectedInput] = useState<string>();

    const inputKeys: string[] = Object.keys(category.inputs);

    const options: string[] = useMemo(() => {
        if (selectedInput) {
            return category.inputs[selectedInput].options;
        }

        return [];
    }, [selectedInput]);

    return (
        <Formik initialValues={initialValues} onSubmit={onSubmit}>
            {({ values, handleSubmit, setFieldValue }) => (
                <Form onSubmit={handleSubmit}>
                    <Row>
                        <Col className="order-2 order-xl-1">
                            <Card className="mb-4">
                                <ListGroup flush>
                                    {inputKeys.map((inputKey, index) => {
                                        const isActive = selectedInput === inputKey;

                                        return (
                                            <ListGroupItem
                                                key={index}
                                                active={isActive}
                                                onClick={() => setSelectedInput(inputKey)}
                                                action>
                                                <div className="d-flex align-items-center justify-content-between">
                                                    <div className="d-flex flex-column">
                                                        <div className="fw-bold mb-1">
                                                            {labels[inputKey]}
                                                        </div>
                                                        <div>{labels[values[inputKey]]}</div>
                                                    </div>
                                                    {isActive && (
                                                        <FontAwesomeIcon icon={faCaretRight} />
                                                    )}
                                                </div>
                                            </ListGroupItem>
                                        );
                                    })}
                                </ListGroup>
                            </Card>
                        </Col>
                        <Col className="order-3 order-xl-2">
                            {selectedInput && (
                                <Card className="mb-4">
                                    <ListGroup>
                                        {options.map((option, index) => {
                                            const isActive = values[selectedInput] === option;

                                            return (
                                                <ListGroupItem
                                                    key={index}
                                                    active={isActive}
                                                    onClick={() =>
                                                        setFieldValue(selectedInput, option)
                                                    }
                                                    action>
                                                    <FontAwesomeIcon
                                                        icon={isActive ? faCircleDot : faCircle}
                                                    />
                                                    <span className="ms-3">{labels[option]}</span>
                                                </ListGroupItem>
                                            );
                                        })}
                                    </ListGroup>
                                </Card>
                            )}
                        </Col>
                    </Row>
                    <Button.Save block />
                </Form>
            )}
        </Formik>
    );
};

export default WidgetForm;
