import React from 'react';
import { Button } from 'components/molecules';
import type { ProgrammeFormSubmitFunction, ProgrammeFormValues } from 'components/organisms';
import { ProgrammeForm, ProgrammesTable } from 'components/organisms';
import { Card, CardHeader, Modal, ModalBody, ModalHeader } from 'reactstrap';

interface UserProgrammesPageProps {
    programmes: {
        id: string;
        name: string;
        startDate: string;
        endDate: string;
        status: string;
        createdAt: string;
        updatedAt: string;
    }[];
    handleCreateProgramme: (
        values: {
            name: string;
            description: string;
            startDate: string;
            endDate: string;
        },
        onSuccess: () => void
    ) => void;
}

const UserProgrammesPage: React.FC<UserProgrammesPageProps> = ({
    programmes,
    handleCreateProgramme
}) => {
    const [modal, setModal] = React.useState(false);

    const initialValues: ProgrammeFormValues = {
        name: '',
        description: '',
        startDate: '',
        endDate: ''
    };

    const onSubmit: ProgrammeFormSubmitFunction = (values, helpers): void => {
        handleCreateProgramme(
            {
                name: values.name,
                description: values.description,
                startDate: values.startDate,
                endDate: values.endDate
            },
            () => {
                setModal(false);
                helpers.resetForm();
            }
        );
    };

    const toggle = () => setModal(!modal);

    return (
        <React.Fragment>
            <Card className="card-header-actions mb-4">
                <CardHeader tag="h5">
                    Programmes
                    <Button.Create color="primary" size="sm" onClick={toggle} />
                </CardHeader>
                <ProgrammesTable
                    className="mb-0"
                    data={programmes}
                    next={() => {}}
                    borderless
                    responsive
                    striped
                />
            </Card>
            <Modal isOpen={modal} toggle={toggle} centered>
                <ModalHeader toggle={toggle}>Programme</ModalHeader>
                <ModalBody>
                    <ProgrammeForm initialValues={initialValues} onSubmit={onSubmit} />
                </ModalBody>
            </Modal>
        </React.Fragment>
    );
};

export default UserProgrammesPage;
