import React, { useEffect, useState } from 'react';
import type { ExercisesTableData } from 'components/organisms';
import { ExercisesPage } from 'components/pages';
import { useGetExercisesQuery } from 'graphql/generated';

export interface ExercisesProps {}

const Exercises: React.FC<ExercisesProps> = () => {
    const { data, fetchMore } = useGetExercisesQuery({ variables: { offset: 0, limit: 25 } });

    const [exercisesTableData, setExercisesTableData] = useState<ExercisesTableData[]>([]);

    useEffect(() => {
        setExercisesTableData(
            data?.exercises?.map(exercise => ({
                id: exercise.id,
                name: exercise.name,
                createdAt: exercise.created_at,
                updatedAt: exercise.updated_at || ''
            })) || []
        );
    }, [data]);

    const next = (): void => {
        if (data?.exercises) fetchMore({ variables: { offset: data.exercises.length } });
    };

    return <ExercisesPage {...{ exercisesTableData, next }} />;
};

export default Exercises;
