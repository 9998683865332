import React from 'react';
import { Button, InfiniteScroll } from 'components/molecules';
import Moment from 'react-moment';
import { Link } from 'react-router-dom';
import type { TableProps } from 'reactstrap';
import { Table } from 'reactstrap';

export type UsersTableData = {
    id: string;
    avatar: string;
    name: string;
    createdAt: string;
    updatedAt: string;
};

export interface UsersTableProps extends TableProps {
    data: UsersTableData[];
    next: () => void;
}

const UsersTable: React.FC<UsersTableProps> = ({ data, next, ...props }) => (
    <InfiniteScroll dataLength={data.length} next={next} hasMore>
        <Table {...props}>
            <thead>
                <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Created</th>
                    <th scope="col">Updated</th>
                    <th scope="col"></th>
                </tr>
            </thead>
            <tbody>
                {data.map((user, index) => (
                    <tr key={index}>
                        <td>
                            <div className="d-flex align-items-center">
                                <div className="avatar me-2">
                                    <img className="avatar-img img-fluid" src={user.avatar} />
                                </div>
                                <Link to={`/users/${user.id}`}>{user.name}</Link>
                            </div>
                        </td>
                        <td>
                            <Moment fromNow>{user.createdAt}</Moment>
                        </td>
                        <td>
                            <Moment fromNow>{user.updatedAt}</Moment>
                        </td>
                        <td>
                            <Button.Edit
                                tag={Link}
                                to={`/users/${user.id}/update`}
                                color="primary"
                                role="button"
                                size="sm"
                                block
                            />
                        </td>
                    </tr>
                ))}
            </tbody>
        </Table>
    </InfiniteScroll>
);

export default UsersTable;
