import React from 'react';
import type { LeadFormSubmitFunction, LeadFormValues } from 'components/organisms';
import { LeadForm } from 'components/organisms';
import { DefaultTemplate } from 'components/templates';
import { Button, Card, CardBody, CardHeader, Container } from 'reactstrap';

interface UpdateLeadPageProps {
    initialLeadFormValues: LeadFormValues;
    onDeleteButtonClick: () => void;
    onLeadFormSubmit: LeadFormSubmitFunction;
}

const UpdateLeadPage: React.FC<UpdateLeadPageProps> = ({
    initialLeadFormValues,
    onDeleteButtonClick,
    onLeadFormSubmit
}) => (
    <DefaultTemplate title="Update Lead">
        <Container className="mt-n10">
            <Card className="mb-4">
                <CardHeader tag="h5">Details</CardHeader>
                <CardBody>
                    <LeadForm initialValues={initialLeadFormValues} onSubmit={onLeadFormSubmit} />
                </CardBody>
            </Card>
            <Card className="mb-4">
                <CardHeader tag="h5">Delete Lead</CardHeader>
                <CardBody>
                    <p className="fw-bold text-danger">Deleted leads cannot be restored!</p>
                    <Button type="button" color="danger" onClick={onDeleteButtonClick}>
                        Delete Lead
                    </Button>
                </CardBody>
            </Card>
        </Container>
    </DefaultTemplate>
);

export default UpdateLeadPage;
