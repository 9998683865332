import React, { ReactNode } from 'react';
import { Draggable as BaseDraggable } from 'react-beautiful-dnd';

export interface DraggableProps {
    children: ReactNode;
    draggableId: string;
    index: number;
}

const Draggable: React.FC<DraggableProps> = ({ children, draggableId, index }) => (
    <BaseDraggable {...{ draggableId, index }}>
        {provided => (
            <div
                ref={provided.innerRef}
                style={provided.draggableProps.style}
                {...provided.draggableProps}
                {...provided.dragHandleProps}>
                {children}
            </div>
        )}
    </BaseDraggable>
);

export default Draggable;
