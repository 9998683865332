import CreateButton from './CreateButton';
import EditButton from './EditButton';
import RemoveButton from './RemoveButton';
import SaveButton from './SaveButton';

export * from './CreateButton';
export * from './EditButton';
export * from './RemoveButton';
export * from './SaveButton';

const Button = {
    Create: CreateButton,
    Edit: EditButton,
    Remove: RemoveButton,
    Save: SaveButton
};

export default Button;
