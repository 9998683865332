import React from 'react';
import Moment from 'react-moment';
import { Card, CardBody } from 'reactstrap';
import { z } from 'zod';

export const UserActivitySchema = z.object({
    name: z.string(),
    activities: z.array(
        z.object({
            id: z.string(),
            name: z.string(),
            createdAt: z.string()
        })
    )
});

export interface UserActivityProps extends z.infer<typeof UserActivitySchema> {}

const UserActivity: React.FC<UserActivityProps> = ({ name, activities }) => {
    return (
        <React.Fragment>
            {activities.map((activity, index) => (
                <Card key={index} className="mb-4">
                    <CardBody>
                        {name} logged activity <Moment fromNow>{activity.createdAt}</Moment>
                    </CardBody>
                </Card>
            ))}
        </React.Fragment>
    );
};

export default UserActivity;
