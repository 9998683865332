import React from 'react';
import type { DashboardFormSubmitFunction, DashboardFormValues } from 'components/organisms';
import { DashboardForm } from 'components/organisms';
import { DefaultTemplate } from 'components/templates';
import { Card, CardBody, CardHeader, Container } from 'reactstrap';
import type { CreateDashboardFunction } from './CreateDashboardPage.types';

interface CreateDashboardPageProps {
    createDashboard: CreateDashboardFunction;
}

const CreateDashboardPage: React.FC<CreateDashboardPageProps> = ({ createDashboard }) => {
    const initialDashboardFormValues: DashboardFormValues = {
        name: '',
        description: ''
    };

    const onDashboardFormSubmit: DashboardFormSubmitFunction = (values, _): void => {
        createDashboard(values.name, values.description);
    };

    return (
        <DefaultTemplate title="Create Dashboard">
            <Container className="mt-n10">
                <Card className="mb-4">
                    <CardHeader tag="h5">Details</CardHeader>
                    <CardBody>
                        <DashboardForm
                            initialValues={initialDashboardFormValues}
                            onSubmit={onDashboardFormSubmit}
                        />
                    </CardBody>
                </Card>
            </Container>
        </DefaultTemplate>
    );
};

export default CreateDashboardPage;
