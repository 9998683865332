import React from 'react';
import type { DashboardFormSubmitFunction, DashboardFormValues } from 'components/organisms';
import { DashboardForm } from 'components/organisms';
import { DefaultTemplate } from 'components/templates';
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Container,
    ListGroup,
    ListGroupItem
} from 'reactstrap';
import RemoveButton from 'components/molecules/Button/RemoveButton';

export interface UpdateDashboardPageProps {
    initialDashboardFormValues: DashboardFormValues;
    widgets: { id: string; name: string }[];
    deleteWidget: (widgetId: string) => void;
    onDashboardFormSubmit: DashboardFormSubmitFunction;
    onDeleteButtonClick: () => void;
}

const UpdateDashboardPage: React.FC<UpdateDashboardPageProps> = ({
    initialDashboardFormValues,
    widgets,
    deleteWidget,
    onDashboardFormSubmit,
    onDeleteButtonClick
}) => (
    <DefaultTemplate title="Update Dashboard">
        <Container className="mt-n10">
            <Card className="mb-4">
                <CardHeader tag="h5">Details</CardHeader>
                <CardBody>
                    <DashboardForm
                        initialValues={initialDashboardFormValues}
                        onSubmit={onDashboardFormSubmit}
                    />
                </CardBody>
            </Card>

            {widgets.length > 0 && (
                <Card className="mb-4">
                    <CardHeader tag="h5">Widgets</CardHeader>
                    <ListGroup flush>
                        {widgets.map((widget, index) => (
                            <ListGroupItem key={index}>
                                <div className="d-flex align-items-center justify-content-between">
                                    <div className="d-flex flex-column fw-bold">
                                        <div className="line-height-normal mb-1">{widget.name}</div>
                                        <div className="small text-muted line-height-normal"></div>
                                    </div>
                                    <RemoveButton
                                        type="button"
                                        onClick={() => deleteWidget(widget.id)}
                                    />
                                </div>
                            </ListGroupItem>
                        ))}
                    </ListGroup>
                </Card>
            )}

            <Card className="mb-4">
                <CardHeader tag="h5">Delete Dashboard</CardHeader>
                <CardBody>
                    <p className="fw-bold text-danger">Deleted dashboards cannot be restored!</p>
                    <Button type="button" color="danger" onClick={onDeleteButtonClick}>
                        Delete Dashboard
                    </Button>
                </CardBody>
            </Card>
        </Container>
    </DefaultTemplate>
);

export default UpdateDashboardPage;
